import { FormField, Label, SelectV2, Sheet, TextInput, TitleBar } from '@dynatrace/strato-components-preview';
import { Button } from '@dynatrace/strato-components/buttons';
import { Flex } from '@dynatrace/strato-components/layouts';
import { XmarkIcon } from '@dynatrace/strato-icons';
import { Key, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ItemInfo } from '../../types/ListItemInfo';
import { useUserById } from '../../hooks/use-minerva-data';
import { LoadingStateComponent } from '../../components/LoadingStateComponent';
import { ShowErrorNotification, ShowSuccessNotification } from '../../utils/Notifications';
import { UserService } from '../../services/UsersService';
import { useAppInfo } from '../../contexts/AppContext';
import { useAuth0, User } from '@auth0/auth0-react';

export interface UserSheetProps {
  closeDetails: (itemInfo?: ItemInfo) => void;
  itemInfo?: ItemInfo;
  show: boolean;
}

export const UserSheet = ({ closeDetails, show, itemInfo }: UserSheetProps) => {
  const [state, setState] = useState(itemInfo?.visibility);
  const { userId, tenantId } = useAppInfo();
  const { isLoading, data: thisUser } = useUserById(itemInfo?.idStr ?? '', tenantId);
  const { user: loggedInUser } = useAuth0();
  const [loading, setLoading] = useState(false);

  const { handleSubmit, control, reset } = useForm<{
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    isTenantUser: boolean;
    isAdminUser: boolean;
    userName: string;
    contact: string;
    website: string;
    userType: undefined | Key;
  }>({
    mode: undefined,
  });

  const resetPassword = async () => {
    try {
      setLoading(true);
      await UserService.forgotPassword(thisUser?.userName, tenantId);
      ShowSuccessNotification('Password reset e-mail sent successfully');
    } catch (error) {
      ShowErrorNotification('Error resetting password', error);
    }
    finally{
      setLoading(false);
    }
  };

  const title = itemInfo?.idStr ? itemInfo?.name : 'Add New User';
  const subTitle = itemInfo?.idStr
    ? 'Edit user details for Tenant: ' + (itemInfo?.tenantName ?? '')
    : 'Create a new user for Tenant: ' + (itemInfo?.tenantName ?? '');

  // only admin and tenant admin can add staff + they can edit other users info.
  // if you cannot add staff you can only edit your own info
  const userCanAddStaff =
    loggedInUser?.['roles/roles']?.includes('Admin') || loggedInUser?.['roles/roles']?.includes('TenantAdmin');
  const userCanEdit =
    loggedInUser?.['roles/roles']?.includes('Admin') ||
    loggedInUser?.['roles/roles']?.includes('TenantAdmin') ||
    itemInfo?.idStr === userId;

  const isLoggedInUserAdmin = () => {
    return loggedInUser?.['roles/roles']?.includes('Admin');
  };

  const isThisUserAdmin = () => {
    return thisUser?.roles === 'Admin';
  };

  useEffect(() => {
    if (itemInfo?.idStr) {
      reset({
        userName: thisUser?.userName,
        firstName: thisUser?.firstName,
        lastName: thisUser?.lastName,
        email: thisUser?.email,
        phoneNumber: thisUser?.phoneNumber,
        userType:
          loggedInUser?.email === thisUser?.email ? getRolesFromLoggedInUser(loggedInUser) : getRoles(thisUser?.roles),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thisUser, itemInfo?.idStr]);

  function getRoles(userType: string | undefined): string {
    if (userType === undefined) {
      return 'Staff';
    }
    return userType === 'TenantAdmin' ? 'TenantAdmin' : userType === 'Admin' ? 'Admin' : 'Staff';
  }

  function getRolesFromLoggedInUser(loggedInUser: User | undefined): string {
    return loggedInUser?.['roles/roles']?.includes('Admin')
      ? 'Admin'
      : loggedInUser?.['roles/roles']?.includes('TenantAdmin')
      ? 'TenantAdmin'
      : 'Staff';
  }

  const saveUser = async (values: any) => {
    if (itemInfo?.idStr) {
      values.userId = itemInfo.idStr;
      values.isActive = true;
      values.isDeleted = false;
      values.notificationsEnabled = true;
      values.tenantId = tenantId;
      const newRole = getRoles(values.userType); // get the current role
      if (newRole !== thisUser?.roles) {
        values.roles = newRole;
        values.oldRole = thisUser?.roles;
      }
      // update the user
      try {
        values.roles = getRoles(values.userType);
        await UserService.updateUserById(values, tenantId);
        ShowSuccessNotification('User updated successfully');
        closeDetails({ refreshParent: true });
      } catch (error) {
        ShowErrorNotification('Error updating user', error);
      }
    } else {
      values.tenantId = itemInfo?.tenantId ?? tenantId;
      values.isTenantUser = 1;
      values.isActive = true;
      values.isDeleted = false;
      values.notificationsEnabled = true;
      values.roles = getRoles(values.userType);
      try {
        const isEmailExist: boolean = await UserService.verifyIsEmailExist(values.email, tenantId);
        if (isEmailExist) {
          ShowErrorNotification('Email already exists in the system, please use another email address.');
          return;
        }
        await UserService.createuser(values, tenantId);
        ShowSuccessNotification('User created successfully');
        closeDetails({ refreshParent: true });
      } catch (error) {
        ShowErrorNotification('Error creating user', error);
      }
    }
  };

  // checks for disabling user type field
  //1.  enable if current user is Tenant Admin or Admin
  let disableUserTypeField = false;
  if (!userCanAddStaff || loggedInUser?.email === thisUser?.userName) {
    disableUserTypeField = true;
  }
  //2.  disable if current user is Tenant Admin and editing a Admin user
  if (isThisUserAdmin() && loggedInUser?.['roles/roles']?.includes('TenantAdmin')) {
    disableUserTypeField = true;
  }

  return (
    <Sheet show={state} onDismiss={closeDetails}>
      <LoadingStateComponent loading = {loading} />
      <Flex flexDirection='column' margin={8} padding={0} gap={8}>
        <TitleBar>
          <TitleBar.Title>{title}</TitleBar.Title>
          <TitleBar.Subtitle>{subTitle} </TitleBar.Subtitle>
          <TitleBar.Action>
            <Flex flexDirection='row' gap={8}>
              <Button
                width='75px'
                onClick={() => {
                  setState(false);
                  closeDetails();
                }}
                variant='default'
              >
                <Button.Prefix>
                  <XmarkIcon />
                </Button.Prefix>
              </Button>
            </Flex>
          </TitleBar.Action>
        </TitleBar>
        <Flex flexDirection='column' gap={4}>
          <LoadingStateComponent loading={isLoading} />
          <form onSubmit={handleSubmit(saveUser)} onReset={() => reset()} noValidate>
            <Flex flexDirection='column' justifyContent='space-between' alignItems='end'>
              <TitleBar.Subtitle>
                <TitleBar.Action>
                  <Button
                    style={{ marginRight: '8px' }}
                    width='75px'
                    onClick={() => {
                      setState(false);
                      closeDetails();
                    }}
                    variant='default'
                  >
                    Discard
                  </Button>
                  {itemInfo && itemInfo?.name && (
                    <Button
                      disabled={!userCanAddStaff}
                      style={{ marginRight: '8px' }}
                      width='150px'
                      onClick={resetPassword}
                      variant='accent'
                      color='primary'
                    >
                      Reset password
                    </Button>
                  )}
                  <Button
                    disabled={!userCanEdit}
                    width='60px'
                    onClick={() => saveUser}
                    type='submit'
                    variant='accent'
                    color='primary'
                  >
                    Save
                  </Button>
                </TitleBar.Action>
              </TitleBar.Subtitle>
            </Flex>
            <Flex flexDirection='column'></Flex>
            <Flex flexDirection='column' width={512} minWidth={512} gap={16}>
              {itemInfo?.idStr && (
                <Controller
                  name='userName'
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <FormField required disabled>
                      <Label>Username</Label>
                      <TextInput
                        controlState={{
                          state: error ? 'error' : 'valid',
                          hint: error?.message,
                        }}
                        {...field}
                      />
                    </FormField>
                  )}
                />
              )}
              <Controller
                name='firstName'
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'First name is required.',
                  },
                  maxLength: {
                    value: 200,
                    message: 'Please enter a valid first name.',
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormField required>
                    <Label>First name</Label>
                    <TextInput
                      disabled={!userCanEdit}
                      placeholder='Enter first name'
                      controlState={{
                        state: error ? 'error' : 'valid',
                        hint: error?.message,
                      }}
                      {...field}
                    />
                  </FormField>
                )}
              />
              <Controller
                name='lastName'
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Last name is required.',
                  },
                  maxLength: {
                    value: 200,
                    message: 'Please enter a valid last name.',
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormField required>
                    <Label>Last name</Label>
                    <TextInput
                      disabled={!userCanEdit}
                      placeholder='Enter last name'
                      controlState={{
                        state: error ? 'error' : 'valid',
                        hint: error?.message,
                      }}
                      {...field}
                    />
                  </FormField>
                )}
              />
              {itemInfo?.idStr === undefined && (
                <Controller
                  name='email'
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Email is required.',
                    },
                    maxLength: {
                      value: 200,
                      message: 'Please enter a valid email address',
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <FormField required>
                      <Label>Email</Label>
                      <TextInput
                        placeholder='Enter email, this will be your username/login Id.'
                        controlState={{
                          state: error ? 'error' : 'valid',
                          hint: error?.message,
                        }}
                        {...field}
                      />
                    </FormField>
                  )}
                />
              )}
              <Controller
                name='phoneNumber'
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Mobile number is required.',
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormField required>
                    <Label>Mobile number</Label>
                    <TextInput
                      disabled={!userCanEdit}
                      placeholder='Enter mobile number'
                      controlState={{
                        state: error ? 'error' : 'valid',
                        hint: error?.message,
                      }}
                      {...field}
                    />
                  </FormField>
                )}
              />
              <Controller
                name='userType'
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'User type needs to be selected.',
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormField required disabled={disableUserTypeField}>
                    <Label>User type</Label>
                    <Flex
                      onKeyDown={(event: { key: string; preventDefault: () => void }) => {
                        if (event.key === 'Enter') {
                          event.preventDefault(); // Prevent form submission
                        }
                      }}
                    >
                      <SelectV2
                        controlState={{
                          state: error ? 'error' : 'valid',
                          hint: error?.message,
                        }}
                        {...field}
                      >
                        <SelectV2.Content style={{ width: 'max-content' }}>
                          <SelectV2.Option key={0} value={'Staff'}>
                            {'Tenant Staff'}
                          </SelectV2.Option>
                          <SelectV2.Option key={1} value={'TenantAdmin'}>
                            {'Tenant Admin'}
                          </SelectV2.Option>
                          {(isLoggedInUserAdmin() || isThisUserAdmin()) && (
                            <SelectV2.Option key={2} value={'Admin'}>
                              {'System Admin'}
                            </SelectV2.Option>
                          )}
                        </SelectV2.Content>
                      </SelectV2>
                    </Flex>
                  </FormField>
                )}
              />
            </Flex>
          </form>
        </Flex>
      </Flex>
    </Sheet>
  );
};
