import React, { useEffect, useRef, useState } from 'react';
import { Modal, FormFieldMessages, Tabs, Tab, Sheet, TitleBar, Label, FormField, TextArea, ColumnType, DataTableV2, Tooltip } from '@dynatrace/strato-components-preview';
import { Button } from '@dynatrace/strato-components/buttons';
import { Text } from '@dynatrace/strato-components/typography';
import { Flex } from '@dynatrace/strato-components/layouts';
import { Colors } from '@dynatrace/strato-design-tokens';
import { useForm, useWatch, useFieldArray, Controller } from 'react-hook-form';
import { MailIcon, SourceIcon, CriticalIcon, DeleteIcon, ContainerIcon, CycleIcon, SyncDoneIcon, DescriptionIcon } from '@dynatrace/strato-icons';
import { ProjectService } from '../../../services/ProjectService';
import { ProjectDetail, ProjectPeopleRelation } from '../../../types/Project';
import { RequestDetails } from '../../../types/Request';
import { ShowErrorNotification, ShowSuccessNotification, ShowValidationBanner } from '../../../utils/Notifications';
import { LoadingStateComponent } from '../../../components/LoadingStateComponent';
import { ItemInfo } from '../../../types/ListItemInfo';
import { useAppInfo } from '../../../contexts/AppContext';
import { PackageMessage } from './PackageMessage';
import { PackageSummary } from './PackageSummary';
import { PackageRecipients } from './PackageRecipients';
import { useProjectRequestById, useRequestTemplates } from '../../../hooks/use-minerva-data';
import { useParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { PackageService } from '../../../services/PackageService';
import styled from 'styled-components';
import FilePreview from '../../../components/FilePreview';

const VerticalLine = styled.div`
  border-left: 2px solid ${Colors.Border.Neutral.Default};
  margin: 0 8px;
`;

export interface CreateRequestProps {
  peopleList: ProjectPeopleRelation[];
  projectDetails: ProjectDetail | any;
  isOpen: boolean;
  onClose: () => void;
  onPackageCreated: () => void;
  editPackageDetails?: any;
  projectPackages?: any;
  refetchProjectPackagesDrafts?: any;
}

const CreatePackage = (props: CreateRequestProps) => {
  const { isOpen, onClose, peopleList, projectDetails, onPackageCreated, editPackageDetails, projectPackages, refetchProjectPackagesDrafts } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<Record<string, boolean>>({});
  const [selectedUsers, setSelectedUsers] = useState<any[]>();
  const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});
  const [sendConfirmModal, setSendConfirmModal] = useState<boolean>(false);
  const [recipients, setRecipients] = useState<ProjectPeopleRelation[]>(peopleList);
  const [projectRequestId, setProjectRequestId] = useState(0);
  const [editRequestFlag, setEditRequestFlag] = useState(true);
  const projectRequests = useRef<RequestDetails[]>([]);
  const [selectedFilesRows, setSelectedFilesRows] = useState([]);
  // use refs for hooks
  const [activeTab, setActiveTab] = useState('Package');
  const [selectedFiles, setSelectedFiles] = useState<File[][]>([[]]); // Array of file arrays for each section
  const fileInputRefs = useRef<HTMLInputElement[]>([]); // Array of file input refs
  // eslint-disable-next-line
  const [disableReqeustMessage, setDisableReqeustMessage] = useState(false);
  const [autoSave, setAutoSave] = useState(false);
  const [createDraft, setCreateDraft] = useState(true);
  const [currentDraftRequestId, setCurrentDraftRequestId] = useState<number>(0);
  const [reqeusetCancelModal, setReqeusetCancelModal] = useState(false);
  const [previewFile, setPreviewFile] = useState<any>(null);
  const {
    tenantId,
    documentTypes,
    tenantRequestReminders: reminderListData,
    tenantRequestStatusList: requestStatusList,
    tenantProjectRequestStatusList: projectRequestStatusList,
    user,
  } = useAppInfo();
  const { ProjectId } = useParams();
  const {
    isLoading: projectRequestsLoading,
    isRefetching: projectRequestsRefetching,
    data: projectRequestsData,
    // error: requestError,
    refetch: refetchProjectRequests,
  } = useProjectRequestById(Number(ProjectId), (tenantId && Number(tenantId) !== 0) ? Number(tenantId) : Number(localStorage.getItem("tenantId")));

  const loadingDetails =
    projectRequestsLoading || projectRequestsRefetching || loading;

  if (!loadingDetails) {
    //notes
    projectRequests.current = projectRequestsData?.projectRequest;
  }
  // eslint-disable-next-line
  const otherDocumentTypeId = documentTypes?.find(
    (docType) => docType.documentTypeId === -1 && docType.tenantId === tenantId,
  )?.documentTypeAutoId;
  const {
    isLoading: isTemplatesLoading,
    // eslint-disable-next-line
    data: requestTemplateData,
    // eslint-disable-next-line
    refetch: refetchRequestTemplates,
    isRefetching,
  } = useRequestTemplates((tenantId && Number(tenantId) !== 0) ? Number(tenantId) : Number(localStorage.getItem("tenantId")));
  const defaultRequestStatusId = requestStatusList?.find((status) => status.statusName === 'New')?.statusAutoId;
  const defaultDraftRequestStatusId = projectRequestStatusList?.find((status) => status.statusName === 'inprogress/draft')?.statusAutoId;
  const defaultSentRequestStatusId = projectRequestStatusList?.find((status) => status.statusName === 'published')?.statusAutoId;
  const disableSaveClose = editPackageDetails?.packageName && editPackageDetails?.projectPackageStatusAutoId ? editPackageDetails?.projectPackageStatusAutoId !== defaultDraftRequestStatusId : false;
  // let distinctSentToEmails = [];
  // if( editRequestDetails){
  //   distinctSentToEmails = Array.from(
  //   new Set(editRequestDetails.projectRequestSentList.map((item: any) => item.sentTo))
  //   );
  // }

  // setSelectedReceipents(distinctSentToEmails);
  useEffect(() => {
    if (peopleList.length > 0) {
      setRecipients(peopleList);

    }
  }, [peopleList]);

  const {
    handleSubmit,
    setValue,
    control,
    reset,
    trigger,
    formState: { errors },
  } = useForm<{
    projectId: number;
    tenantId: number;
    packageId: number;
    packageName: string;
    packageMessage: string;
    packageDescription: string;
    requestUpdateComments: string;
    requestDetails: [],
    recipientDetails: any,
    requestPackages: any;
  }>({
    reValidateMode: 'onChange',
    defaultValues: {
      recipientDetails: [
        {
          email: '',
          firstName: '',
          lastName: '',
          peopleId: '',
        },
      ],
    },
    mode: undefined,
  });

  let createRequestErrors = errors;
  const formValues = useWatch({
      control,
    });
    const [debouncedValues, setDebouncedValues] = useState<any>(formValues);
  
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValues(formValues);
      }, 2000);
  
      return () => {
        clearTimeout(handler);
      };
    }, [formValues]);
  
    useEffect(() => {
      // if (editPackageDetails?.projectRequest?.projectRequestStatusAutoId === defaultDraftRequestStatusId && (Object.keys(debouncedValues).length > 0 || selectedFiles.length > 0)) {
        if (isOpen && editPackageDetails?.projectPackageStatusAutoId === defaultDraftRequestStatusId && (Object.keys(debouncedValues).length > 0 || selectedFilesRows.length > 0)) {
        setCreateDraft(false);
        setCurrentDraftRequestId(editPackageDetails?.packageId);
        saveDraftRequest(debouncedValues);
      }
      else if (isOpen && !editPackageDetails?.packageName && (Object.keys(debouncedValues).length > 0 || selectedFilesRows.length > 0)) {
        if(currentDraftRequestId <= 0){
          setCreateDraft(true);
        setCurrentDraftRequestId(0);
      }
      saveDraftRequest(debouncedValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValues, selectedFilesRows]);

  // eslint-disable-next-line
  const updatedFields = useWatch({
    control,
    name: 'requestDetails',
  });

  const { fields, remove } = useFieldArray({
    control,
    name: 'requestDetails',
  });
  const [formData, setFormData] = useState({
    template: 0,
    packageId: 0,
    packageName: '',
    packageMessage: '',
    packageDescription: '',
    requestUpdateComments: '',
    requestPackages: [],

    reminder: '',
    recipients: [
      {
        email: '',
        firstName: '',
        lastName: '',
        peopleId: 0,
        packageShareId: 0,
      },
    ],
  });

  // UseEffect to update form values when externalData changes

  const saveDraftRequest = async (formValues: { requestPackages: never[]; recipientDetails: any; packageName: any; packageDescription: any; packageMessage: any; requestUpdateComments: any; recipients: any; requestDescription: string | any[]; }) => {
      setAutoSave(true);
      try {
        // const base64FilesPromises = selectedFiles.map((fileList) =>
        //   Promise.all(
        //     fileList.map(async (file: any) => {
        //       // if this is already read base64String then just use it as this file is not available
        //       // on this machine or not readable
        //       const base64 = file.base64String ? file.base64String : await getBase64(file);
        //       return {
        //         name: file.name,
        //         size: file.size,
        //         type: file.type,
        //         base64String: base64, // base64 encoded string
        //         projectRequestDetailsFilesId: file.projectRequestDetailsFilesId || 0,
        //       };
        //     }),
        //   ),
        // );
  
        // const base64Files = await Promise.all(base64FilesPromises);
        // Set autoSave to true
        // const requestDetails = formValues.requestPackages || [];
        const recipients = formValues.recipientDetails || [];
        let mapProjectRequest: any = {
          packageId: projectRequestId,
          packageName: '',
          packageDescription: '',
          reminderId: '',
          document: [],
          requestSendTo: [],
          tenantId: 0,
          projectId: 0,
          recipients: [],
          requestPackages: [],
        };
        mapProjectRequest.packageId = currentDraftRequestId;
        mapProjectRequest.packageName = formValues?.packageName?.slice(0, 45) || formValues?.packageName;;
        mapProjectRequest.packageDescription = formValues.packageDescription;
        mapProjectRequest.packageMessage = formValues.packageMessage;
        mapProjectRequest.packageUpdateComments = formValues.requestUpdateComments;
        mapProjectRequest.requestSendTo = [];
        mapProjectRequest.projectId = projectDetails?.project.projectId;
        mapProjectRequest.tenantId = tenantId;
        mapProjectRequest.statusAutoId = defaultRequestStatusId;
        mapProjectRequest.projectPackageStatusAutoId = defaultDraftRequestStatusId;
        mapProjectRequest.reminderId = reminderListData?.[0].remindersAutoId;
        mapProjectRequest.requestPackages = selectedFilesRows;
        
        mapProjectRequest.createdBy = user?.userName;
        // mapProjectRequest.recipients = formData.recipients;
        if (createDraft && formValues?.packageName && formValues.packageName !== "" && formValues?.packageName?.length > 3) {
          if (!editPackageDetails?.packageName || editPackageDetails?.projectPackageStatusAutoId !== defaultDraftRequestStatusId) {
            const data: any = await PackageService.saveDraftPackageDetails(mapProjectRequest, tenantId, projectDetails?.project.projectId);
            if (data.code === "200") {
              setCreateDraft(false);
              // console.log("data.id:", data.id, "Converted:", Number(data.id));
              setCurrentDraftRequestId(Number(data.id));
            }
            else {
              ShowErrorNotification("Something went wrong with Auto Save", data.message);
            }
          }
  
  
        }
        else if (!createDraft && currentDraftRequestId > 0) {
          if (!editPackageDetails?.packageName || editPackageDetails?.projectPackageStatusAutoId === defaultDraftRequestStatusId) {
            if (recipients.length > 0 && recipients[0].firstName !== "") {
              mapProjectRequest.recipients = formValues.recipientDetails;
        mapProjectRequest.recipients.forEach((recipient: { packageShareId: any; email: any; }) => {
          const matchedUser = selectedUsers?.find(user => user.email === recipient.email);
          if (matchedUser) {
            recipient.packageShareId = matchedUser.packageShareId;
          }
        });
            }
            const data: any = await PackageService.updateDraftPackageDetails(mapProjectRequest, tenantId, projectDetails?.project.projectId, currentDraftRequestId);
            if (data.code === "200") {
              setCurrentDraftRequestId(Number(data.id));
              setCreateDraft(false);
  
            }
            else {
              ShowErrorNotification("Something went wrong with Auto Save", data.message);
            }
          }
        }
        // 
        // console.log(formValues);
        // console.log(autoSave);
      }
      catch (e) {
  
      }
      finally {
        setAutoSave(false);
      }
  
  
    }

  const updateProjectRequest = (editPackageDetails: any) => {

    reset({
      packageId: editPackageDetails.packageId,
      packageName: editPackageDetails.packageName,
      packageMessage: editPackageDetails.packageMessage,
      packageDescription: editPackageDetails.packageDescription,
      requestPackages: selectedFilesRows,
    });

    if (editPackageDetails?.projectPackageStatusAutoId !== defaultDraftRequestStatusId) {
      setProjectRequestId(editPackageDetails.packageId);
    }

    editPackageDetails.assignedTo.forEach((details: any, index: number) => {
      // Update instructions if they exist
      if (details.instructions) {
        //console.log(`Updating instructions for index ${index}:`, details.instructions);
        // Handle the logic to update the instruction in your UI here
      }

      if (index === 0) {
        if (details.requestPackages && details.requestPackages.length > 0) {
          const attachedFiles = details.requestPackages.map((file: any) => ({
            downloadFilePath: file.uniqueLink,
            // size: findSize(file.fileData),
            // type: file.type,
            // base64String: file.fileData,
            projectRequestId: file.projectRequestId,
            label: file.label,
            projectRequestSentId: file.projectRequestSentId,
            projectRequestName: file.projectRequestName,
            updateDate: file.updateDate,
            assignedto: file.createdBy,
            createdOn: file.createdOn,
          }));
          setSelectedFilesRows(attachedFiles);
        }
        else {
          setSelectedFilesRows([]);
        }
      }
      // Check if attachedFiles exist

    });

    const distinctSentToEmails = Array.from(
      new Set(editPackageDetails.assignedTo.map((item: any) => item.peopleId))
    );
    const distinctSentToEmailsList = editPackageDetails.assignedTo.map((item: any) =>
      ({ 'email': item.sentToEmailAddress, 'peopleId': item.peopleId, 'userName': item.sentToUserName, 'packageShareId': item.packageShareId }));

    const indexMap: any = distinctSentToEmails.reduce((acc: any, peopleId) => {
      const personIndex = peopleList.findIndex(person => person.peopleId === peopleId);
      if (personIndex !== -1) {
        // We use personIndex as string here to match the Record<string, boolean> type
        acc[personIndex.toString()] = true;
      }
      return acc;
    }, {} as Record<string, boolean>);
    // setSelectedReceipents(indexMap);
    setSelectedRows(indexMap);

    const updatedRecipients = peopleList
      ?.filter((row) => distinctSentToEmailsList.some((dist: any) => dist.peopleId === row.peopleId))
      .map((newRow) => {
        const existingRecipient = distinctSentToEmailsList.find((dist: any) => dist.peopleId === newRow.peopleId);
        return {
          email: newRow.email,
          firstName: newRow.firstName,
          lastName: newRow.lastName,
          // status: existingRecipient?.statusAutoId,
          peopleId: newRow.peopleId,
          packageShareId: existingRecipient.packageShareId,
          // uniqueLink: existingRecipient?.uniqueLink,
          // projectRequestSendToId: existingRecipient?.projectRequestSendToId,
        };
      });


    setSelectedUsers(updatedRecipients);
    setFormData((prevFormData) => ({
      ...prevFormData,
      recipients: updatedRecipients ?? [],
    }));
    setValue("recipientDetails", updatedRecipients ?? []);
    // const pendingRequestsDisable = editRequestDetails.projectRequestSentList.some((item: any) => item.statusName !== 'New');
    // setDisableReqeustMessage(pendingRequestsDisable);
  }

  useEffect(() => {
    if (isOpen) {
      reset({
        packageId: 0,
        packageName: '',
        packageMessage: '',
        packageDescription: '',
        requestPackages: [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, reset]);

  // eslint-disable-next-line
  const findSize = (base64: string) => {
    // Calculate the padding
    const padding = (base64.match(/=*$/) || [])[0]?.length;

    // Calculate the size in bytes
    const fileSizeInBytes = (base64.length * 3) / 4 - (padding ? padding : 0);
    return fileSizeInBytes;
  };

  // updates project people relations post creating a new one or deleting
  const updateProjectPeopleRelations = async (addPersonsVisible: ItemInfo) => {
    try {
      setLoading(true);
      const data = await ProjectService.getProjectWithDetailsID<ProjectDetail>(addPersonsVisible?.id ?? 0, tenantId);
      setRecipients(data.projectPeopleRelation);
    } catch (error) {
      ShowErrorNotification('Error updating project people relation', error);
    } finally {
      // setLoadingPeopleRelations(false);
      setLoading(false);
    }
  };

  const handleRecipientsChange = (
    selectedRowsData?: any,
  ) => {
    const selectedIndices = Object.keys(selectedRowsData)
      .filter((key) => selectedRowsData[key])
      .map((key) => parseInt(key, 10));

    const selectedRows = selectedIndices.map((index) => recipients[index]) as any;

    setSelectedRows(selectedRowsData);

    // Map the selected indices to the actual row data
    const updatedRecipients = selectedRows?.map((row: any) => ({
      email: row.email,
      firstName: row.firstName,
      lastName: row.lastName,
      peopleId: row.peopleId,
      packageShareId: row.packageShareId
    }));
    setSelectedUsers(selectedRows);
    setFormData((prevFormData) => ({
      ...prevFormData,
      recipients: updatedRecipients ?? [],
    }));
    setValue("recipientDetails", updatedRecipients);
    setValidationErrors({});
  };

  // console.log(validationErrors)

  const validateCreateRequestTab = (values: any) => {
    const errors: { [key: string]: string } = {};
    if (!selectedFilesRows || selectedFilesRows.length === 0) {
      errors['Files'] = 'Error';
    }
    return errors;
  };

  const validateAddRecipientsTab = () => {
    const errors: { [key: string]: string } = {};
    if (formData?.recipients.length === 0 || formData?.recipients[0].email === '') {
      errors['recipients'] = 'Atleast one recipient must be selected';
    }

    return errors;
  };

  const [tabOptions, setTabOptions] = useState({
    'Package': false,
    'Recipients': true,
    'Package overview': true,
  });

  const handleCreateRequestNextButtonClick = (values: any) => {
    if (activeTab === 'Package') {
      const errors = validateCreateRequestTab(values);
      // console.log(errors)
      if (Object.keys(errors).length === 0) {
        setValidationErrors({});
        enableNextTab();
      } else {
        setValidationErrors(errors);
      }
    }
  };
  const handleNextButtonClick = () => {
    if (activeTab === 'Recipients') {
      const errors = validateAddRecipientsTab();
      if (Object.keys(errors).length === 0) {
        setValidationErrors({});
        enableNextTab();
      } else {
        setValidationErrors(errors);
      }
    }
  };

  const enableNextTab = () => {
    const nextIndex = (currentTabIndex + 1) % tabs.length;
    setTabOptions((prevOptions) => ({
      ...prevOptions,
      [tabs[nextIndex]]: false,
    }));
    setCurrentTabIndex(nextIndex);
    setActiveTab(tabs[nextIndex]);
  };
  const disablePrevTab = () => {
    // const nextIndex = currentTabIndex % tabs.length;
    const prevIndex = (currentTabIndex - 1) % tabs.length;
    setCurrentTabIndex(prevIndex);
    setActiveTab(tabs[prevIndex]);
  };
  // const [reminderList, setReminderList] = useState<ReminderDetails[]>([]);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const tabs = ['Package', 'Recipients', 'Package overview'];


  // const getDocumentTypeName = (documentTypeAutoId: any) => {
  //   return documentTypes.current.find((docType) => docType.documentTypeAutoId === documentTypeAutoId)?.documentTypeName;
  // };

  // save the information
  const saveDetails = async (values: any) => {
    // eslint-disable-next-line
    const { template, packageName, packageMessage, packageDescription, requestTemplateName, requestTemplateDescription, requestPackages } = values;

    setFormData((prevData) => ({
      ...prevData,
      packageId: projectRequestId,
      packageName: packageName || prevData.packageName,
      packageMessage: packageMessage || prevData.packageMessage,
      packageDescription: packageDescription || prevData.packageDescription,
      requestPackages: selectedFilesRows || prevData.requestPackages,
    }));
    handleCreateRequestNextButtonClick(values);
  };


  const handleRequestCancelClick = () => {
      if (debouncedValues?.packageName?.length > 3) {
        setReqeusetCancelModal(true);
      }
      else {
        handleDismiss();
      }
    }
  
    const handleRequestCancelCancelClick = () => {
      setReqeusetCancelModal(false);
    }
    const handleRequestCancelSave = async() => {
      const isValid = await trigger('packageName');
      if (isValid) {
      handleDismiss();
      }
    }
    const handleRequestCancelNoSavePackage = () => {
      
      
  
      handleDismiss();
    }
    const handleRequestCancelNoSave = () => {
      if(editPackageDetails?.projectPackageStatusAutoId === defaultDraftRequestStatusId){
        const data: any = PackageService.deleteProjectPackageDraftById(currentDraftRequestId, tenantId, projectDetails?.project.projectId);
        if (data.code === '500') {
          ShowErrorNotification("Error while deleting the package draft", data.message);
        }
      }
      
  
      handleDismiss();
    }

  const handleDismiss = () => {
    // Resetting additional form data state if needed
    setValue("packageName", "");
    setDebouncedValues((prevValues: any) => ({
      ...prevValues,
      packageName: "",
    }));

    setFormData({
      template: 0,
      packageId: 0,
      packageName: '',
      packageMessage: '',
      packageDescription: '',
      requestUpdateComments: '',
      requestPackages: [],
      reminder: '0',
      recipients: [
        {
          email: '',
          firstName: '',
          lastName: '',
          peopleId: 0,
          packageShareId: 0,
        },
      ],
    });
    reset({
      packageName: '',
      packageMessage: '',
      packageDescription: '',
      requestPackages: [],
    });
    fileInputRefs.current = [];
    setSelectedRows({});
    setSelectedFilesRows([]);
    // Reset the active tab to the first tab
    setCurrentTabIndex(0);
    setActiveTab('Package');
    setValidationErrors({});
    // Optionally, reset tab options if needed
    setTabOptions({
      'Package': false,
      'Recipients': true,
      'Package overview': true,
    });
    setSelectedFiles([[]]);
    setEditRequestFlag(true);
    // setEditProjectRequest({});
    setReqeusetCancelModal(false);
    refetchProjectPackagesDrafts();
    onClose();

    setCurrentDraftRequestId(0);

    setCreateDraft(true);
  };

  // Function to handle tab change
  const handleTabChange = (index: number) => {
    // Implement any logic needed before switching tabs, such as validation
    const isValid = true;
    if (isValid) {
      setCurrentTabIndex(index);
      setTabOptions((prevOptions) => ({
        ...prevOptions,
        [Object.keys(tabOptions)[index]]: false,
      }));
      const tabKeys = Object.keys(tabOptions);
      const tabKeyToIndex = tabKeys.reduce((acc, key, index) => {
        acc[index] = key;
        return acc;
      }, {} as { [key: number]: string });
      setActiveTab(tabKeyToIndex[index]);
    } else {
      // Handle case when validation fails
      // console.warn('Validation failed, cannot change tab.');
    }
  };

  const handleSend = handleSubmit((values) => {
    // Perform validations
    const CreateRequestErrors = validateCreateRequestTab(values);
    const ReceipentsErrors = validateAddRecipientsTab();

    // Merge both errors
    const combinedErrors = { ...CreateRequestErrors, ...ReceipentsErrors };

    // If there are any errors, set them in validationErrors
    if (Object.keys(combinedErrors).length !== 0) {
      setValidationErrors(combinedErrors);
    } else {
      // If there are no errors, clear the validationErrors
      setValidationErrors({});
      // Show the send confirmation modal
      setSendConfirmModal(true);
    }
  });

  // Function to handle the Yes click
  const handleYesClick = () => {
    CreateRequestSubmitClick(formValues, formData, projectDetails, documentTypes);
    setSendConfirmModal(false);
  };

  // Function to close the modal when No is clicked
  const handleNoClick = () => {
    setSendConfirmModal(false);
  };

  const CreateRequestSubmitClick = async (formValues: any, formData: any, projectDetails: any, documentTypes: any) => {
    let mapProjectRequest: any = {
      packageId: projectRequestId > 0 ? projectRequestId : currentDraftRequestId > 0 ? currentDraftRequestId : 0,
      packageName: '',
      packageDescription: '',
      reminderId: '',
      document: [],
      requestSendTo: [],
      tenantId: 0,
      projectId: 0,
      recipients: [],
      requestPackages: [],
    };
    mapProjectRequest.packageName = formValues.packageName;
    mapProjectRequest.packageDescription = formValues.packageDescription;
    mapProjectRequest.packageMessage = formValues.packageMessage;
    mapProjectRequest.packageUpdateComments = formValues.requestUpdateComments;
    mapProjectRequest.requestSendTo = [];
    mapProjectRequest.projectId = projectDetails?.project.projectId;
    mapProjectRequest.tenantId = tenantId;
    mapProjectRequest.projectPackageStatusAutoId = defaultSentRequestStatusId;
    mapProjectRequest.statusAutoId = defaultRequestStatusId;
    mapProjectRequest.reminderId = reminderListData?.[0].remindersAutoId;
    mapProjectRequest.requestPackages = selectedFilesRows;
    mapProjectRequest.recipients = formValues.recipientDetails;
    mapProjectRequest.recipients.forEach((recipient: { packageShareId: any; email: any; }) => {
      const matchedUser = selectedUsers?.find(user => user.email === recipient.email);
      if (matchedUser) {
        recipient.packageShareId = matchedUser.packageShareId;
      }
    });
    mapProjectRequest.createdBy = user?.userName;




    try {
      setLoading(true);
      if (projectRequestId !== 0) {

        const data: any = await ProjectService.updateProjectPackageWithDetails<Request>(mapProjectRequest, tenantId, mapProjectRequest.projectId);
        if (data.code === '500') {
          ShowErrorNotification('Error while updating Package', data);
        } else {
          ShowSuccessNotification('Package updated successfully');
          setEditRequestFlag(true);
          onPackageCreated();
        }
      }
      else {
        const data: any = await ProjectService.postPackageFilesWithDetails<Request>(mapProjectRequest, tenantId, mapProjectRequest.projectId);
        if (data.code === '500') {
          ShowErrorNotification('Error while creating Package', data);
        } else {
          ShowSuccessNotification('Package created successfully');
          onPackageCreated();
        }
      }
      handleDismiss();
    } catch (error) {
      ShowErrorNotification('Error while creating Package', error);
    } finally {
      setLoading(false);
    }
  };

  // Handle file selection
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const files = Array.from(event.target.files || []);
    setSelectedFiles((prevFiles) => {
      const updatedFiles = prevFiles.map((fileList, i) => (i === index ? [...fileList, ...files] : fileList));
      return updatedFiles; // Return the updated state
    });
  };

  // Handle file removal
  const handleFileRemove = (fileToRemove: File, index: number) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.map((fileList, i) => (i === index ? fileList.filter((file: File) => file !== fileToRemove) : fileList)),
    );
  };

  const handleRemovePackage = (indexToRemove: number) => {
    setSelectedFilesRows(prevRows => prevRows.filter((_, i) => i !== indexToRemove));

    setFormData(prevData => ({
      ...prevData,
      requestPackages: prevData.requestPackages.filter((_: any, i: number) => i !== indexToRemove),
    }));
  };

  const handleAddPackage = (newData: any[]) => {

    setSelectedFilesRows((prevRows) => {
      const updatedRows = [...prevRows] as any;

      newData.forEach((newItem) => {
        const existingIndex = updatedRows.findIndex(
          (item: any) => item.projectRequestSentId === newItem.projectRequestSentId
        );

        if (existingIndex !== -1) {
          // Update the existing row's fields
          updatedRows[existingIndex] = {
            ...updatedRows[existingIndex],
            ...newItem,
          };
        } else {
          // Not found, so push it as a new entry
          updatedRows.push(newItem);
        }
      });

      return updatedRows;
    });

    setFormData((prevData: any) => ({
      ...prevData,
      requestPackages: [...newData],
    }));

  };



  // Define columns for the DataTable
  const columns: any = [
    {
      id: 'fileName',
      header: 'File Name',
      width: { type: 'auto', minWidth: 200 },
      accessor: 'fileName',
      columnType: 'string' as ColumnType,
      cell: ({ rowData }: any) => (
        <DataTableV2.DefaultCell>
          {rowData?.downloadFilePath?.split('/')?.pop() || 'N/A'}
        </DataTableV2.DefaultCell>
      ),
    },
    {
      id: 'fileType',
      header: 'File Type',
      accessor: 'fileType',
      width: { type: 'auto', minWidth: 200 },
      columnType: 'string' as ColumnType,
      cell: ({ rowData }: any) => (
        <DataTableV2.DefaultCell>{rowData?.downloadFilePath?.split('.')?.pop() || 'N/A'}</DataTableV2.DefaultCell>
      ),
    },
    {
      id: 'createdby',
      header: 'Created by',
      accessor: 'createdby',
      width: { type: 'auto', minWidth: 150 },
      columnType: 'string' as ColumnType,
      cell: ({ rowData }: any) => (
        <DataTableV2.DefaultCell>{rowData.assignedto || 'N/A'}</DataTableV2.DefaultCell>
      ),
    },
    {
      id: 'modifiedOn',
      header: 'Updated Date',
      accessor: 'createdOn',
      width: { type: 'auto', minWidth: 200 },
      cell: ({ rowData }: any) => (
        <DataTableV2.DefaultCell>{rowData?.updateDate !== null && rowData?.updateDate !== "" ? format(parseISO(rowData?.updateDate), 'MMM dd, yyyy hh:mm a') : rowData?.createdOn !== null && rowData?.createdOn !== "" ? format(parseISO(rowData?.createdOn), 'MMM dd, yyyy hh:mm a') : 'N/A'}</DataTableV2.DefaultCell>),
    },
    {
      id: 'source',
      header: 'Source',
      accessor: 'projectRequestName',
      width: { type: 'auto', minWidth: 200 },
      columnType: 'string' as ColumnType,
    },
    {
      id: 'actions',
      header: 'Actions',
      width: { type: 'auto', minWidth: 100 },
      accessor: 'fileName',
      cell: ({ rowData, rowIndex }: any) => (
        <DataTableV2.DefaultCell>
          <Flex justifyContent="flex-start" >
            <Tooltip text="Preview">
              <Button onClick={() => getFileForDocViewer(rowData)}>
                <Button.Prefix>
                  <DescriptionIcon />
                </Button.Prefix>
              </Button>
            </Tooltip>
            <Tooltip text="Delete">
              <Button variant="default" onClick={() => handleRemovePackage(rowIndex)}>
                <Button.Prefix>
                  <DeleteIcon />
                </Button.Prefix>
              </Button>
            </Tooltip>
          </Flex >
        </DataTableV2.DefaultCell>)
    }
  ]

  const removeFileSection = (sectionIndex: number) => {
    setSelectedFiles((prevSelectedFiles) => {
      // Create a copy of `selectedFiles`
      const updatedFiles = [...prevSelectedFiles];

      // Remove the entire section's files based on `sectionIndex`
      updatedFiles.splice(sectionIndex, 1);

      // Return the updated array without the section's files
      return updatedFiles;
    });
  };

  const mimeTypes: {
    pdf: string;
    jpg: string;
    jpeg: string;
    png: string;
    doc: string;
    docx: string;
    pptx: string;
    xlsx: string;
  } = {
    pdf: 'application/pdf',
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    png: 'image/png',
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  };

  const getMimeType = (fileExtension: string): string | undefined => {
    return mimeTypes[fileExtension as keyof typeof mimeTypes];
  };

  const supportedExtensions = ['txt', 'pdf', 'png', 'jpg', 'sql', 'htm', 'json', 'csv', 'docx', 'jpeg', 'doc', 'xls', 'xlsx'];

  const handleDocxPreview = async (rowInfo: any, base64File: string, fileExtension: string) => {
    try {

      const fileName = rowInfo.downloadFilePath.split('/').pop();
      const contentType = getMimeType(fileExtension) || '';

      const result = await PackageService.uploadDocxPreview(base64File, fileName, contentType) as any
      setPreviewFile({
        uri: result.downloadUrl,
        fileName: fileName,
        rowData: rowInfo,
        fileType: fileExtension,
      });
    } catch (error) {
      console.error("Error generating DOCX preview:", error);
      ShowErrorNotification("Error generating preview for DOCX file.");
    }
  };


  const getFileForDocViewer = async (rowInfo: any) => {
    try {
      setLoading(true);
      const fileExtension = rowInfo?.downloadFilePath?.split('.')?.pop()?.toLowerCase();
      console.log(fileExtension)

      if (!supportedExtensions.includes(fileExtension || '')) {
        setPreviewFile({
          fileName: rowInfo?.downloadFilePath?.split('/')?.pop(),
          unsupported: true,
          fileType: fileExtension,
          rowData: rowInfo
        });
        return;
      }

      const downloadFilePath = rowInfo?.downloadFilePath?.includes('uploads/')
        ? rowInfo?.downloadFilePath?.split('uploads/')[1]
        : rowInfo?.downloadFilePath?.includes('/requestsAttachments')
          ? rowInfo?.downloadFilePath?.split('/requestsAttachments')[1]
          : null;

      if (!downloadFilePath) {
        console.error("Unable to determine file path for:", rowInfo.downloadFilePath);
        alert("Invalid file path. Unable to preview the file.");
        return;
      }

      const base64File: any = await ProjectService.getUploadFileDataForStaff(rowInfo.downloadFilePath, tenantId);

      if (!base64File) {
        ShowErrorNotification("File data could not be retrieved.");
        return;
      }

      if (fileExtension === 'docx' || fileExtension === 'doc' || fileExtension === 'xls' || fileExtension === 'xlsx') {
        await handleDocxPreview(rowInfo, base64File, fileExtension);
      } else {

        const mimeType = getMimeType(fileExtension || '');
        const doc = {
          uri: `data:${mimeType};base64,${base64File}`,
          fileName: rowInfo?.downloadFilePath?.split('/')?.pop(),
          rowData: rowInfo,
          fileType: fileExtension
        };

        setPreviewFile(doc);
      }
    } catch (error) {
      console.error("Error in getFileForDocViewer:", error);
      ShowErrorNotification("An error occurred while trying to preview the file.");
    } finally {
      setLoading(false);
    }
  };

  // eslint-disable-next-line
  const downloadFileClick = function (rowInfo: any) {
    try {
      const downloadFilePath =
        rowInfo?.downloadFilePath?.includes('uploads/')
          ? rowInfo?.downloadFilePath?.split('uploads/')[1]
          : rowInfo?.downloadFilePath?.includes('/requestsAttachments')
            ? rowInfo?.downloadFilePath?.split('/requestsAttachments')[1]
            : null;

      if (!downloadFilePath) {
        console.error("Unable to determine file path for:", rowInfo.downloadFilePath);
        alert("Invalid file path. Unable to download the file.");
        return;
      }

      const downloadUrl = ProjectService.getDownloadFileHeader(downloadFilePath, tenantId);
      window.open(downloadUrl, "_blank");
    } catch (error) {
      console.error("Error in downloadFileClick:", error);
      alert("An error occurred while trying to download the file.");
    }
  };

  return (
    <Sheet show={isOpen} onDismiss={handleRequestCancelClick}>
      <Modal title={"Request : " + debouncedValues?.packageName} show={reqeusetCancelModal} onDismiss={handleRequestCancelCancelClick} size='small'>
        {editPackageDetails?.projectPackageStatusAutoId === defaultDraftRequestStatusId ? (
          <><Text>Do you want to discard changes to the draft?</Text><Flex flexDirection='row' justifyContent='flex-end' rowGap={4}>
            <Button width='130px' variant='default' onClick={handleRequestCancelNoSave}>
              Discard draft
            </Button>
            <Button width='130px' variant='accent' color='primary' onClick={handleRequestCancelCancelClick}>
              Continue editing
            </Button>


          </Flex></>
        ) : (
          <><Text>Are you sure you want to discard the changes of the package? All unsaved changes will be lost.</Text><Flex flexDirection='row' justifyContent='flex-end' rowGap={4}>
            <Button width='130px' variant='default' onClick={handleRequestCancelNoSavePackage}>
              Discard package
            </Button>
            <Button width='130px' variant='accent' color='primary' onClick={handleRequestCancelCancelClick}>
              Continue editing
            </Button>


          </Flex></>
        )}
      </Modal>
      {previewFile && (
        <Modal
          show={!!previewFile}
          onDismiss={() => setPreviewFile(null)}
          title={previewFile.fileName}
          size="large"
          style={{ padding: 0, margin: 0 }}
        >
          <FilePreview previewFile={previewFile} tenantId={tenantId} />
        </Modal>
      )}
      <Modal title={projectRequestId > 0 ? 'Update package confirmation' : 'Send package confirmation'} show={sendConfirmModal} onDismiss={handleNoClick} size={'small'}>
        {projectRequestId > 0 && (
          <Flex flexDirection='column' columnGap={16}>
            <Controller
              rules={{
                required: {
                  value: true,
                  message: 'Comments is required',
                },
                minLength: {
                  value: 1,
                  message: '',
                },
              }}
              name="requestUpdateComments"
              control={control}
              render={({ field, fieldState }) => (
                <FormField required>
                  <Label>Package update comments</Label>
                  <TextArea
                    {...field}
                    rows={3}
                    onChange={(e) => {
                      // Update the formData state directly on change
                      setFormData((prevState) => ({
                        ...prevState,
                        requestUpdateComments: e,
                      }));

                      // Also update the field value in the form
                      field.onChange(e);
                    }}
                    width={'100%'}
                    placeholder="Enter Package comments"
                    controlState={{
                      state: fieldState.error ? 'error' : 'valid',
                      hint: fieldState.error?.message,
                    }}

                  />
                </FormField>
              )}
            />
          </Flex>
        )}
        <Text>You are about to send this package to all recipients.</Text>
        <Flex flexDirection='row' justifyContent='flex-end' rowGap={4}>
          <Button width='80px' variant='default' onClick={handleNoClick}>
            Cancel
          </Button>
          <Button width='80px' variant='accent' color='primary' onClick={handleYesClick} disabled={projectRequestId > 0 && (formData.requestUpdateComments === '' || formData.requestUpdateComments === null)}>
            Confirm
          </Button>
        </Flex>

      </Modal>
      <LoadingStateComponent loading={loading || isTemplatesLoading || isRefetching} />
      <form onSubmit={handleSubmit(saveDetails)} onReset={() => reset()} noValidate>
        <TitleBar>
          <TitleBar.Title> {editPackageDetails?.packageName ? "Edit '" + editPackageDetails.packageName + "'" : 'Create new package'} </TitleBar.Title>

          <TitleBar.Action style={{ display: 'flex', alignItems: 'center' }}>
            <Flex justifyContent='flex-end' gap={8}>
              {autoSave ? (
                <Tooltip text="Auto saving">
                  <CycleIcon />
                </Tooltip>

              ) : (
                <Tooltip text="Successfully saved as draft ">
                  <SyncDoneIcon />
                </Tooltip>

              )}
              <Button width='130px' variant='emphasized' onClick={handleRequestCancelSave} disabled={disableSaveClose}>
                Save and close
              </Button>
              <VerticalLine />
              <Button width='80px' variant='default' onClick={handleRequestCancelClick}>
                Discard
              </Button>

              <Button width='80px' variant='emphasized' onClick={disablePrevTab} disabled={activeTab === 'Package'}>
                Previous
              </Button>
              {activeTab === 'Package' && (
                <Button width='80px' variant='emphasized' type='submit'>
                  Next
                </Button>
              )}
              {activeTab !== 'Package' && (
                <Button
                  width='80px'
                  variant='emphasized'
                  onClick={handleNextButtonClick}
                  disabled={activeTab === 'Package overview'}
                >
                  Next
                </Button>
              )}

              <Button
                width='80px'
                variant='accent'
                color='primary'
                onClick={() => handleSend()}
                disabled={tabOptions['Package overview'] === true}
              >
                Send
              </Button>
            </Flex>
          </TitleBar.Action>
        </TitleBar>
        <Flex flexDirection='row' gap={8} alignItems='end' justifyContent='flex-end'></Flex>
        <Tabs selectedIndex={currentTabIndex} onChange={handleTabChange}>
          {loading ? (
            <LoadingStateComponent loading={true} />
          ) : (
            <Tab
              title={'Package'}
              prefixIcon={
                Object.keys(createRequestErrors).length !== 0 || validationErrors['Files'] ? (
                  <CriticalIcon style={{ color: Colors.Icon.Critical.Default }} />
                ) : (
                  <ContainerIcon />
                )
              }
              disabled={tabOptions['Package']}
            >
              {validationErrors['Files'] ? (selectedFilesRows.length === 0 ? ShowValidationBanner(validationErrors['Files'], "Add atleast one file to proceed") : "") : ""}
              <PackageMessage
                control={control}
                fields={fields}
                selectedFiles={selectedFiles}
                handleFileRemove={handleFileRemove}
                handleFileChange={handleFileChange}
                fileInputRefs={fileInputRefs}
                remove={remove}
                removeFileSection={removeFileSection}
                editPackageDetails={editPackageDetails}
                updateProjectRequest={updateProjectRequest}
                editRequestFlag={editRequestFlag}
                setEditRequestFlag={setEditRequestFlag}
                disableReqeustMessage={disableReqeustMessage}
                projectRequests={projectRequests.current}
                refetchProjectRequests={refetchProjectRequests}
                setLoading={setLoading}
                handleAddPackage={handleAddPackage}
                selectedFilesRows={selectedFilesRows}
                projectPackages={projectPackages}
                validationErrors={validationErrors}
              />
              <DataTableV2
                data={selectedFilesRows}
                columns={columns}
                fullWidth
                resizable
                sortable
                variant={{ rowDensity: 'comfortable', rowSeparation: 'none', verticalDividers: false, contained: false }}
              >
                <DataTableV2.EmptyState>No file added, add atleast one file to proceed.</DataTableV2.EmptyState>
              </DataTableV2>
            </Tab>
          )}

          <Tab
            title='Recipients'
            prefixIcon={
              validationErrors['recipients'] ? (
                <CriticalIcon style={{ color: Colors.Icon.Critical.Default }} />
              ) : (
                <MailIcon />
              )
            }
            disabled={tabOptions['Recipients']}
          >
            <PackageRecipients
              projectDetails={projectDetails}
              peopleList={peopleList}
              recipients={recipients}
              handleSubmit={handleSubmit}
              validationErrors={validationErrors}
              selectedRows={selectedRows}
              updateProjectPeopleRelations={updateProjectPeopleRelations}
              handleRecipientsChange={handleRecipientsChange}
              updateProjectRequest={updateProjectRequest}
            //selectedReceipents={distinctSentToEmails}
            />
          </Tab>

          <Tab title='Package overview' prefixIcon={<SourceIcon />} disabled={tabOptions['Package overview']}>
            <PackageSummary formValues={formValues} formData={formData} selectedFiles={selectedFiles} />
          </Tab>
        </Tabs>
      </form>
      <FormFieldMessages />
    </Sheet>
  );
};

export default CreatePackage;
