import React, { useEffect, useRef, useState } from 'react';
import { Modal, FormFieldMessages, Tabs, Tab, Sheet, TitleBar, TextInput, Label, FormField, TextArea, Tooltip } from '@dynatrace/strato-components-preview';
import { Text } from '@dynatrace/strato-components/typography';
import { Button } from '@dynatrace/strato-components/buttons';
import { Flex } from '@dynatrace/strato-components/layouts';
import { Colors } from '@dynatrace/strato-design-tokens';
import { useForm, useWatch, useFieldArray, Controller } from 'react-hook-form';
import { RequestIcon, MailIcon, SourceIcon, CriticalIcon, CycleIcon, SyncDoneIcon } from '@dynatrace/strato-icons';
import { ProjectService } from '../../../services/ProjectService';
import { ProjectDetail, ProjectPeopleRelation } from '../../../types/Project';
import { RequestDetails } from '../../../types/Request';
import { ShowErrorNotification, ShowSuccessNotification } from '../../../utils/Notifications';
import { LoadingStateComponent } from '../../../components/LoadingStateComponent';
import { ItemInfo } from '../../../types/ListItemInfo';
import { useAppInfo } from '../../../contexts/AppContext';
import { TemplateService } from '../../../services/TemplateService';
import styled from 'styled-components';
import { RequestMessage } from './RequestMessage';
import { RequestSummary } from './RequestSummary';
import { RequestRecipients } from './RequestRecipients';
import { useRequestTemplates } from '../../../hooks/use-minerva-data';

const VerticalLine = styled.div`
  border-left: 2px solid ${Colors.Border.Neutral.Default};
  margin: 0 8px;
`;
export interface CreateRequestProps {
  peopleList: ProjectPeopleRelation[];
  projectDetails: ProjectDetail | any;
  isOpen: boolean;
  onClose: () => void;
  onRequestCreated: (newRequest: RequestDetails) => void;
  editRequestDetails?: any;
  refetchProjectRequestDrafts: any;

}
export const CreateRequest = (props: CreateRequestProps) => {
  const { isOpen, onClose, peopleList, projectDetails, onRequestCreated, editRequestDetails, refetchProjectRequestDrafts } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<Record<string, boolean>>({});
  const [selectedUsers, setSelectedUsers] = useState<any[]>();
  const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});
  const [sendConfirmModal, setSendConfirmModal] = useState<boolean>(false);
  const [recipients, setRecipients] = useState<ProjectPeopleRelation[]>(peopleList);
  const [templateModalOpen, setTemplateModalOpen] = useState(false);
  const [projectRequestId, setProjectRequestId] = useState(0);
  const [editRequestFlag, setEditRequestFlag] = useState(true);
  // use refs for hooks
  const [activeTab, setActiveTab] = useState('Request');
  const [selectedFiles, setSelectedFiles] = useState<File[][]>([[]]); // Array of file arrays for each section
  const fileInputRefs = useRef<HTMLInputElement[]>([]); // Array of file input refs
  const [disableReqeustMessage, setDisableReqeustMessage] = useState(false);
  const [autoSave, setAutoSave] = useState(false);
  const [createDraft, setCreateDraft] = useState(true);
  const [currentDraftRequestId, setCurrentDraftRequestId] = useState<number>(0);
  const [reqeusetCancelModal, setReqeusetCancelModal] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [uniqueReminders, setUniqueReminders] = useState<Map<number, number>>(new Map());
  const {
    tenantId,
    documentTypes,
    tenantRequestReminders: reminderListData,
    tenantRequestStatusList: requestStatusList,
    tenantProjectRequestStatusList: projectRequestStatusList,
  } = useAppInfo();

  const otherDocumentTypeId = documentTypes?.find(
    (docType) => docType.documentTypeId === -1 && docType.tenantId === tenantId,
  )?.documentTypeAutoId;
  const {
    isLoading: isTemplatesLoading,
    data: requestTemplateData,
    refetch: refetchRequestTemplates,
    isRefetching,
  } = useRequestTemplates((tenantId && Number(tenantId) !== 0) ? Number(tenantId) : Number(localStorage.getItem("tenantId")));
  const defaultRequestStatusId = requestStatusList?.find((status) => status.statusName === 'New')?.statusAutoId;
  const defaultDraftRequestStatusId = projectRequestStatusList?.find((status) => status.statusName === 'inprogress/draft')?.statusAutoId;
  const disableSaveClose = editRequestDetails ? editRequestDetails?.projectRequest?.projectRequestStatusAutoId !== defaultDraftRequestStatusId : false;
  // console.log(projectRequestStatusList);
  // let distinctSentToEmails = [];
  // if( editRequestDetails){
  //   distinctSentToEmails = Array.from(
  //   new Set(editRequestDetails.projectRequestSentList.map((item: any) => item.sentTo))
  //   );
  // }

  // setSelectedReceipents(distinctSentToEmails);
  useEffect(() => {
    if (peopleList.length > 0) {
      setRecipients(peopleList);

    }
  }, [peopleList]);

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    trigger,
    formState: { errors },
  } = useForm<{
    template: string;
    projectRequestId: number;
    projectId: number;
    tenantId: number;
    requestMessage: string;
    requestDescription: string;
    requestUpdateComments: string;
    requestDetails: RequestDetails[] | any;
    recipientDetails: any;
    requestTemplateName: string;
    requestTemplateDescription: string;
    remindersAutoId: number;
    reminderEndDate: string;
  }>({
    reValidateMode: 'onChange',
    defaultValues: {
      requestDetails: [
        {
          projectRequestDetailsId: 0,
          projectRequestId: 0,
          projectId: 0,
          tenantId: 0,
          label: '',
          documentTypeAutoId: otherDocumentTypeId,
          statusAutoId: 0,
          statusName: '',
          instructions: '',
          attachedFiles: selectedFiles,
        },
      ],
      recipientDetails: [
        {
          email: '',
          firstName: '',
          lastName: '',
          peopleId: '',
        },
      ],
    },
    mode: undefined,
  });

  let createRequestErrors = errors;
  const formValues = useWatch({
    control,
  });
  const [debouncedValues, setDebouncedValues] = useState<any>(formValues);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValues(formValues);
    }, 2000);

    return () => {
      clearTimeout(handler);
    };
  }, [formValues]);

  useEffect(() => {
    if (isOpen && editRequestDetails?.projectRequest?.projectRequestStatusAutoId === defaultDraftRequestStatusId && (Object.keys(debouncedValues).length > 0 || selectedFiles.length > 0)) {
      setCreateDraft(false);
      setCurrentDraftRequestId(editRequestDetails?.projectRequest?.projectRequestId);
      saveDraftRequest(debouncedValues);
    }
    else if (isOpen && !editRequestDetails && (Object.keys(debouncedValues).length > 0 || selectedFiles.length > 0)) {
      setCreateDraft(true);
      setCurrentDraftRequestId(0);
      saveDraftRequest(debouncedValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValues, selectedFiles]);

  const updatedFields = useWatch({
    control,
    name: 'requestDetails',
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'requestDetails',
  });
  const [formData, setFormData] = useState({
    template: 0,
    projectRequestId: 0,
    requestMessage: '',
    requestDescription: '',
    requestUpdateComments: '',
    requestTemplateName: '',
    requestTemplateDescription: '',
    requestDetails: [
      {
        projectRequestDetailsId: 0,
        projectRequestId: 0,
        tenantId: 0,
        label: '',
        documentTypeAutoId: otherDocumentTypeId,
        documentTypeName: '',
      },
    ],

    remindersAutoId: 0,
    reminderEndDate: '',
    recipients: [
      {
        email: '',
        firstName: '',
        lastName: '',
        peopleId: '',
      },
    ],
  });

  // UseEffect to update form values when externalData changes
  const saveDraftRequest = async (formValues: { template?: string | undefined; projectRequestId?: number | undefined; projectId?: number | undefined; tenantId?: number | undefined; requestMessage?: string | undefined; requestDescription?: string | undefined; requestUpdateComments?: string | undefined; requestDetails?: RequestDetails[] | any; recipientDetails: any; requestTemplateName?: string | undefined; requestTemplateDescription?: string | undefined; remindersAutoId?: number | undefined; reminderEndDate?: string | undefined; }) => {
    setAutoSave(true);
    try {
      const base64FilesPromises = selectedFiles.map((fileList) =>
        Promise.all(
          fileList.map(async (file: any) => {
            // if this is already read base64String then just use it as this file is not available
            // on this machine or not readable
            const base64 = file.base64String ? file.base64String : await getBase64(file);
            return {
              name: file.name,
              size: file.size,
              type: file.type,
              base64String: base64, // base64 encoded string
              projectRequestDetailsFilesId: file.projectRequestDetailsFilesId || 0,
            };
          }),
        ),
      );

      const base64Files = await Promise.all(base64FilesPromises);
      // Set autoSave to true
      const requestDetails = formValues.requestDetails || [];
      const recipients = formValues.recipientDetails || [];
      let mapProjectRequest: any = {
        projectRequestId: projectRequestId,
        requestName: '',
        requestDescription: '',
        remindersAutoId: '',
        requestDetails: [],
        document: [],
        requestSendTo: [],
        tenantId: 0,
        projectId: 0,
      };
      mapProjectRequest.projectRequestId = currentDraftRequestId;
      mapProjectRequest.projectRequestStatusAutoId = defaultDraftRequestStatusId;
      mapProjectRequest.requestDescription = formValues?.requestDescription?.slice(0, 45) || formValues?.requestDescription;
      mapProjectRequest.requestMessage = formValues.requestMessage;
      mapProjectRequest.requestUpdateComments = formValues.requestUpdateComments;
      mapProjectRequest.requestDetails = [];
      mapProjectRequest.requestSendTo = [];
      mapProjectRequest.projectId = projectDetails?.project.projectId;
      mapProjectRequest.tenantId = tenantId;
      mapProjectRequest.statusAutoId = defaultRequestStatusId;
      mapProjectRequest.remindersAutoId = formValues?.remindersAutoId !== null? formValues?.remindersAutoId : reminderListData?.[0].remindersAutoId;
      mapProjectRequest.reminderEndDate = formValues.reminderEndDate !== '' ? formValues.reminderEndDate : null;
      requestDetails.forEach((reqDetails: { label: any; documentTypeAutoId: any; projectRequestDetailsId: any; instructions: any; attachedFiles: any; }, index: number) => {
        mapProjectRequest.requestDetails.push({
          label: reqDetails.label,
          documentTypeAutoId: reqDetails.documentTypeAutoId,
          projectRequestDetailsId: reqDetails.projectRequestDetailsId,
          projectRequestId: currentDraftRequestId,
          projectId: mapProjectRequest.projectId,
          tenantId: tenantId,
          instructions: reqDetails.instructions,
          attachedFiles: base64Files[index] || [],
        });
      });
      mapProjectRequest.reminderEndDate = mapProjectRequest.reminderEndDate?.value ? mapProjectRequest.reminderEndDate?.value : mapProjectRequest.reminderEndDate;
      // mapProjectRequest.recipients = formData.recipients;
      if (createDraft && formValues?.requestDescription && formValues.requestDescription !== "" && formValues?.requestDescription?.length > 3) {
        if (!editRequestDetails || editRequestDetails?.projectRequest?.projectRequestStatusAutoId !== defaultDraftRequestStatusId) {
          const data: any = await ProjectService.saveDraftRequestDetails(mapProjectRequest, tenantId, projectDetails?.project.projectId);
          if (data.code === "200") {
            setCreateDraft(false);
            // console.log("data.id:", data.id, "Converted:", Number(data.id));
            setCurrentDraftRequestId(Number(data.id));
          }
          else {
            ShowErrorNotification("Something went wrong with Auto Save", data.message);
          }
        }


      }
      else if (!createDraft && currentDraftRequestId > 0) {
        if (!editRequestDetails || editRequestDetails?.projectRequest?.projectRequestStatusAutoId === defaultDraftRequestStatusId) {
          if (recipients.length > 0 && recipients[0].firstName !== "") {
            requestDetails.forEach((reqDetails: any) => {
              selectedUsers?.forEach((user: any) => {
                mapProjectRequest.requestSendTo.push({
                  sendTo: user.email,
                  sendCC: '',
                  statusAutoId: defaultRequestStatusId,
                  label: reqDetails.label,
                  instructions: reqDetails.instructions,
                  peopleId: user.peopleId,
                  documentTypeAutoId: reqDetails.documentTypeAutoId,
                  remindersAutoId: formData.remindersAutoId,
                });
              });
            });
          }
          const data: any = await ProjectService.updateDraftRequestDetails(mapProjectRequest, tenantId, projectDetails?.project.projectId, currentDraftRequestId);
          if (data.code === "200") {
            setCurrentDraftRequestId(Number(data.id));
            setCreateDraft(false);

          }
          else {
            ShowErrorNotification("Something went wrong with Auto Save", data.message);
          }
        }
      }
      // 
      // console.log(formValues);
      // console.log(autoSave);
    }
    catch (e) {

    }
    finally {
      setAutoSave(false);
    }


  }

  const updateProjectRequest = (editProjectRequestDetails: any) => {

    reset({
      template: '',
      projectRequestId: editProjectRequestDetails.projectRequest.projectRequestId,
      requestMessage: editProjectRequestDetails.projectRequest.projectRequestDescription,
      requestDescription: editProjectRequestDetails.projectRequest.projectRequestName,
      requestTemplateName: '',
      requestTemplateDescription: '',
      remindersAutoId: editProjectRequestDetails.projectRequest.remindersAutoId,
      reminderEndDate: editProjectRequestDetails.projectRequest.reminderEndDate ? editProjectRequestDetails.projectRequest.reminderEndDate.split("T")[0] : undefined,
      requestDetails: editProjectRequestDetails.projectRequestDetailList.map((detail: any) => ({
        label: detail.label,
        instructions: detail.instructions,
        documentTypeAutoId: otherDocumentTypeId,
        projectRequestDetailsId: detail.projectRequestDetailsId,
        statusAutoId: editProjectRequestDetails.projectRequest.statusAutoId,
        statusName: editProjectRequestDetails.projectRequest.statusName,

      })),
    });

    const remindersMap = new Map<number, number>();

    editProjectRequestDetails.projectRequestSentList.forEach((item: { peopleId: any; remindersAutoId: any; }) => {
      if (!remindersMap.has(item.peopleId)) {
        remindersMap.set(item.peopleId, item.remindersAutoId);
      }
    });

    setUniqueReminders(remindersMap);

    if(editProjectRequestDetails?.projectRequest?.projectRequestStatusAutoId !== defaultDraftRequestStatusId){
      setProjectRequestId(editProjectRequestDetails.projectRequest.projectRequestId);
    }
    
    editProjectRequestDetails.projectRequestDetailList.forEach((details: any, index: number) => {
      // Update instructions if they exist
      if (details.instructions) {
        //console.log(`Updating instructions for index ${index}:`, details.instructions);
        // Handle the logic to update the instruction in your UI here
      }


      // Check if attachedFiles exist
      if (details.projectRequestDetailsFiles && details.projectRequestDetailsFiles.length > 0) {
        const attachedFiles = details.projectRequestDetailsFiles.map((file: any) => ({
          name: file.filePath.split(details.projectRequestDetailsId + '/')[1],
          size: findSize(file.fileData),
          type: file.type,
          base64String: file.fileData,
          projectRequestDetailsFilesId: file.projectRequestDetailsFilesId,
        }));
        setSelectedFiles((prevSelectedFiles) => {
          const updatedSelectedFiles = [...prevSelectedFiles];
          updatedSelectedFiles[index] = attachedFiles;
          return updatedSelectedFiles;
        });
      }
      else {
        setSelectedFiles((prevSelectedFiles) => {
          const updatedSelectedFiles = [...prevSelectedFiles];
          updatedSelectedFiles[index] = [];
          return updatedSelectedFiles;
        });
      }
    });

    const distinctSentToEmails = Array.from(
      new Set(editRequestDetails.projectRequestSentList.map((item: any) => item.peopleId))
    );
    const distinctSentToEmailsList = editRequestDetails.projectRequestSentList.map((item: any) =>
      ({ 'email': item.sentTo, 'peopleId': item.peopleId, 'statusAutoId': item.statusAutoId, 'uniqueLink': item.uniqueLink }));

    const indexMap: any = distinctSentToEmails.reduce((acc: any, peopleId) => {
      const personIndex = peopleList.findIndex(person => person.peopleId === peopleId);
      if (personIndex !== -1) {
        // We use personIndex as string here to match the Record<string, boolean> type
        acc[personIndex.toString()] = true;
      }
      return acc;
    }, {} as Record<string, boolean>);
    // setSelectedReceipents(indexMap);
    setSelectedRows(indexMap);

    const updatedRecipients = peopleList
      ?.filter((row) => distinctSentToEmailsList.some((dist: any) => dist.peopleId === row.peopleId))
      .map((newRow) => {
        const existingRecipient = distinctSentToEmailsList.find((dist: any) => dist.peopleId === newRow.peopleId);
        return {
          email: newRow.email,
          firstName: newRow.firstName,
          lastName: newRow.lastName,
          status: existingRecipient?.statusAutoId,
          peopleId: newRow.peopleId.toString(),
          uniqueLink: existingRecipient?.uniqueLink,
          projectRequestSendToId: existingRecipient?.projectRequestSendToId,
        };
      });


    setSelectedUsers(updatedRecipients);
    setFormData((prevFormData) => ({
      ...prevFormData,
      recipients: updatedRecipients ?? [],
    }));

    const pendingRequestsDisable = editRequestDetails.projectRequestSentList.some((item: any) => item.statusName !== 'New');
    setDisableReqeustMessage(pendingRequestsDisable);
  }

  useEffect(() => {
    if (isOpen) {
      reset({
        template: '',
        requestMessage: '',
        requestDescription: '',
        requestTemplateName: '',
        requestTemplateDescription: '',
        requestDetails: [{
          label: '',
          documentTypeAutoId: otherDocumentTypeId,
          instructions: ''
        }],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, reset]);

  const findSize = (base64: string) => {
    // Calculate the padding
    const padding = (base64.match(/=*$/) || [])[0]?.length;

    // Calculate the size in bytes
    const fileSizeInBytes = (base64.length * 3) / 4 - (padding ? padding : 0);
    return fileSizeInBytes;
  };

  // updates project people relations post creating a new one or deleting
  const updateProjectPeopleRelations = async (addPersonsVisible: ItemInfo) => {
    try {
      setLoading(true);
      const data = await ProjectService.getProjectWithDetailsID<ProjectDetail>(addPersonsVisible?.id ?? 0, tenantId);
      setRecipients(data.projectPeopleRelation);
    } catch (error) {
      ShowErrorNotification('Error updating project people relation', error);
    } finally {
      // setLoadingPeopleRelations(false);
      setLoading(false);
    }
  };

  const handleTemplateDetailsOpen = handleSubmit(async (values) => {
    const errors = validateCreateRequestTab(values);
    if (Object.keys(errors).length === 0) {
      setValidationErrors({});
    } else {
      setValidationErrors(errors);
    }
    // handleCreateRequestNextButtonClick(values);
    if (Object.keys(errors).length === 0) {
      setTemplateModalOpen(true)
    }
  });

  const handleTemplateChange = async (value: any) => {
    try {
      const template = requestTemplateData?.find((temp: any) => temp.requestTemplateId === value);
      if (template) {
        const { requestTemplateName, requestTemplateDescription, requestDescription, requestMessage, requestTemplateDetails } = template;

        reset({
          template: value,
          requestTemplateName: requestTemplateName,
          requestTemplateDescription: requestTemplateDescription,
          requestDescription: requestDescription,
          requestMessage: requestMessage,
          requestDetails: requestTemplateDetails.map((detail: any) => ({
            label: detail.label,
            instructions: detail.instructions,
            documentTypeAutoId: detail.documentTypeAutoId,
          })),
        });

        template.requestTemplateDetails.forEach((details: any, index: number) => {
          // Update instructions if they exist
          if (details.instructions) {
            //console.log(`Updating instructions for index ${index}:`, details.instructions);
            // Handle the logic to update the instruction in your UI here
          }

          // Check if attachedFiles exist
          if (details.requestTemplateDetailsFiles && details.requestTemplateDetailsFiles.length > 0) {
            const attachedFiles = details.requestTemplateDetailsFiles.map((file: any) => ({
              name: file.filePath.split(details.requestTemplateDetailsId + '/')[1],
              size: findSize(file.fileData),
              type: file.type,
              base64String: file.fileData,
            }));
            setSelectedFiles((prevSelectedFiles) => {
              const updatedSelectedFiles = [...prevSelectedFiles];
              updatedSelectedFiles[index] = attachedFiles;
              return updatedSelectedFiles;
            });
          }
          else {
            setSelectedFiles((prevSelectedFiles) => {
              const updatedSelectedFiles = [...prevSelectedFiles];
              updatedSelectedFiles[index] = [];
              return updatedSelectedFiles;
            });
          }
        });
      }
    } catch (error) {
      ShowErrorNotification('Error loading template data', error);
    }
  };

  const handleRecipientsChange = (
    selectedRowsData?: any,
  ) => {
    const selectedIndices = Object.keys(selectedRowsData)
      .filter((key) => selectedRowsData[key])
      .map((key) => parseInt(key, 10));

    const selectedRowsWithData = selectedIndices.map((index) => recipients[index]) as any;

    setSelectedRows(selectedRowsData);

    // Map the selected indices to the actual row data
    const updatedRecipients = selectedRowsWithData?.map((row: any) => ({
      email: row.email,
      firstName: row.firstName,
      lastName: row.lastName,
      peopleId: row.peopleId,
    }));
    setSelectedUsers(selectedRowsWithData);
    setFormData((prevFormData) => ({
      ...prevFormData,
      recipients: updatedRecipients ?? [],
    }));
    setValue("recipientDetails", updatedRecipients);
    setValidationErrors({});
  };

  const validateCreateRequestTab = (values: any) => {
    const errors: { [key: string]: string } = {};
    if (!values.requestDescription) {
      errors['Request description'] = 'Request description is required';
    }
    if (!values.requestMessage) {
      errors['Request message'] = 'Request message is required';
    } //
    values.requestDetails.map((document: any) => {
      if (!document.label) {
        errors['Document Name'] = 'File name is required';
      }
      if (!document.documentTypeAutoId) {
        errors['Document Type'] = 'File type is required';
      }
      return null;
    });

    return errors;
  };

  const validateAddRecipientsTab = () => {
    const errors: { [key: string]: string } = {};
    if (formData?.recipients.length === 0 || formData?.recipients[0].email === '') {
      errors['recipients'] = 'Atleast one recipient must be selected';
    }

    return errors;
  };

  const [tabOptions, setTabOptions] = useState({
    'Request': false,
    'Recipients': true,
    'Request overview': true,
  });

  const handleCreateRequestNextButtonClick = (values: any) => {
    if (activeTab === 'Request') {
      // saveDetails(data);
      const errors = validateCreateRequestTab(values);
      if (Object.keys(errors).length === 0) {
        setValidationErrors({});
        enableNextTab();
      } else {
        setValidationErrors(errors);
      }
    }
  };
  const handleNextButtonClick = () => {
    if (activeTab === 'Recipients') {
      const errors = validateAddRecipientsTab();
      if (Object.keys(errors).length === 0) {
        setValidationErrors({});
        enableNextTab();
      } else {
        setValidationErrors(errors);
      }
    }
  };

  const enableNextTab = () => {
    const nextIndex = (currentTabIndex + 1) % tabs.length;
    setTabOptions((prevOptions) => ({
      ...prevOptions,
      [tabs[nextIndex]]: false,
    }));
    setCurrentTabIndex(nextIndex);
    setActiveTab(tabs[nextIndex]);
  };
  const disablePrevTab = () => {
    // const nextIndex = currentTabIndex % tabs.length;
    const prevIndex = (currentTabIndex - 1) % tabs.length;
    setCurrentTabIndex(prevIndex);
    setActiveTab(tabs[prevIndex]);
  };
  // const [reminderList, setReminderList] = useState<ReminderDetails[]>([]);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const tabs = ['Request', 'Recipients', 'Request overview'];

  const handleAddMore = () => {
    append({
      documentTypeName: '',
      label: '',
      documentTypeAutoId: otherDocumentTypeId,
      instructions: '',
      tnantId: tenantId,
    });
    setSelectedFiles((prevFiles) => [...prevFiles, []]); // Add an empty array for new section files
  };

  const handleAddDuplicate = (index: number) => {
    append({
      ...updatedFields[index],
      projectRequestDetailsId: null,
      projectRequestSendToId: 0,
    });

    setSelectedFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.push([...prevFiles[index]]);
      return updatedFiles;
    });
  };

  // const getDocumentTypeName = (documentTypeAutoId: any) => {
  //   return documentTypes.current.find((docType) => docType.documentTypeAutoId === documentTypeAutoId)?.documentTypeName;
  // };

  // save the information
  const saveDetails = async (values: any) => {
    const { template, requestMessage, requestDescription, requestTemplateName, requestTemplateDescription, remindersAutoId, reminderEndDate, requestDetails } = values;
    const base64FilesPromises = selectedFiles.map((fileList) =>
      Promise.all(
        fileList.map(async (file: any) => {
          // if this is already read base64String then just use it as this file is not available
          // on this machine or not readable
          const base64 = file.base64String ? file.base64String : await getBase64(file);
          return {
            name: file.name,
            size: file.size,
            type: file.type,
            base64String: base64, // base64 encoded string
            projectRequestDetailsFilesId: file.projectRequestDetailsFilesId || 0,
          };
        }),
      ),
    );

    const base64Files = await Promise.all(base64FilesPromises);
    const reminderEndDatee = reminderEndDate?.value ? reminderEndDate?.value : reminderEndDate
    setFormData((prevData) => ({
      ...prevData,
      template: template || prevData.template,
      projectRequestId: projectRequestId,
      requestMessage: requestMessage || prevData.requestMessage,
      requestDescription: requestDescription || prevData.requestDescription,
      requestTemplateName: requestTemplateName || prevData.requestTemplateName,
      requestTemplateDescription: requestTemplateDescription || prevData.requestTemplateDescription,
      remindersAutoId: remindersAutoId || prevData.remindersAutoId,
      reminderEndDate: reminderEndDatee || prevData.reminderEndDate,
      requestDetails: requestDetails.map((doc: RequestDetails, index: number) => ({
        ...prevData.requestDetails[index],
        label: doc.label,
        projectRequestDetailsId: doc.projectRequestDetailsId,
        projectRequestId: doc.projectRequestId,
        tenantId: doc.tenantId,
        documentTypeAutoId: doc.documentTypeAutoId,
        instructions: doc.instructions,
        attachedFiles: base64Files[index] || [],
      })),
    }));
    handleCreateRequestNextButtonClick(values);
  };

  const handleRequestCancelClick = () => {
    if (debouncedValues?.requestDescription?.length > 3) {
      setReqeusetCancelModal(true);
    }
    else {
      handleDismiss();
    }
  }

  const handleRequestCancelCancelClick = () => {
    setReqeusetCancelModal(false);
  }
  const handleRequestCancelSave = async () => {
    const isValid = await trigger('requestDescription');
    if (isValid) {
    handleDismiss();
    }
  }
  const handleRequestCancelNoSave = () => {
    if(editRequestDetails?.projectRequest?.projectRequestStatusAutoId === defaultDraftRequestStatusId){
      const data: any = ProjectService.deleteProjectRequestDraftById(currentDraftRequestId, tenantId, projectDetails?.project.projectId);
      if (data.code === '500') {
        ShowErrorNotification("Error while deleting the request as draft", data.message);
      }
    }
    

    handleDismiss();
  }
  const handleDismiss = () => {
    
    // Resetting additional form data state if needed
    setDebouncedValues((prevValues: any) => ({
      ...prevValues,
      requestDescription: "",
    }));
    setFormData({
      template: 0,
      projectRequestId: 0,
      requestMessage: '',
      requestDescription: '',
      requestUpdateComments: '',
      requestTemplateName: '',
      requestTemplateDescription: '',
      remindersAutoId: 0,
      reminderEndDate: '',
      requestDetails: [
        {
          projectRequestDetailsId: 0,
          projectRequestId: 0,
          tenantId: 0,
          label: '',
          documentTypeAutoId: otherDocumentTypeId,
          documentTypeName: '',
        },
      ],
      recipients: [
        {
          email: '',
          firstName: '',
          lastName: '',
          peopleId: '',
        },
      ],
    });
    reset({
      template: '',
      requestMessage: '',
      requestDescription: '',
      requestTemplateName: '',
      requestTemplateDescription: '',
      requestDetails: [
        {
          label: '',
          documentTypeAutoId: otherDocumentTypeId,
          instructions: '',
        },
      ],
    });
    fileInputRefs.current = [];
    setSelectedRows({});
    // Reset the active tab to the first tab
    setCurrentTabIndex(0);
    setActiveTab('Request');
    setValidationErrors({});
    // Optionally, reset tab options if needed
    setTabOptions({
      'Request': false,
      'Recipients': true,
      'Request overview': true,
    });
    setSelectedFiles([[]]);
    setEditRequestFlag(true);
    refetchProjectRequestDrafts();
    setCreateDraft(true);
    setProjectRequestId(0);
    setCurrentDraftRequestId(0);
    setReqeusetCancelModal(false);
    onClose();
  };

  // Function to handle tab change
  const handleTabChange = (index: number) => {
    // Implement any logic needed before switching tabs, such as validation
    const isValid = true;
    if (isValid) {
      setCurrentTabIndex(index);
      setTabOptions((prevOptions) => ({
        ...prevOptions,
        [Object.keys(tabOptions)[index]]: false,
      }));
      const tabKeys = Object.keys(tabOptions);
      const tabKeyToIndex = tabKeys.reduce((acc, key, index) => {
        acc[index] = key;
        return acc;
      }, {} as { [key: number]: string });
      setActiveTab(tabKeyToIndex[index]);
    } else {
      // Handle case when validation fails
      // console.warn('Validation failed, cannot change tab.');
    }
  };

  const getBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleSaveTemplate = handleSubmit(async (values: any) => {
    const { requestMessage, requestTemplateName, requestTemplateDescription, requestDescription, requestDetails } = values;

    const base64FilesPromises = selectedFiles.map((fileList) =>
      Promise.all(
        fileList.map(async (file: any) => {
          // if this is already read base64String then just use it as this file is not available
          // on this machine or not readable
          const base64 = file.base64String
            ? file.base64String
            : file.base64String
              ? file.base64String
              : await getBase64(file);
          return {
            name: file.name,
            size: file.size,
            type: file.type,
            base64String: base64, // base64 encoded string
          };
        }),
      ),
    );

    const base64Files = await Promise.all(base64FilesPromises);

    let formInit: any = {
      requestTemplateId: 0,
      requestTemplateName: '',
      requestTemplateDescription: '',
      requestMessage: '',
      requestDescription: '',
      remindersAutoId: 0,
      requestTemplateDetails: [
        {
          documentTypeAutoId: otherDocumentTypeId,
          label: '',
          instructions: '',
          attachedFiles: [],
        },
      ],
    };

    formInit.requestTemplateDetails = requestDetails.map((rd: any, index: number) => ({
      label: rd.label,
      instructions: rd.instructions,
      documentTypeAutoId: rd.documentTypeAutoId,
      attachedFiles: base64Files[index] || [], // Attach base64 files
    }));

    formInit.requestDescription = requestDescription;
    formInit.requestMessage = requestMessage;

    formInit.requestTemplateName = requestTemplateName;
    formInit.requestTemplateDescription = requestTemplateDescription;
    formInit.remindersAutoId = reminderListData?.[0].remindersAutoId.toString();
    // formInit.requestTemplateDetails = requestDetails;

    try {
      const errors = validateCreateRequestTab(values);
      if (Object.keys(errors).length === 0) {
        setValidationErrors({});
      } else {
        setValidationErrors(errors);
      }
      // handleCreateRequestNextButtonClick(values);
      if (Object.keys(errors).length === 0) {
        try {
          setLoading(true);
          const data: any = await TemplateService.postRequestTemplates(formInit, tenantId);
          if (data.code === 500) {
            ShowErrorNotification('Error creating template', data.message);
            refetchRequestTemplates();

            // onTemplateClose(tenantId);
          } else {
            ShowSuccessNotification(values.requestTemplateName + ' successfully Saved.');
            refetchRequestTemplates();
          }
          // onTemplateClose(tenantId);
        } catch (error) {
          ShowErrorNotification('Error creating template', error);
        }
      }
      setTemplateModalOpen(false)
    } catch (error) {
      ShowErrorNotification('Error creating template', error);
    } finally {
      setLoading(false);
    }
  });
  const handleSend = handleSubmit((values) => {
    // Perform validations
    const CreateRequestErrors = validateCreateRequestTab(values);
    const ReceipentsErrors = validateAddRecipientsTab();

    // Merge both errors
    const combinedErrors = { ...CreateRequestErrors, ...ReceipentsErrors };

    // If there are any errors, set them in validationErrors
    if (Object.keys(combinedErrors).length !== 0) {
      setValidationErrors(combinedErrors);
    } else {
      // If there are no errors, clear the validationErrors
      setValidationErrors({});
      // Show the send confirmation modal
      setSendConfirmModal(true);
    }
  });

  // Function to handle the Yes click
  const handleYesClick = () => {
    CreateRequestSubmitClick(formData, projectDetails, documentTypes);
    setSendConfirmModal(false);
  };

  // Function to close the modal when No is clicked
  const handleNoClick = () => {
    setSendConfirmModal(false);
  };


  const CreateRequestSubmitClick = async (formData: any, projectDetails: any, documentTypes: any) => {
    const { requestDetails } = formData;
    let mapProjectRequest: any = {
      projectRequestId: projectRequestId > 0 ? projectRequestId : currentDraftRequestId > 0 ? currentDraftRequestId : 0,
      requestName: '',
      requestDescription: '',
      projectRequestStatusAutoId: projectRequestStatusList?.find((status) => status.statusName === 'published')?.statusAutoId,
      remindersAutoId: '',
      requestDetails: [],
      document: [],
      requestSendTo: [],
      tenantId: 0,
      projectId: 0,
    };
    mapProjectRequest.requestName = formData.requestDescription;
    mapProjectRequest.requestDescription = formData.requestMessage;
    mapProjectRequest.requestUpdateComments = formData.requestUpdateComments;
    mapProjectRequest.requestDetails = [];
    mapProjectRequest.requestSendTo = [];
    mapProjectRequest.projectId = projectDetails?.project.projectId;
    mapProjectRequest.tenantId = tenantId;
    mapProjectRequest.statusAutoId = defaultRequestStatusId;
    mapProjectRequest.remindersAutoId = formValues?.remindersAutoId !== null? formValues?.remindersAutoId : reminderListData?.[0].remindersAutoId;
    mapProjectRequest.reminderEndDate = formData.reminderEndDate;
    requestDetails.forEach((reqDetails: any) => {
      mapProjectRequest.requestDetails.push({
        label: reqDetails.label,
        documentTypeAutoId: reqDetails.documentTypeAutoId,
        projectRequestDetailsId: reqDetails.projectRequestDetailsId,
        projectRequestId: projectRequestId,
        projectId: mapProjectRequest.projectId,
        tenantId: tenantId,
        instructions: reqDetails.instructions,
        attachedFiles: reqDetails.attachedFiles,
      });
    });




    try {
      setLoading(true);
      if (projectRequestId !== 0) {
        requestDetails.forEach((reqDetails: any) => {
          selectedUsers?.forEach((user: any) => {
            // Find all matching recipients in the existing list
            const existingRecipient = editRequestDetails.projectRequestSentList.find(
              (sent: any) => sent.peopleId === Number(user.peopleId) && sent.projectRequestDetailsId === reqDetails.projectRequestDetailsId && sent.remindersAutoId !== null
            );

            if (existingRecipient) {
              // Process each matching recipient (preserve existing data)
              // existingRecipients.forEach((recipient: any) => {
              mapProjectRequest.requestSendTo.push({
                sendTo: user.email,
                sendCC: '',
                statusAutoId: existingRecipient.statusAutoId, // Retain the existing status
                label: reqDetails.label,
                instructions: reqDetails.instructions,
                peopleId: user.peopleId,
                uniqueLink: existingRecipient.uniqueLink,
                documentTypeAutoId: reqDetails.documentTypeAutoId,
                projectRequestSendToId: existingRecipient.projectRequestSentId, // Keep the existing ID
                projectRequestDetailsId: existingRecipient.projectRequestDetailsId,
                projectRequestId: projectRequestId,
                projectId: existingRecipient.projectId,
                tenantId: tenantId,
                batchId: existingRecipient.batchId,
                remindersAutoId: existingRecipient.remindersAutoId,

              });
            } else {
              // Handle new users (not found in existing recipients)
              mapProjectRequest.requestSendTo.push({
                sendTo: user.email,
                sendCC: '',
                statusAutoId: defaultRequestStatusId, // Assign default status for new users
                label: reqDetails.label,
                instructions: reqDetails.instructions,
                peopleId: user.peopleId,
                documentTypeAutoId: reqDetails.documentTypeAutoId,
                projectRequestSendToId: 0, // No ID for new users
                projectRequestDetailsId: reqDetails.projectRequestDetailsId,
                projectRequestId: projectRequestId,
                projectId: mapProjectRequest.projectId,
                tenantId: tenantId,
                remindersAutoId: mapProjectRequest.remindersAutoId,

              });
            }
          });

        });

        const data: any = await ProjectService.updateProjectRequestWithDetails<Request>(mapProjectRequest, tenantId, mapProjectRequest.projectId);
        if (data.code === '500') {
          ShowErrorNotification('Error while creating Request', data);
        } else {
          ShowSuccessNotification('Request updated successfully');
          setEditRequestFlag(true);
          onRequestCreated(mapProjectRequest);
        }
      }
      else {
        requestDetails.forEach((reqDetails: any) => {
          selectedUsers?.forEach((user: any) => {
            mapProjectRequest.requestSendTo.push({
              sendTo: user.email,
              sendCC: '',
              status: defaultRequestStatusId,
              label: reqDetails.label,
              instructions: reqDetails.instructions,
              peopleId: user.peopleId,
              documentTypeAutoId: reqDetails.documentTypeAutoId,
              remindersAutoId: formData.remindersAutoId,
            });
          });
        });
        const data: any = await ProjectService.postprojectRequestWithDetails<Request>(mapProjectRequest, tenantId, mapProjectRequest.projectId);
        if (data.code === '500') {
          ShowErrorNotification('Error while creating Request', data);
        } else {
          ShowSuccessNotification('Request created successfully');
          onRequestCreated(mapProjectRequest);
        }
      }
      handleDismiss();
    } catch (error) {
      ShowErrorNotification('Error while creating Request', error);
    } finally {
      setLoading(false);
    }
  };

  // Handle file selection
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const files = Array.from(event.target.files || []);
    setSelectedFiles((prevFiles) => {
      const updatedFiles = prevFiles.map((fileList, i) => (i === index ? [...fileList, ...files] : fileList));
      return updatedFiles; // Return the updated state
    });
  };

  // Handle file removal
  const handleFileRemove = (fileToRemove: File, index: number) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.map((fileList, i) => (i === index ? fileList.filter((file: File) => file !== fileToRemove) : fileList)),
    );
  };

  const removeFileSection = (sectionIndex: number) => {
    setSelectedFiles((prevSelectedFiles) => {
      // Create a copy of `selectedFiles`
      const updatedFiles = [...prevSelectedFiles];

      // Remove the entire section's files based on `sectionIndex`
      updatedFiles.splice(sectionIndex, 1);

      // Return the updated array without the section's files
      return updatedFiles;
    });
  };

  return (
    <Sheet show={isOpen} onDismiss={handleRequestCancelClick}>
      <Modal title={"Request : " + debouncedValues?.requestDescription} show={reqeusetCancelModal} onDismiss={handleRequestCancelCancelClick} size='small'>
        {editRequestDetails?.projectRequest?.projectRequestStatusAutoId === defaultDraftRequestStatusId ? (
          <><Text>Do you want to discard changes to the draft?</Text><Flex flexDirection='row' justifyContent='flex-end' rowGap={4}>
            <Button width='130px' variant='default' onClick={handleRequestCancelNoSave}>
              Discard draft
            </Button>
            <Button width='130px' variant='accent' color='primary' onClick={handleRequestCancelCancelClick}>
              Continue editing
            </Button>


          </Flex></>
        ):(
          <><Text>Are you sure you want to discard the changes of the request? All unsaved changes will be lost.</Text><Flex flexDirection='row' justifyContent='flex-end' rowGap={4}>
              <Button width='130px' variant='default' onClick={handleRequestCancelNoSave}>
                Discard request
              </Button>
              <Button width='130px' variant='accent' color='primary' onClick={handleRequestCancelCancelClick}>
                Continue editing
              </Button>


            </Flex></>
        )}
      </Modal>
      <Modal title={projectRequestId>0 ? 'Update request confirmation' : 'Send request confirmation'} show={sendConfirmModal} onDismiss={handleNoClick} size={'small'}>
        {projectRequestId>0 && (
          <Flex flexDirection='column' columnGap={16}>
            <Controller
              rules={{
                required: {
                  value: true,
                  message: 'Comments is required',
                },
                minLength: {
                  value: 1,
                  message: '',
                },
              }}
              name="requestUpdateComments"
              control={control}
              render={({ field, fieldState }) => (
                <FormField required>
                  <Label>Request update comments</Label>
                  <TextArea
                    {...field}
                    rows={3}
                    onChange={(e) => {
                      // Update the formData state directly on change
                      setFormData((prevState) => ({
                        ...prevState,
                        requestUpdateComments: e,
                      }));

                      // Also update the field value in the form
                      field.onChange(e);
                    }}
                    width={'100%'}
                    placeholder="Enter Request comments"
                    controlState={{
                      state: fieldState.error ? 'error' : 'valid',
                      hint: fieldState.error?.message,
                    }}

                  />
                </FormField>
              )}
            />
          </Flex>
        )}


        <Text>You are about to send this request to all recipients.</Text>
        <Flex flexDirection='row' justifyContent='flex-end' rowGap={4}>
          <Button width='80px' variant='default' onClick={handleNoClick}>
            Cancel
          </Button>
          <Button width='80px' variant='accent' color='primary' onClick={handleYesClick} disabled={projectRequestId>0 && (formData.requestUpdateComments === '' || formData.requestUpdateComments === null)}>
            Confirm
          </Button>
        </Flex>

      </Modal>
      <Modal show={templateModalOpen} title="Save as template" size="small" onDismiss={() => setTemplateModalOpen(false)}>
        <form onSubmit={handleSaveTemplate} noValidate>
          <Flex flexDirection="column" gap={16} width={512}>
            <Controller
              name="requestTemplateName"
              control={control}
              rules={{
                required: 'Template name is required',
                maxLength: {
                  value: 45,
                  message: 'Template name cannot have more than 45 characters.',
                },
              }}
              render={({ field, fieldState }) => (
                <FormField required>
                  <Label>Template name</Label>
                  <TextInput
                    {...field}
                    placeholder="Enter your template name"
                    controlState={{
                      state: fieldState.error ? 'error' : 'valid',
                      hint: fieldState.error?.message,
                    }}
                  />
                </FormField>
              )}
            />

            <Controller
              name="requestTemplateDescription"
              control={control}
              render={({ field, fieldState }) => (
                <FormField>
                  <Label>Template description</Label>
                  <TextInput
                    {...field}
                    placeholder="Enter your template description"
                    controlState={{
                      state: fieldState.error ? 'error' : 'valid',
                      hint: fieldState.error?.message,
                    }}
                  />
                </FormField>
              )}
            />

            <Flex justifyContent="flex-end" gap={8}>
              <Button variant="default" onClick={() => setTemplateModalOpen(false)} type="button">
                Cancel
              </Button>
              <Button variant="emphasized" type="submit">
                Save
              </Button>
            </Flex>
          </Flex>
        </form>
      </Modal>
      <LoadingStateComponent loading={loading || isTemplatesLoading || isRefetching} />
      <form onSubmit={handleSubmit(saveDetails)} onReset={() => reset()} noValidate>
        <TitleBar>
          <TitleBar.Title> {editRequestDetails ? 'Edit request' : 'Create new request'}</TitleBar.Title>
          <TitleBar.Action style={{ display: 'flex', alignItems: 'center' }}>

            <Flex justifyContent='flex-end' gap={8}>

              {autoSave ? (
                <Tooltip text="Auto saving">
                  <CycleIcon />
                </Tooltip>

              ) : (
                <Tooltip text="Successfully saved as draft ">
                  <SyncDoneIcon />
                </Tooltip>

              )}
              <Button
                disabled={currentTabIndex !== 0}
                width='200px'
                variant='emphasized'
                color='primary'
                onClick={handleTemplateDetailsOpen}
                type='button'
              >
                Save as template
              </Button>
              <VerticalLine />
              <Button width='130px' variant='emphasized' onClick={handleRequestCancelSave} disabled={disableSaveClose}>
                Save and close
              </Button>
              <VerticalLine />
              <Button width='80px' variant='default' onClick={handleRequestCancelClick}>
                Discard
              </Button>


              <Button width='80px' variant='emphasized' onClick={disablePrevTab} disabled={activeTab === 'Request'}>
                Previous
              </Button>
              {activeTab === 'Request' && (
                <Button width='80px' variant='emphasized' type='submit'>
                  Next
                </Button>
              )}
              {activeTab !== 'Request' && (
                <Button
                  width='80px'
                  variant='emphasized'
                  onClick={handleNextButtonClick}
                  disabled={activeTab === 'Request overview'}
                >
                  Next
                </Button>
              )}

              <Button
                width='80px'
                variant='accent'
                color='primary'
                onClick={() => handleSend()}
                disabled={tabOptions['Request overview'] === true}
              >
                Send
              </Button>
            </Flex>
          </TitleBar.Action>
        </TitleBar>
        <Flex flexDirection='row' gap={8} alignItems='end' justifyContent='flex-end'></Flex>
        <Tabs selectedIndex={currentTabIndex} onChange={handleTabChange}>
          {loading ? (
            <LoadingStateComponent loading={true} />
          ) : (
            <Tab
              title={'Request'}
              prefixIcon={
                Object.keys(createRequestErrors).length !== 0 || validationErrors['Request description'] ? (
                  <CriticalIcon style={{ color: Colors.Icon.Critical.Default }} />
                ) : (
                  <RequestIcon />
                )
              }
              disabled={tabOptions['Request']}
            >
              <RequestMessage
                control={control}
                handleTemplateChange={handleTemplateChange}
                requestTemplateData={requestTemplateData}
                fields={fields}
                selectedFiles={selectedFiles}
                handleFileRemove={handleFileRemove}
                handleAddMore={handleAddMore}
                handleAddDuplicate={handleAddDuplicate}
                handleFileChange={handleFileChange}
                fileInputRefs={fileInputRefs}
                remove={remove}
                removeFileSection={removeFileSection}
                editRequestDetails={editRequestDetails}
                updateProjectRequest={updateProjectRequest}
                editRequestFlag={editRequestFlag}
                setEditRequestFlag={setEditRequestFlag}
                disableReqeustMessage={disableReqeustMessage}
                reminderListData={reminderListData}
              />
            </Tab>
          )}

          <Tab
            title='Recipients'
            prefixIcon={
              validationErrors['recipients'] ? (
                <CriticalIcon style={{ color: Colors.Icon.Critical.Default }} />
              ) : (
                <MailIcon />
              )
            }
            disabled={tabOptions['Recipients']}
          >
            <RequestRecipients
              projectDetails={projectDetails}
              peopleList={peopleList}
              recipients={recipients}
              handleSubmit={handleSubmit}
              validationErrors={validationErrors}
              selectedRows={selectedRows}
              updateProjectPeopleRelations={updateProjectPeopleRelations}
              handleRecipientsChange={handleRecipientsChange}
              updateProjectRequest={updateProjectRequest}
            //selectedReceipents={distinctSentToEmails}
            />
          </Tab>

          <Tab title='Request overview' prefixIcon={<SourceIcon />} disabled={tabOptions['Request overview']}>
            <RequestSummary formValues={formValues} formData={formData} selectedFiles={selectedFiles} reminderList={reminderListData} />
          </Tab>
        </Tabs>
      </form>
      <FormFieldMessages />
    </Sheet>
  );
};

export default CreateRequest;
