import { v4 as uuidv4 } from 'uuid';
import { DataTableV2, Modal, SelectV2, Tooltip } from '@dynatrace/strato-components-preview';
import { Text, TextEllipsis } from '@dynatrace/strato-components/typography';
import { Button } from '@dynatrace/strato-components/buttons';
import { Flex } from '@dynatrace/strato-components/layouts';
import { useMemo, useState } from 'react';
import { PlusIcon } from '@dynatrace/strato-icons';
import { NewPersonModal } from '../../people/NewPersonModal';
import { useAppInfo } from '../../../contexts/AppContext';
import { usePeopleByTenantId } from '../../../hooks/use-minerva-data';
import { ShowErrorNotification, ShowSuccessNotification, ShowValidationBanner } from '../../../utils/Notifications';
import { PersonaService } from '../../../services/PersonaService';
import { ProjectPeopleRelation } from '../../../types/Project';
import { ModalParentType, PARENT_TYPE_BUSINESS, PARENT_TYPE_NONE, PARENT_TYPE_PROJECT } from '../../../types/Types';
import { ClientPersona } from '../../../types/Business';

interface NewProjectPeopleRelationModalProps {
  parentType: ModalParentType;
  parentId: number;
  currentProjectPeopleList: ProjectPeopleRelation[];
  currentBusinessPeopleList: ClientPersona[];
  onDismiss: (update?: boolean) => void;
}

export const NewProjectPeopleRelationModal = (props: NewProjectPeopleRelationModalProps) => {
  const { onDismiss, parentId, currentProjectPeopleList, currentBusinessPeopleList } = props;

  const isPrimaryContactExist = currentProjectPeopleList?.filter((item) => item.primaryContact === 1)?.length > 0 || currentBusinessPeopleList?.filter((item) => item.primaryContact === 1)?.length > 0
  // eslint-disable-next-line
  const [showPersonaErrorBanner, setShowPersonaErrorBanner] = useState<boolean>(false);
  const [showErrorBanner, setShowErrorBanner] = useState<boolean>(false);
  const [disableConfirm, setDisableConfirm] = useState(true);
  const [showModal, setShowModal] = useState(true);
  const [showNewPersonModal, setShowNewPersonModal] = useState<boolean>(false);
  const [selectedPeople, setSelectedPeople] = useState<any[]>([]);

  const { tenantId, tenantPersonaList, peoplePersonas } = useAppInfo();

  const { isLoading, data: tenantPeople, error, refetch } = usePeopleByTenantId(
    (tenantId && Number(tenantId) !== 0)
      ? Number(tenantId)
      : Number(localStorage.getItem("tenantId"))
  );
  if (error) {
    ShowErrorNotification('Error loading people information', error);
  }

  // Helper: Validate that every selected person has a project role and no more than one primary contact.
  const isValidSelection = (people: any[]) => {
    let isValid = true;
    people.forEach((person: any) => {
      if (person.isPersonSelected && person.personaAutoId === undefined) {
        isValid = false;
      }
    });
    const primaryCount = people.filter((person: any) => person.isPrimaryContact).length;
    if (primaryCount > 1) {
      isValid = false;
    }
    return isValid;
  };

  const updateSelection = (newSelection: any[]) => {
    setSelectedPeople(newSelection);
    setDisableConfirm(!isValidSelection(newSelection));
  };

  // When a project role is selected for a row.
  const onSelectPersona = (row: any, personaAutoId: number) => {
    const updated = selectedPeople.map((person: any) =>
      person.clientId === row.clientId ? { ...person, personaAutoId } : person
    );
    updateSelection(updated);
  };

  // Called when the primary contact dropdown changes.
  const onPrimaryChange = (row: any, value: string) => {
    const yesSelected = value === "true";
    const updated = selectedPeople.map((p: any) => {
      if (p.clientId === row.clientId) {
        return { ...p, isPrimaryContact: yesSelected };
      }
      // If setting a row to Yes, clear any other Yes selections.
      return yesSelected ? { ...p, isPrimaryContact: false } : p;
    });
    updateSelection(updated);
  };

  const projectPeopleColumns = useMemo(
    () => [
      {
        id: 'clientName',
        header: 'Name',
        accessor: 'clientName',
        minWidth: 200,
        autoWidth: true,
        cell: ({ rowData }: any) => (
          <DataTableV2.DefaultCell>
            <Tooltip placement='bottom' text={`${rowData.firstName}, ${rowData.lastName}`}>
              <TextEllipsis truncationMode='end'>
                {`${rowData.lastName}, ${rowData.firstName}`}
              </TextEllipsis>
            </Tooltip>
          </DataTableV2.DefaultCell>
        ),
      },
      {
        id: 'primaryContact',
        header: 'Primary Contact',
        accessor: 'primaryContact',
        width: 150,
        // Render a dropdown with Yes/No options.
        cell: ({ rowData }: any) => {
          const person = selectedPeople.find((p: any) => p.clientId === rowData.clientId);
          const isSelected = !!person;
          const isPrimary = person?.isPrimaryContact || false;
          return (
            <DataTableV2.DefaultCell>
              <Tooltip text={isPrimaryContactExist ? "Primary contact already exists" : "Selecy primary contact"}>
                <SelectV2
                  disabled={!isSelected || isPrimaryContactExist}
                  value={isPrimary ? "true" : "false"}
                  onChange={(value) => onPrimaryChange(rowData, value as string)}
                >
                  <SelectV2.Trigger placeholder="Select" />
                  <SelectV2.Content style={{ maxWidth: '300px' }} width='150px'>
                    <SelectV2.Option value="true">
                      <Text>Yes</Text>
                    </SelectV2.Option>
                    <SelectV2.Option value="false">
                      <Text>No</Text>
                    </SelectV2.Option>
                  </SelectV2.Content>
                </SelectV2>
              </Tooltip>
            </DataTableV2.DefaultCell>
          );
        },
      },
      {
        id: 'personaName',
        header: 'Project role',
        accessor: 'personaName',
        width: 200,
        cell: ({ rowData }: any) => {
          // Find the selected value (if any) for this row.
          const selectedItem = selectedPeople.find((item: any) => item.clientId === rowData.clientId);
          return (
            <DataTableV2.DefaultCell>
              <Flex
                onKeyDown={(event: { key: string; preventDefault: () => void }) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                  }
                }}
              >
                <Button style={{ padding: 0, margin: 0 }}>
                  <SelectV2
                    value={selectedItem?.personaAutoId ?? ""}
                    disabled={!selectedItem}
                    onChange={(value) => onSelectPersona(rowData, value as number)}
                  >
                    <SelectV2.Trigger placeholder={'Select project role'} />
                    <SelectV2.Content style={{ maxWidth: '300px' }} width='150px'>
                      {tenantPersonaList &&
                        tenantPersonaList.map((persona, index) => (
                          <SelectV2.Option key={index} value={persona.personaAutoId}>
                            <Text>{persona.personaName}</Text>
                          </SelectV2.Option>
                        ))}
                      {props.parentType === PARENT_TYPE_BUSINESS &&
                        peoplePersonas &&
                        peoplePersonas.map((persona, index) => (
                          <SelectV2.Option key={index} value={persona.personaId}>
                            <Text>{persona.personaName}</Text>
                          </SelectV2.Option>
                        ))}
                    </SelectV2.Content>
                  </SelectV2>
                </Button>
              </Flex>
            </DataTableV2.DefaultCell>
          );
        },
      },
      {
        id: 'email',
        header: 'Email',
        accessor: 'email',
      },
    ],
    // eslint-disable-next-line
    [tenantPersonaList, peoplePersonas, props.parentType, selectedPeople],
  );

  const handleCancel = () => {
    setShowModal(false);
    onDismiss(false);
  };

  const handleConfirm = () => {
    if (props.parentType === PARENT_TYPE_PROJECT) {
      saveProjectPeopleRelation();
    }
    if (props.parentType === PARENT_TYPE_BUSINESS) {
      saveBusinessPeopleRelation();
    }
    setShowModal(false);
    onDismiss(false);
  };

  const onRowSelected = (selectedRowsData: any) => {
    const selectedIndices = Object.keys(selectedRowsData)
      .filter((key) => selectedRowsData[key])
      .map((key) => parseInt(key, 10));

    const data = tenantPeople
      ? tenantPeople.filter(
        (person) =>
          props.parentType === PARENT_TYPE_PROJECT
            ? !props.currentProjectPeopleList.some((ppr) => ppr.peopleId === person.clientId)
            : !props.currentBusinessPeopleList.some((ppr) => ppr.clientId === person.clientId)
      )
      : [];

    const selectedRows = selectedIndices.map((index) => data[index]);

    // Retain only rows that are still selected.
    const updatedSelectedPeople = selectedPeople.filter((person: any) =>
      selectedRows.map((row) => row.clientId).includes(person.clientId)
    );

    // Add any newly selected rows with default properties.
    selectedRows.forEach((row) => {
      if (!updatedSelectedPeople.some((person: any) => person.clientId === row.clientId)) {
        updatedSelectedPeople.push({ ...row, isPersonSelected: true, isPrimaryContact: false });
      }
    });

    updateSelection(updatedSelectedPeople);

    if (selectedRows.length === 0) {
      setShowErrorBanner(false);
      setDisableConfirm(true);
    } else if (isValidSelection(updatedSelectedPeople)) {
      setShowErrorBanner(false);
      setDisableConfirm(false);
    } else {
      setShowErrorBanner(true);
      setDisableConfirm(true);
    }
  };

  const saveProjectPeopleRelation = async () => {
    try {
      const relationData = selectedPeople.map((person: any) => ({
        projectPersonId: 0,
        tenantId: tenantId,
        projectId: parentId,
        peopleId: person.clientId,
        personaAutoId: person.personaAutoId,
        modifiedOn: new Date().toISOString(),
        primaryContact: person.isPrimaryContact ? 1 : 0,
      }));
      await PersonaService.createProjectPeopleRelation(relationData, tenantId);
      ShowSuccessNotification('Project people relation created successfully');
      onDismiss(true);
    } catch (error) {
      ShowErrorNotification('Error creating project people relation', error);
      onDismiss();
    }
  };

  const saveBusinessPeopleRelation = async () => {
    try {
      const relationData = selectedPeople.map((person: any) => ({
        businessId: parentId,
        clientBusinessId: 0,
        clientId: person.clientId,
        personaId: person.personaAutoId,
        primaryContact: person.isPrimaryContact ? 1 : 0,
      }));
      await PersonaService.createClintRelation(relationData, tenantId);
      ShowSuccessNotification('Business people relation created successfully');
      onDismiss(true);
    } catch (error) {
      ShowErrorNotification('Error creating Business people relation', error);
      onDismiss();
    }
  };

  return (
    <Modal
      show={showModal}
      title={'Add people to this project'}
      size={'medium'}
      dismissible={false}
      footer={
        <Flex justifyContent='space-between' width='100%'>
          <Button
            onClick={() => {
              setShowNewPersonModal(true);
            }}
            variant='emphasized'
            color='neutral'
          >
            <Button.Prefix>
              <PlusIcon />
            </Button.Prefix>
            New person
          </Button>

          <Flex justifyContent='flex-end' gap={8}>
            <Button onClick={handleCancel} variant='default'>
              Discard
            </Button>
            <Button
              onClick={handleConfirm}
              type='submit'
              variant='accent'
              color='primary'
              disabled={disableConfirm || props.parentType === PARENT_TYPE_NONE}
            >
              Confirm
            </Button>
          </Flex>
        </Flex>
      }
    >
      {showErrorBanner && ShowValidationBanner('All selected rows should have a valid', 'Project role')}
      {showPersonaErrorBanner && ShowValidationBanner('Cannot have more than one primary contact', 'Role conflict')}
      <DataTableV2
        loading={isLoading}
        sortable
        columns={projectPeopleColumns}
        data={
          tenantPeople
            ? tenantPeople.filter(
              (person) =>
                props.parentType === PARENT_TYPE_PROJECT
                  ? !props.currentProjectPeopleList.some((ppr) => ppr.peopleId === person.clientId)
                  : !props.currentBusinessPeopleList.some((ppr) => ppr.clientId === person.clientId)
            )
            : []
        }
        defaultSortBy={[{ id: 'clientName', desc: false }]}
        selectableRows
        onRowSelectionChange={onRowSelected}
        variant={{ rowDensity: 'comfortable', rowSeparation: 'none', verticalDividers: false, contained: false }}
        resizable
        fullWidth
      >
        <DataTableV2.EmptyState>No persons available.</DataTableV2.EmptyState>
      </DataTableV2>
      {showNewPersonModal && (
        <NewPersonModal
          key={uuidv4()}
          onDismiss={(refresh) => {
            setShowNewPersonModal(false);
            if (refresh) {
              refetch();
            }
          }}
        />
      )}
    </Modal>
  );
};
