// PersonFeatureHighlight.tsx
import React, { useState, useRef } from 'react';
import { Flex } from '@dynatrace/strato-components/layouts';
import { FeatureHighlight } from '@dynatrace/strato-components-preview/overlays';
import { Strong, Text } from '@dynatrace/strato-components/typography';
import { MailIcon, CallIcon, AccountFilledIcon } from '@dynatrace/strato-icons';

interface Person {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  personaName: string;
  // ...other properties if needed
}

interface PersonFeatureHighlightProps {
  person: Person;
  children: React.ReactNode;
}

export const PersonFeatureHighlight = ({
  person,
  children,
}: PersonFeatureHighlightProps) => {
  const [hoverOpen, setHoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setAnchorEl(event.currentTarget);
    setHoverOpen(true);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setHoverOpen(false);
      setAnchorEl(null);
    }, 300);
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ position: 'relative', display: 'inline-block' }}
    >
      {children}
      {hoverOpen && anchorEl && (
        <FeatureHighlight
          open={hoverOpen}
          onClose={() => setHoverOpen(false)}
          anchor={anchorEl}
          placement="top-middle"
        >
          <FeatureHighlight.Title>
            <Strong>
              {person.firstName} {person.lastName}
            </Strong>
          </FeatureHighlight.Title>
          <FeatureHighlight.Content>
            <Flex flexDirection="column" gap={2}>
              <Flex
                alignItems="center"
                gap={2}
                style={{
                  cursor: 'pointer',
                  padding: '2px 4px',
                  borderRadius: '2px',
                }}
                onClick={() => copyToClipboard(person.email)}
              >
                <MailIcon />
                <Text>{person.email}</Text>
              </Flex>
              <Flex
                alignItems="center"
                gap={2}
                style={{
                  cursor: 'pointer',
                  padding: '2px 4px',
                  borderRadius: '2px',
                }}
                onClick={() => copyToClipboard(person.phoneNumber)}
              >
                <CallIcon />
                <Text>{person.phoneNumber}</Text>
              </Flex>
              <Flex
                alignItems="center"
                gap={2}
                style={{
                  cursor: 'pointer',
                  padding: '2px 4px',
                  borderRadius: '2px',
                }}
                onClick={() => copyToClipboard(person.personaName)}
              >
                <AccountFilledIcon />
                <Text>{person.personaName}</Text>
              </Flex>
            </Flex>
          </FeatureHighlight.Content>
        </FeatureHighlight>
      )}
    </div>
  );
};
