import {
  DataTableV2,
  DataTableV2ColumnSort,
  FilterBar,
  Page,
  TableColumn,
  TextInput,
  TitleBar,
  Tooltip,
} from '@dynatrace/strato-components-preview';
import { Button } from '@dynatrace/strato-components/buttons';
import { Flex } from '@dynatrace/strato-components/layouts';
import { TextEllipsis } from '@dynatrace/strato-components/typography';
import { useMemo, useState } from 'react';
import { PlusIcon } from '@dynatrace/strato-icons';
import { PersonSheet } from './PersonSheet';
import { ItemInfo } from '../../types/ListItemInfo';
import { configRoutes } from '../../configs/constants';
import { usePeopleByTenantId } from '../../hooks/use-minerva-data';
import { ShowErrorNotification } from '../../utils/Notifications';
import { useAppInfo } from '../../contexts/AppContext';
import { LoadingStateComponent } from '../../components/LoadingStateComponent';

export interface PeoplesProps { }

export const PeoplesPage = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [itemDetailsVisible, setItemDetailsVisible] = useState<ItemInfo | undefined>();
  const [sortBy, setSortBy] = useState([{ id: 'lastName', desc: false }]);
  const handleSortingChange = (sort: DataTableV2ColumnSort[]) => {
    setSortBy(sort);
  };

  const { isAppLoading, tenantId } = useAppInfo();

  const columns: TableColumn[] = useMemo(
    () => [
      {
        id: 'lastName',
        header: 'Name',
        accessor: 'firstName',
        width: 300,
        cell: ({ rowData }: any) => (
          <DataTableV2.DefaultCell>
            <Tooltip placement='bottom' text={`${rowData.firstName}, ${rowData.lastName}`}>
              {
                <TextEllipsis
                  truncationMode='end'
                >
                  {`${rowData.lastName}, ${rowData.firstName}`}
                </TextEllipsis>
              }
            </Tooltip>
          </DataTableV2.DefaultCell>
        ),
      },
      {
        id: 'phoneNumber',
        header: 'Phone',
        accessor: 'phoneNumber',
        minWidth: 350,
        autoWidth: true,
      },
      {
        id: 'email',
        header: 'Email',
        accessor: 'email',
        autoWidth: true,
        width: 600
      },
    ],
    [],
  );

  const { isLoading, data, error, refetch } = usePeopleByTenantId(tenantId);
  if (error && !isAppLoading) {
    ShowErrorNotification('Error loading projects', error);
  }

  if (itemDetailsVisible?.refreshParent) {
    itemDetailsVisible.refreshParent = false;
    refetch();
  }

  const filteredData = useMemo(() => {
    return searchQuery.trim().length !== 0
      ? data?.filter(
        (item: { userId: string; firstName: string; lastName: string; phoneNumber: string; email: string }) =>
          item.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.lastName.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.phoneNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.email.toLowerCase().includes(searchQuery.toLowerCase()),
      )
      : data;
  }, [searchQuery, data]);

  return (
    isAppLoading ? <></> :
      <>
        <TitleBar>
          <TitleBar.Prefix>
            <Page.PanelControlButton target='sidebar' />
          </TitleBar.Prefix>
          <TitleBar.Title>{configRoutes[2].friendlyName}</TitleBar.Title>
          <TitleBar.Subtitle>{configRoutes[2].desc} </TitleBar.Subtitle>
        </TitleBar>

        <Flex flexDirection='column' margin={8} padding={0} gap={0} style={{ height: `calc(100% - 85px)` }}>
          <Flex flexDirection='row' justifyContent='space-between' alignItems='end' marginBottom={16} marginTop={8}>
            <FilterBar onFilterChange={() => void 0}>
              <FilterBar.Item name={'search'} label={''}>
                <TextInput placeholder={'Search'} onChange={setSearchQuery} value={searchQuery} />
              </FilterBar.Item>
              <FilterBar.Item name={'selectedType'} label={''}>
                <Button></Button>
              </FilterBar.Item>
            </FilterBar>

            <Button
              onClick={() => {
                //setSelectedEndpoint(null);
                setItemDetailsVisible({ visibility: true });
              }}
              variant='accent'
              color='primary'
              style={{ margin: '0px 0px 0px auto' }}
            >
              <Button.Prefix>
                <PlusIcon />
              </Button.Prefix>
              Person
            </Button>
          </Flex>
          <Flex flexDirection={'column'} style={{ height: `calc(100% - 60px)` }} >
            <LoadingStateComponent loading={isLoading} />

            <DataTableV2
              columns={columns as any}
              data={filteredData ?? []}
              sortable
              variant={{ rowDensity: 'comfortable', rowSeparation: 'none', verticalDividers: false, contained: false }}
              sortBy={sortBy}
              onSortByChange={handleSortingChange}
              interactiveRows
              fullWidth
              onActiveRowChange={(row) => {
                if (row !== null) {
                  setItemDetailsVisible({
                    id: filteredData?.[+row].clientId,
                    index: +row,
                    name: `${filteredData?.[+row].lastName}, ${filteredData?.[+row].firstName}`,
                    visibility: true,
                    refreshParent: false
                  });
                }
              }}
            ></DataTableV2>
          </Flex>

          {itemDetailsVisible && itemDetailsVisible.visibility && (
            <PersonSheet
              closeDetails={(itemInfo?: ItemInfo) => {
                setItemDetailsVisible(itemInfo);
              }}
              itemInfo={itemDetailsVisible}
              show
            />
          )}
        </Flex>
      </>
  );
};
