import { DataTableV2, DataTableV2ColumnDef, DataTableV2ColumnSort } from '@dynatrace/strato-components-preview/tables';
import { FilterBar, Modal, Page, TextInput, TitleBar, Tooltip } from '@dynatrace/strato-components-preview';
import { Button } from '@dynatrace/strato-components/buttons';
import { TextEllipsis, Text } from '@dynatrace/strato-components/typography';
import { Flex } from '@dynatrace/strato-components/layouts';
import { useEffect, useMemo, useRef, useState } from 'react';
import { EditIcon, PlusIcon } from '@dynatrace/strato-icons';
import { ProjectSheet } from './ProjectSheet';
import { ItemInfo } from '../../types/ListItemInfo';
import { useNavigate } from 'react-router-dom';
import { Project } from '../../types/Project';
import { ShowErrorNotification, ShowSuccessNotification } from '../../utils/Notifications';
import { useAppInfo } from '../../contexts/AppContext';
import { formatDate } from '@dynatrace-sdk/units';
import { configRoutes } from '../../configs/constants';
import { useProjectsByTenantId } from '../../hooks/use-minerva-data';
import { LoadingStateComponent } from '../../components/LoadingStateComponent';
import { ProjectService } from '../../services/ProjectService';

export interface ProjectsProps {}

export const ProjectsPage = () => {
  const [itemDetailsVisible, setItemDetailsVisible] = useState<ItemInfo | undefined>();
  const [projectDetailsDelete, setProjectDetailsDelete] = useState<ItemInfo | undefined>();
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [sortBy, setSortBy] = useState([{ id: 'createdDateTime', desc: true }]);
  const handleSortingChange = (sort: DataTableV2ColumnSort[]) => {
    setSortBy(sort);
  };

  let { tenantProjectStatusList, isAppLoading, tenantId } = useAppInfo();

  // console.log(tenantProjectStatusList)
  const prevTenantId = useRef<number>(tenantId);
  const redirectUrl = localStorage.getItem('redirectAfterLogin');
  const navigate = useNavigate();

  const redirectPage = () => {
    if (redirectUrl) {
      // navigate(redirectUrl);
      localStorage.removeItem('redirectAfterLogin'); // Clean up
    } else {
      navigate('/projects'); // Default to home if no redirect is saved
    }
  };

  function showProjectDetails(itemInfo: ItemInfo) {
    navigate(`project/${encodeURIComponent(itemInfo.id ? itemInfo.id : '')}`);
  }

  const columns = useMemo<DataTableV2ColumnDef<Project[][number]>[]>(
    () => [
      {
        id: 'projectName',
        header: 'Project Name',
        accessor: 'projectName',
        width: 300,
        autoWidth: true,
        cell: ({ value, rowIndex, rowData }) => (
          <DataTableV2.DefaultCell>
            <Tooltip placement='bottom' text={rowData.projectName}>
              {<TextEllipsis>{rowData.projectName}</TextEllipsis>}
            </Tooltip>
          </DataTableV2.DefaultCell>
        ),
      },
      {
        id: 'amount',
        header: 'Amount',
        accessor: 'amount',
        autoWidth: true,
        columnType: 'number',
        cell: (cell: any) => (
          <DataTableV2.DefaultCell>{`$${new Intl.NumberFormat().format(cell.value)}`}</DataTableV2.DefaultCell>
        ),
      },
      {
        id: 'createdDateTime',
        header: 'Created on',
        accessor: 'createdDateTime',
        autoWidth: true,
        minWidth: 150,
        cell: (cell: any) => (
          <DataTableV2.DefaultCell>
            {formatDate(new Date(cell.value).getTime(), { dateStyle: 'medium' })}
          </DataTableV2.DefaultCell>
        ),
      },
      // {
      //   id: 'purpose',
      //   header: 'Use of funds',
      //   accessor: 'purpose',
      //   minWidth: 150,
      // },
      {
        id: 'requestneedingaction',
        header: 'Pending approval',
        accessor: 'pendingApprovalCount',
        autoWidth: true,
        minWidth: 150,
        cell: (cell: any) => <DataTableV2.DefaultCell>{cell.value > 0 ? 'Yes' : 'No'}</DataTableV2.DefaultCell>,
      },
      {
        id: 'assignedstaff',
        header: 'Assigned staff',
        accessor: 'assignedToName',
        autoWidth: true,
        minWidth: 150,
        cell: (cell: any) => <DataTableV2.DefaultCell>{cell.value}</DataTableV2.DefaultCell>,
      },
      {
        id: 'primarycontact',
        header: 'Primary contact',
        accessor: 'primaryContact',
        autoWidth: true,
        minWidth: 150,
      },
      {
        id: 'lendingphase',
        header: 'Lending phase',
        accessor: 'statusAutoId',
        autoWidth: true,
        minWidth: 150,
        cell: ({ value }) => (
          <DataTableV2.DefaultCell>
            {tenantProjectStatusList?.filter((item: any) => item.statusId === value)[0]?.statusName}
          </DataTableV2.DefaultCell>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const { isLoading, data, error, refetch } = useProjectsByTenantId(tenantId);
  if (error) {
    ShowErrorNotification('Error loading projects', error);
  }

  if (itemDetailsVisible?.refreshParent) {
    itemDetailsVisible.refreshParent = false;
    refetch();
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!isLoading && redirectUrl) {
      redirectPage();
    }
    // eslint-disable-next-line
  }, [redirectUrl, isLoading]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // we would like to get the data only once when the tenantId changes from 0 to actual tenantId
    if (prevTenantId.current === 0) {
      prevTenantId.current = tenantId;
      refetch();
    }
  }, [tenantId, refetch]);

  const filteredData = useMemo(() => {
    return searchQuery.trim().length !== 0
      ? data?.filter(
          (item: { purpose: string; projectName: string }) =>
            item.purpose.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.projectName.toLowerCase().includes(searchQuery.toLowerCase()),
        )
      : data;
  }, [searchQuery, data]);
  const DeleteProjectSubmit = async (projectDetailsDelete: ItemInfo) => {
    try {
      setLoading(true);
      if (projectDetailsDelete.id !== undefined) {
        await ProjectService.deleteProjectById(projectDetailsDelete?.id, tenantId);
        ShowSuccessNotification(`${projectDetailsDelete.name} deleted successfully`);
        refetch();
      }
    } catch (err) {
      ShowErrorNotification(`Error occurred while deleting: ${projectDetailsDelete.name}`);
    } finally {
      setLoading(false);
    }
  };
  const handleYesClick = () => {
    if (projectDetailsDelete) {
      DeleteProjectSubmit(projectDetailsDelete);
    }

    setProjectDetailsDelete({});
  };

  const handleNoClick = () => {
    setProjectDetailsDelete({});
  };

  return isAppLoading ? (
    <>
      {' '}
      <LoadingStateComponent loading={isAppLoading} />
    </>
  ) : (
    <>
      <TitleBar>
        <TitleBar.Prefix>
          <Page.PanelControlButton target='sidebar' />
        </TitleBar.Prefix>
        <TitleBar.Title>{configRoutes[0].friendlyName}</TitleBar.Title>
        <TitleBar.Subtitle>{configRoutes[0].desc} </TitleBar.Subtitle>
      </TitleBar>
      <LoadingStateComponent loading={loading} />
      <Flex flexDirection='column' padding={0} gap={0} marginTop={8} style={{ height: `calc(100% - 85px)` }}>
        <Flex flexDirection='row' marginBottom={8} justifyContent='space-between' alignItems='end'>
          <FilterBar onFilterChange={() => {}}>
            <FilterBar.Item name={'search'} label={''}>
              <TextInput placeholder={'Search'} onChange={setSearchQuery} value={searchQuery} />
            </FilterBar.Item>
            <FilterBar.Item name={'selectedType'} label={''}>
              <Button></Button>
            </FilterBar.Item>
          </FilterBar>

          <Button
            onClick={() => {
              setItemDetailsVisible({ visibility: true });
            }}
            variant='accent'
            color='primary'
            style={{ margin: '0px 0px 0px auto' }}
          >
            <Button.Prefix>
              <PlusIcon />
            </Button.Prefix>
            Project
          </Button>
        </Flex>
        <Flex flexDirection='column' padding={0} gap={8} style={{ height: `calc(100% - 60px)` }}>
          <LoadingStateComponent loading={isLoading || tenantId === 0} />
          <DataTableV2
            columns={columns}
            data={filteredData ?? []}
            sortable
            loading={isLoading}
            variant={{ rowDensity: 'comfortable', rowSeparation: 'none', verticalDividers: false, contained: false }}
            sortBy={sortBy}
            onSortByChange={handleSortingChange}
            resizable
            interactiveRows
            fullWidth
            onActiveRowChange={(row) => {
              if (row !== null) {
                showProjectDetails({
                  id: filteredData?.[+row].projectId,
                  index: +row,
                  name: filteredData?.[+row].projectName,
                  visibility: true,
                });
              }
            }}
          >
            <DataTableV2.RowActions>
              {(row, { rowDensity }) => (
                <>
                  <Button
                    variant={'default'}
                    size={'condensed'}
                    color={'primary'}
                    onClick={() => {
                      const project: Project = row as Project;
                      setItemDetailsVisible({
                        id: project.projectId,
                        index: 0,
                        name: project.projectName,
                        visibility: true,
                      });
                    }}
                  >
                    <Button.Prefix>
                      <EditIcon />
                    </Button.Prefix>
                  </Button>
                </>
              )}
            </DataTableV2.RowActions>
          </DataTableV2>
          {itemDetailsVisible && itemDetailsVisible.visibility && (
            <ProjectSheet
              closeDetails={(itemInfo?: ItemInfo) => {
                //reload the page as needed
                setItemDetailsVisible(itemInfo);
              }}
              show
              itemInfo={itemDetailsVisible}
              refetch={refetch}
            />
          )}

          <Modal
            title={`Delete project '${projectDetailsDelete?.name}'`}
            show={projectDetailsDelete?.visibility}
            onDismiss={handleNoClick}
            size={'small'}
          >
            <Text>Are you sure you want to delete this project?</Text>
            <br />
            <br />
            <Text style={{ color: 'red' }}>Warning!!</Text>
            <br />
            <Text>All assets including files belonging to this project will be deleted. </Text>
            <br />
            <Text> Once deleted, it cannot be recovered.</Text>
            <br />
            <br />
            <Flex flexDirection='row' justifyContent='flex-end' rowGap={4}>
              <Button width='80px' variant='default' onClick={handleNoClick}>
                Cancel
              </Button>
              <Button width='80px' variant='accent' color='primary' onClick={handleYesClick}>
                Confirm
              </Button>
            </Flex>
          </Modal>
        </Flex>
      </Flex>
    </>
  );
};
