import { v4 as uuidv4 } from 'uuid';
import {
  DataTableV2,
  DataTableV2ColumnSort,
  TableRowActions,
} from '@dynatrace/strato-components-preview/tables';
import {
  DateTimePicker,
  FormField,
  SelectV2,
  TextInput,
} from '@dynatrace/strato-components-preview/forms';
import {
  Sheet,
  Tooltip,
} from '@dynatrace/strato-components-preview/overlays';
import {
  Label,
  Tab,
  Tabs,
  TitleBar,
} from '@dynatrace/strato-components-preview';
import { Button } from '@dynatrace/strato-components/buttons';
import { Flex } from '@dynatrace/strato-components/layouts';
import { TextEllipsis } from '@dynatrace/strato-components/typography';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ItemInfo } from '../../types/ListItemInfo';
import { LoadingStateComponent } from '../../components/LoadingStateComponent';
import { BusinessService } from '../../services/BusinessService';
import { BusinessDetail, ClientPersona } from '../../types/Business';
import { ShowErrorNotification, ShowSuccessNotification } from '../../utils/Notifications';
import { useAppInfo } from '../../contexts/AppContext';
import { DeleteIcon, DescriptionIcon, GroupIcon, PlusIcon } from '@dynatrace/strato-icons';
import { groupHeaderFontStyle } from '../GeneralStyles.css';
import { NewProjectPeopleRelationModal } from '../projects/project-relations/NewProjectPeopleRelationModal';
import { PARENT_TYPE_BUSINESS } from '../../types/Types';
import { PeopleService } from '../../services/PeopleService';

export interface BusinessSheetProps {
  closeDetails: (itemInfo?: ItemInfo) => void;
  show: boolean;
  itemInfo?: ItemInfo;
}

export const BusinessSheet = (props: BusinessSheetProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingPeopleRelations, setLoadingPeopleRelations] = useState<boolean>(false);

  const workingOnCall = useRef<boolean>(false);

  const { closeDetails, show, itemInfo } = props;
  const [state, setState] = useState(show);
  const businessDetails = useRef<BusinessDetail | undefined>(undefined);

  const [peopleList, setPeopleList] = useState<ClientPersona[] | []>([]);

  const [showExistingPersonModal, setShowExistingPersonModal] = useState<boolean>(false);

  const { tenantId, userId, tenantBusinessCategories, tenantBusinessIndustries } = useAppInfo();
  const doNotSubmit = false;
  const [sortBy, setSortBy] = useState([{ id: 'clientName', desc: false }]);
  const handleSortingChange = (sort: DataTableV2ColumnSort[]) => {
    setSortBy(sort);
  };


  const {
    handleSubmit,
    control,
    reset,
    trigger,
    formState: { isSubmitSuccessful },
  } = useForm<{
    businessId: number;
    tenantId: number;
    userId: string;
    businessName: string;
    businessType: string;
    businessCategoryId: number;
    businessRegistrationNumber: string;
    businessAddress: string;
    industry: string;
    annualRevenue: number;
    incorporationDate: string | null;
    documentFolder: string;
    companyWebsite: string;
    companyPhoneNumber: string;
    businessIndustryId: number;
  }>({
    mode: undefined,
  });

  // discarded or closed the sheet from escape key
  const dismissDetails = () => {
    closeDetails({ visibility: false });
  };

  useEffect(() => {
    // load other data from API
    if (workingOnCall.current === false) {
      workingOnCall.current = true;
      getRequiredData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemInfo?.id, show]);

  useEffect(() => {
    if (itemInfo?.id) {
      trigger();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const businessPeopleColumns = useMemo(
    () => [
      {
        id: 'clientName',
        header: 'Name',
        accessor: 'clientName',
        width: { type:'auto', minWidth: 300},
        cell: ({rowData}: any) => (
          <DataTableV2.DefaultCell>
            <Tooltip placement='bottom' text={`${rowData.firstName}, ${rowData.lastName}`}>
              {
                <TextEllipsis truncationMode='end'>
                  {`${rowData.lastName}, ${rowData.firstName}`}
                </TextEllipsis>
              }
            </Tooltip>
          </DataTableV2.DefaultCell>
        ),
      },
      {
        id: 'primaryContact',
        header: 'Primary contact',
        accessor: (row: any) => row.primaryContact === 1 ? 'Yes' : 'No' ,
        width: { type:'auto', minWidth: 200}
      },
      {
        id: 'personaName',
        header: 'Project role',
        accessor: 'personaName',
        width: { type:'auto', minWidth: 200},
        cell: ({rowData}: any) => <DataTableV2.DefaultCell>{rowData.personas.personaName}</DataTableV2.DefaultCell>,
      },
      {
        id: 'email',
        header: 'Email',
        accessor: 'email',
        width: { type:'auto', minWidth: 200},
      },
    ],
    [],
  );

  // get all the other required data for the edit
  // and creating new for loading dropdown data
  const getRequiredData = async () => {
    setLoading(true);
    try {
      // if not its a new project
      if (itemInfo && itemInfo.id) {
        businessDetails.current = await BusinessService.getClinetDetailsById<BusinessDetail>(itemInfo?.id, tenantId);
      }
    } catch (error) {
      ShowErrorNotification('Error fetching business data', error);
      setLoading(false);
    } finally {
      if (businessDetails.current) {
        const peopleList: ClientPersona[] = businessDetails.current.clientPersonas;
        setPeopleList(peopleList);

        reset({
          businessId: businessDetails.current.business.businessId,
          tenantId: tenantId,
          userId: userId,
          businessName: businessDetails.current.business.businessName,
          businessType: businessDetails.current.business.businessType,
          businessCategoryId: businessDetails.current.business.businessCategoryId,
          businessRegistrationNumber: businessDetails.current.business.businessRegistrationNumber,
          businessAddress: businessDetails.current.business.businessAddress,
          industry: businessDetails.current.business.industry,
          annualRevenue: businessDetails.current.business.annualRevenue,
          incorporationDate: businessDetails.current.business.incorporationDate
            ? new Date(businessDetails.current.business.incorporationDate).toISOString()
            : null,
          documentFolder: businessDetails.current.business.rootDocumentFolder,
          companyPhoneNumber: businessDetails.current.business.companyPhoneNumber,
          companyWebsite: businessDetails.current.business.companyWebsite,
          businessIndustryId: businessDetails.current.business.businessIndustryId
        });
      } else {
        reset({
          incorporationDate: null,
        });
      }
      setLoading(false);
    }
  };

  // save the information
  const saveDetails = async (values: any) => {
    //console.log('Saving project details', values);
    if (itemInfo?.id) {
      try {
        await BusinessService.updateBusinessById(values, tenantId);
        ShowSuccessNotification('Business updated successfully');
      } catch (error) {
        ShowErrorNotification('Error updating business information', error);
        closeDetails();
        return;
      }
    } else {
      try {
        values.tenantId = tenantId;
        values.userId = userId;
        await BusinessService.createBusiness(values, tenantId);
        ShowSuccessNotification('Business created successfully');
      } catch (error: any) {
        const errorResponse = error?.response?.data;
        ShowErrorNotification('Error creating business', errorResponse);
        closeDetails();
        return;
      }
    }

    // save the information and then close
    closeDetails({ refreshParent: true });
  };

  // updates people business relations post creating a new one or deleting
  const updatePeopleBusinessRelations = async () => {
    try {
      const data: any = await BusinessService.getClinetDetailsById<BusinessDetail>(itemInfo?.id ?? 0, tenantId);
      setPeopleList(data.clientPersonas);
    } catch (error) {
      ShowErrorNotification('Error updating project business relation', error);
    }
    setLoadingPeopleRelations(false);
  };

  const title = itemInfo?.name ? itemInfo.name : 'New Business';
  const subTitle = itemInfo?.name ? 'Updating business information' : 'Creating new business';
  return (
    <Sheet show={state} onDismiss={dismissDetails}>
      <form onSubmit={handleSubmit(saveDetails)} onReset={() => reset()} noValidate>
        <Flex flexDirection='column' margin={8} gap={8}>
          <TitleBar>
            <TitleBar.Title> {title}</TitleBar.Title>
            <TitleBar.Subtitle> {subTitle}</TitleBar.Subtitle>
            <TitleBar.Action style={{ display: 'flex', alignItems: 'center' }}>
              <Flex flexDirection='row' gap={8}>
                <Button
                  width='75px'
                  onClick={() => {
                    setState(false);
                    dismissDetails();
                  }}
                  variant='default'
                >
                  Discard
                </Button>
                <Button
                  width='60px'
                  type={doNotSubmit ? 'button' : 'submit'}
                  variant='accent'
                  color='primary'
                  disabled={isSubmitSuccessful}
                >
                  Save
                </Button>
              </Flex>
            </TitleBar.Action>
          </TitleBar>
          <LoadingStateComponent loading={loading} />

          {!loading && businessDetails && (
            <Tabs defaultIndex={0}>
              <Tab title={'Business details'} prefixIcon={<DescriptionIcon />}>
                <Flex flexDirection='column' width={512} minWidth={512} gap={16}>
                  <Controller
                    name='businessName'
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Business name is required.',
                      },
                      maxLength: {
                        value: 200,
                        message: 'Please enter a valid business name.',
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <FormField required>
                        <Label>Business name</Label>
                        <TextInput
                          placeholder='Enter business name'
                          controlState={{
                            state: error ? 'error' : 'valid',
                            hint: error?.message,
                          }}
                          {...field}
                        />
                      </FormField>
                    )}
                  />
                  <Controller
                    name="businessCategoryId"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <FormField>
                        <Label>Business category</Label>
                        <SelectV2
                          name="business-category-selectV2"
                          value={field.value}
                          onChange={(selectedValue) => {
                            field.onChange(selectedValue);
                          }}
                          controlState={{
                            state: error ? 'error' : 'valid',
                            hint: error?.message,
                          }}
                        >
                          <SelectV2.Trigger placeholder="Select a business category" />

                          <SelectV2.Content style={{ width: 'max-content', minWidth: '200px' }}>
                            {tenantBusinessCategories && tenantBusinessCategories.length > 0 ? (
                              tenantBusinessCategories.map((type) => (
                                <SelectV2.Option key={type.businessCategoryAutoId} value={type.businessCategoryAutoId}>
                                  {type.businessCategory}
                                </SelectV2.Option>
                              ))
                            ) : (
                              <SelectV2.Option value="" disabled>
                                No business categories available
                              </SelectV2.Option>
                            )}
                          </SelectV2.Content>
                        </SelectV2>
                      </FormField>
                    )}
                  />
                  {/* <Controller
                    name='annualRevenue'
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Please enter a loan value.',
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <FormField required>
                        <Label>Annual Revenue</Label>
                        <NumberInput
                          aria-label='Annual-revenue'
                          placeholder='eg. 2000000'
                          controlState={{
                            state: error ? 'error' : 'valid',
                            hint: error?.message || 'Desired loan amount',
                          }}
                          {...field}
                        />
                      </FormField>
                    )}
                  />  */}
                  <Controller
                    name='businessRegistrationNumber'
                    control={control}
                    rules={{
                      maxLength: {
                        value: 200,
                        message: "Please enter a valid TaxID/EIN.",
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <FormField>
                        <Label>TaxID/EIN</Label>
                        <TextInput
                          placeholder="Enter TaxID/EIN"
                          controlState={{
                            state: error ? "error" : "valid",
                            hint: error?.message,
                          }}
                          {...field}
                        />
                      </FormField>
                    )}
                  />

                  {/* Industry/Sector Dropdown */}
                  <Controller
                    name="businessIndustryId"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <FormField required>
                        <Label>Industry/Sector</Label>
                        <SelectV2
                          name="industry-selectV2"
                          value={field.value}
                          onChange={(selectedValue) => {
                            field.onChange(selectedValue);
                          }}
                          controlState={{
                            state: error ? "error" : "valid",
                            hint: error?.message,
                          }}
                        >
                          <SelectV2.Trigger placeholder="Select Industry/Sector" />
                          <SelectV2.Content style={{ width: "max-content", minWidth: "200px" }}>
                            {tenantBusinessIndustries && tenantBusinessIndustries.length > 0 ? (
                              tenantBusinessIndustries.map((type) => (
                                <SelectV2.Option key={type.businessIndustryAutoId} value={type.businessIndustryAutoId}>
                                  {type.businessIndustryName}
                                </SelectV2.Option>
                              ))
                            ) : (
                              <SelectV2.Option value="" disabled>
                                No business categories available
                              </SelectV2.Option>
                            )}
                          </SelectV2.Content>
                        </SelectV2>
                      </FormField>
                    )}
                  />

                  {/* Company Website */}
                  <Controller
                    name="companyWebsite"
                    control={control}
                    rules={{
                      pattern: {
                        value: /^https?:\/\/[^\s$.?#].[^\s]*$/,
                        message: "Please enter a valid URL.",
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <FormField>
                        <Label>Company website</Label>
                        <TextInput
                          placeholder="https://example.com"
                          controlState={{
                            state: error ? "error" : "valid",
                            hint: error?.message,
                          }}
                          {...field}
                        />
                      </FormField>
                    )}
                  />

                  {/* Company Phone Number */}
                  <Controller
                    name="companyPhoneNumber"
                    control={control}
                    rules={{
                      pattern: {
                        value: /^\+?[1-9]\d{1,14}$/,
                        message: "Please enter a valid phone number.",
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <FormField>
                        <Label>Company phone number</Label>
                        <TextInput
                          placeholder="Enter phone number"
                          controlState={{
                            state: error ? "error" : "valid",
                            hint: error?.message,
                          }}
                          {...field}
                        />
                      </FormField>
                    )}
                  />
                  <Controller
                    name='incorporationDate'
                    control={control}
                    rules={{
                      required: {
                        value: false,
                        message: 'Please choose an option.',
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <FormField>
                        <Label>Incorporation date</Label>
                        <DateTimePicker
                          type='date'
                          controlState={{
                            state: error ? 'error' : 'valid',
                            hint: error?.message,
                          }}
                          style={{ width: 'max-content' }}
                          {...field}
                        />
                      </FormField>
                    )}
                  />
                  <Controller
                    name='businessAddress'
                    control={control}
                    rules={{
                      maxLength: {
                        value: 200,
                        message: 'Please enter business location',
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <FormField>
                        <Label>Business address</Label>
                        <TextInput
                          placeholder='Enter business address'
                          controlState={{
                            state: error ? 'error' : 'valid',
                            hint: error?.message,
                          }}
                          {...field}
                        />
                      </FormField>
                    )}
                  />
                </Flex>
              </Tab>
              <Tab title={'Associated people'} prefixIcon={<GroupIcon />} disabled={itemInfo?.id === undefined}>
                <Flex flexDirection='column' width={'50%'} minWidth={325} gap={8}>
                  <Flex
                    flexDirection='row'
                    justifyContent='space-between'
                    alignItems='end'
                    //marginBottom={4}
                    marginTop={8}
                  >
                    <Label className={groupHeaderFontStyle}>People associated with this project</Label>
                    <Flex>
                      <Button
                        onClick={() => {
                          setShowExistingPersonModal(true);
                        }}
                        variant='accent'
                        color='primary'
                        style={{ margin: '0px 0px 0px auto' }}
                      >
                        <Button.Prefix>
                          <PlusIcon />
                        </Button.Prefix>
                        Add person
                      </Button>
                      {showExistingPersonModal && (
                        <NewProjectPeopleRelationModal
                          key={uuidv4()}
                          parentType={PARENT_TYPE_BUSINESS}
                          parentId={itemInfo?.id ?? 0}
                          currentProjectPeopleList={[]}
                          currentBusinessPeopleList={peopleList ?? []}
                          onDismiss={(update) => {
                            setShowExistingPersonModal(false);
                            if (update) {
                              setLoadingPeopleRelations(true);
                              updatePeopleBusinessRelations();
                            }
                          }}
                        />
                      )}
                    </Flex>
                  </Flex>
                  <DataTableV2
                    loading={loadingPeopleRelations}
                    sortable
                    columns={businessPeopleColumns as any}
                    data={peopleList ?? []}
                    sortBy={sortBy}
                    onSortByChange={handleSortingChange}
                    variant={{ rowDensity: 'comfortable', rowSeparation: 'none', verticalDividers: false, contained: false }}
                  >
                    <DataTableV2.EmptyState>No one is associated with this project.</DataTableV2.EmptyState>
                    <DataTableV2.RowActions>
                      {(row: any) => (
                        <TableRowActions.Group>
                          <TableRowActions.Item
                            onClick={async () => {
                              // deleting the buisness from the project
                              try {
                                await PeopleService.deleteBusinessDetailsById(
                                  row.clientBusinessId,
                                  tenantId
                                );
                                setLoadingPeopleRelations(true);
                                updatePeopleBusinessRelations();
                              } catch (error) {
                                ShowErrorNotification('Error deleting people association with the business', error);
                              }
                            }}
                            prefixIcon={<DeleteIcon />}
                          ></TableRowActions.Item>
                        </TableRowActions.Group>
                      )}
                    </DataTableV2.RowActions>
                  </DataTableV2>
                </Flex>
              </Tab>
            </Tabs>
          )}
        </Flex>
      </form>
    </Sheet>
  );
};
