import { Modal, Tooltip } from '@dynatrace/strato-components-preview/overlays';
import { DataTableV2, DataTableV2ColumnSort, TableRowActions } from '@dynatrace/strato-components-preview/tables';
import { Button } from '@dynatrace/strato-components/buttons';
import { Flex } from '@dynatrace/strato-components/layouts';
import { FeedbackIcon, PlusIcon, EditIcon } from '@dynatrace/strato-icons';
import { columns } from './table-definitions';
import { Dispatch, useEffect, Key, useRef, useState } from 'react';
import { RequestDetails } from '../../../../types/Request';
import { ProjectService } from '../../../../services/ProjectService';
import { GroupedDocuments } from '../project-details-utils';
import { StatusDetails } from '../../../../types/Status';
import '../../../../App.css'
import { useAppInfo } from '../../../../contexts/AppContext';
import { Strong } from '@dynatrace/strato-components';
import CreateRequest from '../../request/CreateRequest';
import { ShowErrorNotification, ShowSuccessNotification } from '../../../../utils/Notifications';
import { Breadcrumbs, FilterBar, TextInput } from '@dynatrace/strato-components-preview';
import RequestsUserFiles from './RequestsUserFiles';
import RequestsUsers from './RequestsUsers';
import { useNavigate, useParams } from 'react-router-dom';
import { SelectV2, SelectV2SingleValue } from '@dynatrace/strato-components-preview';
import { UploadService } from '../../../../services/UploadService';
export interface RequestsProps {
  projectRequestsApiData: any;
  requestStatusData: StatusDetails[];
  handleSelectChange: ((updatedStatus: string, statusOptions: any, cell: any) => void);
  projectRequests: RequestDetails[];
  setProjectRequestsApiData: Dispatch<React.SetStateAction<GroupedDocuments[]>>;
  refetchProjectRequests: () => void;
  setLoading: Dispatch<React.SetStateAction<boolean>>;
  projectDetails: any;
  onRequestUpdated: any;
  peopleList: any;
  CreateRequestClick: any;
  projectRequestsDrafts: any;
  refetchProjectRequestDrafts: any;

}

export const Requests = (props: RequestsProps) => {
  const { ProjectId, RequestId, PeopleId } = useParams();
  const {
    projectDetails,
    onRequestUpdated,
    projectRequestsApiData,
    requestStatusData,
    projectRequests,
    handleSelectChange,
    setProjectRequestsApiData,
    refetchProjectRequests,
    peopleList,
    setLoading,
    CreateRequestClick,
    projectRequestsDrafts,
    refetchProjectRequestDrafts
  } = props;
  const navigate = useNavigate();
  const [selectedComment, setSelectedComment] = useState<string | null>(null);
  const [requestCommentModalOpen, setRequestCommentModalOpen] = useState(false);
  const [selectedReminder, setSelectedreminder] = useState<any>(null);
  const [requestReminderModalOpen, setRequestReminderModalOpen] = useState(false);
  const [createRequestSheet, setCreateRequestSheet] = useState(false);
  const [editProjectRequest, setEditProjectRequest] = useState<any>({});
  const [navigationPath, setNavigationPath] = useState<any>({ level: 'requests', routes: [], requestId: 0 });
  const [selectedData, setSelectedData] = useState<any>([]);
  const [selectedUsersData, setSelectedUsersData] = useState<any>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [sortBy, setSortBy] = useState([{ id: 'createdOn', desc: true }]);
  const handleSortingChange = (sort: DataTableV2ColumnSort[]) => {
    setSortBy(sort);
  };

  const filterData = (data: any[], query: string) => {
    if (!query) return data;
    return data.filter((item) => {
      return Object.values(item).some((value) =>
        typeof value === "string" && value.toLowerCase().includes(query.toLowerCase())
      );
    });
  };

  function showProjectDetails(requestData: any) {
    navigate(`/projects/project/${ProjectId}/request/${requestData.id}`);
  }

  // Filtered Data for Rendering

  const filteredProjectRequests = [

    ...filterData(projectRequestsApiData, searchQuery)

  ];

  const { tenantId, tenantRequestReminders } = useAppInfo();

  const fileInputRefs = useRef<{ [key: number]: (HTMLInputElement | null)[] }>({});

  const downloadFileClick = function (rowInfo: any) {
    //In Local, uncomment the below line
    // window.open(ProjectService.getDownloadFileHeader(rowInfo.original.downloadFilePath));
    //In Dev, uncomment the below Line
    const downloadFilePath = rowInfo.downloadFilePath.split('uploads/')[1];
    window.open(ProjectService.getDownloadFileHeader(downloadFilePath, tenantId));
  };

  const handleUploadClick = (requestId: number, projectRequestSentId: number, documentIndex: number) => {
    if (fileInputRefs.current[requestId] && fileInputRefs.current[requestId][documentIndex]) {
      fileInputRefs.current[requestId][documentIndex]!.click();
    }
  };

  const handleReminderChange = async (value: SelectV2SingleValue<number>) => {
    try {
      setLoading(true);
      if (selectedReminder) {
        let mapProjectRequest: any = {};
        mapProjectRequest.peopleId = selectedReminder.peopleId;
        mapProjectRequest.remindersAutoId = value;
        mapProjectRequest.projectRequestId = selectedReminder.projectRequestId;
        mapProjectRequest.projectId = selectedReminder.projectId;
        mapProjectRequest.tenantId = selectedReminder.tenantId;
        setRequestReminderModalOpen(false);
        setSelectedreminder(null);
        const result: any = await UploadService.updateRecipientEmailReminderInterval(mapProjectRequest);
        if (result.code === 500) {
          ShowErrorNotification("Failed to update the reminder interval", result.message);


        }
        else {
          ShowSuccessNotification("Reminder interval updated successfully");
          refetchProjectRequests();
        }
      }
    }
    catch (e) {
      ShowErrorNotification("Failed to update the reminder interval", e);
    }
    finally {
      setLoading(false);
    }
  }

  const openRequestReminderModal = async (request: any, rowId: number) => {
    setSelectedreminder(request)
    setRequestReminderModalOpen(true);
  };

  const closeRequestReminderModal = () => {
    setRequestReminderModalOpen(false);
    setSelectedreminder(null);
  };

  const openRequestCommentModal = (comment: string | null) => {
    setSelectedComment(comment);
    setRequestCommentModalOpen(true);
  };

  const closeRequestCommentModal = () => {
    setRequestCommentModalOpen(false);
    setSelectedComment(null);
  };

  const handleRequestEditChange = async (requestRow: any) => {
    try {
      setLoading(true);
      const data: any = await ProjectService.getProjectRequestByRequestId(requestRow.projectRequestId, requestRow.projectId, tenantId);
      if (data) {

      }
      setEditProjectRequest(data);
      setCreateRequestSheet(true);
    }
    catch (err) {
      ShowErrorNotification('Failed to load Request ' + requestRow.projectRequestName, err);
    }
    finally {
      setLoading(false);
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (RequestId && PeopleId && projectRequestsApiData.length > 0) {
      handleNavigationPath({}, 'user-data')
    } else if (RequestId && PeopleId === undefined && projectRequestsApiData.length > 0) {
      handleNavigationPath({}, 'users')
    } else {

    }
    // eslint-disable-next-line
  }, [RequestId, PeopleId, projectRequestsApiData])

  const handleOpenRequest = async (requestRow: any) => {
    await navigate(`request/${requestRow.projectRequestId}`);
    if (RequestId) {
      handleNavigationPath(requestRow, 'users')
    }
  }

  const handleNavigationPath = (requestRow: any, level: any) => {
    let rawData = projectRequestsApiData.filter((item: any) => item.assignedList[0].documents[0].documentTypeName !== "Project Documents")
    let filteredData = rawData.filter((item: any) => item.projectRequestId === Number(RequestId))
    if (level === 'users') {
      let path = navigationPath.routes || [];
      path.push(filteredData[0]?.projectRequestName);
      setNavigationPath({ level: level, routes: path, requestId: RequestId });
      setSelectedData(filteredData[0]);
    } else if (level === 'user-data') {
      let path = [];
      path.push(filteredData[0]?.projectRequestName);
      let peopleData = filteredData[0]?.assignedList.filter((peopleItem: any) => peopleItem.peopleId === Number(PeopleId))[0]
      path.push(peopleData?.assignedto);
      setNavigationPath({ level: level, routes: path, requestId: RequestId });
      setSelectedUsersData(peopleData);
    } else {
      console.error("Unhandled navigation level:", level);
    }
  };

  return (
    <>
      {!RequestId && <Flex justifyContent="space-between" alignItems="end">
        <Flex flexDirection='row'>
        </Flex>
        <Flex>
          <Button
            onClick={() => CreateRequestClick()}
            variant='accent'
            color='primary'
            disabled={tenantRequestReminders === undefined}
            style={{ margin: '0px 0px 0px auto' }}
          >
            <Button.Prefix>
              <PlusIcon />
            </Button.Prefix>
            Create a request
          </Button>
        </Flex>
      </Flex>}
      <CreateRequest
        isOpen={createRequestSheet}
        onClose={function (): void {
          setCreateRequestSheet(!createRequestSheet);
        }}
        peopleList={peopleList}
        projectDetails={projectDetails}
        onRequestCreated={onRequestUpdated}
        editRequestDetails={editProjectRequest}
        refetchProjectRequestDrafts={refetchProjectRequestDrafts} />
      <Breadcrumbs style={{ marginBottom: '10px' }}>
        {RequestId && <Breadcrumbs.Item
          onClick={() => {
            navigate(`/projects/project/${ProjectId}`);
            setNavigationPath({ routes: [] });
          }}
        >
          All requests
        </Breadcrumbs.Item>}

        {RequestId && (
          <Breadcrumbs.Item
            onClick={() => {
              navigate(`/projects/project/${ProjectId}/request/${RequestId}`);
              setNavigationPath({
                routes: [projectRequestsApiData.find((item: any) => item.projectRequestId === Number(RequestId))?.projectRequestName],
              });
            }}
          >
            {navigationPath.routes[0]}
          </Breadcrumbs.Item>
        )}

        {RequestId && PeopleId && (
          <Breadcrumbs.Item
            onClick={() => {
              navigate(`/projects/project/${ProjectId}/request/${RequestId}/people/${PeopleId}`);
            }}
          >
            {navigationPath.routes[1]}
          </Breadcrumbs.Item>
        )}
      </Breadcrumbs>

      <Flex flexDirection='column' style={{ overflow: 'auto' }}>
        <Modal
          show={requestReminderModalOpen}
          title={"Manage reminder interval for '" + selectedReminder?.assignedto + "'"}
          size="small"
          onDismiss={closeRequestReminderModal}
        >
          <Flex flexDirection="column" gap={8} width={512}>
            <SelectV2
              value={selectedReminder?.remindersAutoId}
              onChange={(value) => {
                handleReminderChange(value);
              }}
            >
              <SelectV2.Trigger placeholder={'Select a reminder'} />
              <SelectV2.Content width={'auto'}>
                {tenantRequestReminders?.map(
                  (option: { remindersId: Key | null | undefined; remindersAutoId: Key | null | undefined; details: string; }) => (
                    <SelectV2.Option key={option.remindersAutoId} value={option.remindersAutoId}>
                      {option.details}
                    </SelectV2.Option>
                  ),
                )}
              </SelectV2.Content>
            </SelectV2>
            <Flex justifyContent="flex-end">
              <Button variant="accent" onClick={closeRequestReminderModal} type="button">
                Close
              </Button>
            </Flex>
          </Flex>
        </Modal>

        {RequestId === undefined ?
          (filteredProjectRequests !== undefined && (
            <Flex height={400}>
              <DataTableV2
                columns={columns(handleOpenRequest) as any}
                data={[
                  ...filteredProjectRequests.filter(
                    (item: any) => item.assignedList[0].documents[0].documentTypeName !== "Project Documents"
                  ),
                  ...filterData(projectRequestsDrafts, searchQuery),
                ]}

                sortable
                variant={{ rowDensity: 'comfortable', rowSeparation:'none', verticalDividers: false, contained:false }}
                sortBy={sortBy}
                onSortByChange={handleSortingChange}
                style={{ overflowY: 'auto' }}
                resizable
                fullWidth
                interactiveRows
                onActiveRowChange={(row: any) => {
                  const filteredData = [...filteredProjectRequests.filter((item: any) => item.assignedList[0].documents[0].documentTypeName !== "Project Documents"),
                  ...filterData(projectRequestsDrafts, searchQuery),
                  ];
                  if (row !== null && filteredData?.[+row]?.statusName !== "inprogress/draft") {
                    showProjectDetails({
                      id: filteredData?.[+row]?.projectRequestId,
                      index: +row,
                      name: filteredData?.[+row]?.projectRequestName,
                      visibility: true,
                    });
                  }
                }}
              >
                <DataTableV2.TableActions>
                  <FilterBar
                    onFilterChange={() => {
                    }}
                  >
                    <FilterBar.Item name={'search'} label={''}>
                      <TextInput placeholder={'Search'} onChange={setSearchQuery} value={searchQuery} />
                    </FilterBar.Item>
                    <FilterBar.Item name={'selectedType'} label={''}>
                      <Button></Button>
                    </FilterBar.Item>
                  </FilterBar>
                </DataTableV2.TableActions>
                <DataTableV2.RowActions>
                  {(row: any) => {
                    const requestRow = row;
                    return (
                      <>
                        <Modal
                          show={requestCommentModalOpen}
                          title="Request comments"
                          size="small"
                          onDismiss={closeRequestCommentModal}
                        >
                          <Flex flexDirection="column" gap={8} width={512}>
                            <Strong>{selectedComment}</Strong>
                            <Flex justifyContent="flex-end">
                              <Button variant="accent" onClick={closeRequestCommentModal} type="button">
                                Close
                              </Button>
                            </Flex>
                          </Flex>
                        </Modal><TableRowActions.Group>
                          <Tooltip text={'Edit request'}>
                            <Button
                              onClick={() => handleRequestEditChange(requestRow)}
                            >
                              <Button.Prefix>
                                <EditIcon />
                              </Button.Prefix>
                            </Button>
                          </Tooltip>
                          <Tooltip text={'Show comments'}>
                            <Button onClick={() => openRequestCommentModal(String(requestRow.requestUpdateComments))} disabled={requestRow.requestUpdateComments === null || requestRow.requestUpdateComments === ""}>
                              <Button.Prefix>
                                <FeedbackIcon />
                              </Button.Prefix>
                            </Button>
                          </Tooltip>
                        </TableRowActions.Group></>
                    );
                  }}
                </DataTableV2.RowActions>
              </DataTableV2>
            </Flex>
          )) : (RequestId && PeopleId) ?
            <RequestsUserFiles
              requestStatusData={requestStatusData}
              handleSelectChange={handleSelectChange}
              handleUploadClick={handleUploadClick}
              downloadFileClick={downloadFileClick}
              projectRequests={projectRequests}
              projectRequestsApiData={projectRequestsApiData}
              refetchProjectRequests={refetchProjectRequests}
              setProjectRequestsApiData={setProjectRequestsApiData}
              setNavigationPath={setNavigationPath}
              navigationPath={navigationPath}
              setLoading={setLoading}
              fileInputRefs={fileInputRefs}
              selectedUsersData={selectedUsersData}
              setSelectedUsersData={setSelectedUsersData}
            /> : (RequestId) ?
              (<RequestsUsers requestStatusData={requestStatusData}
                handleSelectChange={handleSelectChange}
                handleUploadClick={handleUploadClick}
                downloadFileClick={downloadFileClick}
                projectRequests={projectRequests}
                openRequestReminderModal={openRequestReminderModal}
                projectRequestsApiData={projectRequestsApiData.filter((item: any) => item.assignedList[0].documents[0].documentTypeName !== "Project Documents")}
                refetchProjectRequests={refetchProjectRequests}
                setProjectRequestsApiData={setProjectRequestsApiData}
                setNavigationPath={setNavigationPath}
                handleNavigationPath={handleNavigationPath}
                navigationPath={navigationPath}
                setSelectedData={setSelectedData}
                handleRequestEditChange={handleRequestEditChange}
                selectedData={selectedData}
                setSelectedUsersData={setSelectedUsersData}
                setLoading={setLoading} />) : <></>
        }
      </Flex></>
  );
};
