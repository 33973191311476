import { FormField, Label, Modal, TextInput } from '@dynatrace/strato-components-preview';
import { Button } from '@dynatrace/strato-components/buttons';
import { Flex } from '@dynatrace/strato-components/layouts';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useAppInfo } from '../../contexts/AppContext';
import { ShowErrorNotification, ShowSuccessNotification } from '../../utils/Notifications';
import { PeopleService } from '../../services/PeopleService';

interface NewPersonModalProps {
  onDismiss: (refresh?: boolean) => void;
}

export const NewPersonModal = (props: NewPersonModalProps) => {
  const { onDismiss } = props;

  const [showModal, setShowModal] = useState(true);

  const { tenantId } = useAppInfo();
  const {
    handleSubmit: submitNewPerson,
    control: newPersonControl,
    reset: newPersonReset,
  } = useForm<{
    firstName: string;
    lastName: string;
    mobile: string;
    email: string;
  }>({
    mode: 'all',
  });

  const handleCancel = () => {
    setShowModal(false);
    onDismiss();
  };

  // save the information
  const savePerson = async (values: any) => {
    //console.log('Saving project details', values);
    const updateParams = {
      tenantId: tenantId,
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values.mobile,
      email: values.email,
    };
    try {
      await PeopleService.createPepople(updateParams, tenantId);
      ShowSuccessNotification('Business created successfully');
      // save the information and then close
      onDismiss(true);
    } catch (error) {
      ShowErrorNotification('Error creating project user', error);
      onDismiss();
      return;
    }
  };

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.stopPropagation();
    submitNewPerson(savePerson)(event);
  };

  return (
    <Modal
      show={showModal}
      title={'Add new person'}
      size={'small'}
      dismissible={false}
      footer={
        <>
          <Flex justifyContent='flex-end' gap={8}>
            <Button onClick={handleCancel} variant='default'>
              Discard
            </Button>
            <Button form='new-person-form' type='submit' variant='accent' color='primary'>
              Confirm
            </Button>
          </Flex>
        </>
      }
    >
      <form id='new-person-form' key={2} onSubmit={handleFormSubmit} onReset={() => newPersonReset()} noValidate>
        <Flex flexDirection='column' width={512} minWidth={512} gap={16}>
          <Controller
            name='firstName'
            control={newPersonControl}
            rules={{
              required: {
                value: true,
                message: 'First name is required.',
              },
              maxLength: {
                value: 200,
                message: 'Please enter a valid first name.',
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <FormField required>
                <Label>First name</Label>
                <TextInput
                  placeholder='Enter first name'
                  controlState={{
                    state: error ? 'error' : 'valid',
                    hint: error?.message,
                  }}
                  {...field}
                />
              </FormField>
            )}
          />
          <Controller
            name='lastName'
            control={newPersonControl}
            rules={{
              required: {
                value: true,
                message: 'Last name is required.',
              },
              maxLength: {
                value: 200,
                message: 'Please enter a valid last name.',
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <FormField required>
                <Label>Last name</Label>
                <TextInput
                  placeholder='Enter last name'
                  controlState={{
                    state: error ? 'error' : 'valid',
                    hint: error?.message,
                  }}
                  {...field}
                />
              </FormField>
            )}
          />
          <Controller
            name='email'
            control={newPersonControl}
            rules={{
              required: {
                value: true,
                message: 'First name is required.',
              },
              maxLength: {
                value: 200,
                message: 'Please enter a valid first name.',
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <FormField required>
                <Label>Email</Label>
                <TextInput
                  placeholder='Enter email address'
                  controlState={{
                    state: error ? 'error' : 'valid',
                    hint: error?.message,
                  }}
                  {...field}
                />
              </FormField>
            )}
          />
          <Controller
            name='mobile'
            control={newPersonControl}
            rules={{
              required: {
                value: true,
                message: 'Mobile number is required.',
              },
              maxLength: {
                value: 200,
                message: 'Please enter a valid mobile number.',
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <FormField required>
                <Label>Mobile number</Label>
                <TextInput
                  placeholder='Enter mobile number'
                  controlState={{
                    state: error ? 'error' : 'valid',
                    hint: error?.message,
                  }}
                  {...field}
                />
              </FormField>
            )}
          />
        </Flex>
      </form>
    </Modal>
  );
};
