import {
  DataTableV2,
  FilterBar,
  Modal,
  Sheet,
  Tab,
  TableRowActions,
  Tabs,
  TextInput,
  TitleBar,
  Tooltip,
} from '@dynatrace/strato-components-preview';
import { Flex } from '@dynatrace/strato-components/layouts';
import { Text, TextEllipsis } from '@dynatrace/strato-components/typography';
import { Key, useMemo, useRef, useState } from 'react';
import { XmarkIcon, PlusIcon, DeleteIcon } from '@dynatrace/strato-icons';
import { useForm } from 'react-hook-form';
import { useAppInfo } from '../../contexts/AppContext';
import { ShowErrorNotification } from '../../utils/Notifications';
import { RequestTemplate } from '../../types/RequestTemplate';
import RequestTemplateSheet from './RequestTemplateSheet';
import { LoadingStateComponent } from '../../components/LoadingStateComponent';
import { useRequestTemplates } from '../../hooks/use-minerva-data';
import { Button } from '@dynatrace/strato-components/buttons';
import { TemplateService } from '../../services/TemplateService';

export interface TemplatesSheetProps {
  close: () => void;
  show: boolean;
}

export const TemplatesSheet = ({ close, show }: TemplatesSheetProps) => {
  const [state, setState] = useState(show);
  const [templateSheetVisible, setTemplateSheetVisible] = useState(false);
  const [createTemplateSheetVisible, setCreateTemplateSheetVisible] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState<any>(null);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const requestTemplateData = useRef<RequestTemplate[]>([] as RequestTemplate[]);
  const [sendConfirmModal, setSendConfirmModal] = useState(false);
  const toBeDeletedTemplate = useRef<RequestTemplate | null>(null);

  let { tenantId } = useAppInfo();

  // get  templates custom hook
  const {
    isLoading: requestTemplatesLoading,
    data: requestTemplatesData,
    error: requestTemplatesError,
    refetch: refetchRequestTemplates,
    isRefetching: isRefetchingRequestTemplates,
  } = useRequestTemplates(tenantId);

  if (requestTemplatesError) {
    ShowErrorNotification('Error loading request templates', requestTemplatesError);
  }

  if (!requestTemplatesLoading) {
    const tenantTemplates = requestTemplatesData?.filter(
      (template: { tenantId: number }) => template.tenantId === tenantId,
    );

    if (tenantTemplates !== undefined) {
      requestTemplateData.current = tenantTemplates;
    }
  }
  const filteredData = useMemo(
    () => {
      return searchQuery.trim().length !== 0
        ? requestTemplateData.current?.filter(
            (item: { requestTemplateName: string; requestTemplateDescription: string }) =>
              item.requestTemplateName?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
              item.requestTemplateDescription?.toLowerCase().includes(searchQuery?.toLowerCase()),
          )
        : requestTemplateData.current;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchQuery, requestTemplateData.current],
  );

  const columns = useMemo<any>(
    () => [
      {
        id: 'requestTemplateName',
        header: 'Template name',
        accessor: 'requestTemplateName',
        width: { type:'auto', minWidth: 400},
        cell: ({ rowData }: any) => {
          return (
            <DataTableV2.DefaultCell>
              <Tooltip placement='bottom' text={rowData.requestTemplateName}>
                {
                  // eslint-disable-next-line react/jsx-no-undef
                  <TextEllipsis
                    onClick={() => {
                      setTemplateSheetVisible(true);
                      setSelectedTemplateId(rowData.requestTemplateId);
                    }}
                    truncationMode='end'
                  >
                    {rowData.requestTemplateName}
                  </TextEllipsis>
                }
              </Tooltip>
            </DataTableV2.DefaultCell>
          );
        },
      },
      {
        id: 'requestTemplateDescription',
        header: 'Description',
        accessor: 'requestTemplateDescription',
        width: { type:'auto', minWidth: 400},
      },
      {
        id: 'docCount',
        header: 'Number of documents',
        accessor: 'docCount',
        columnType: 'number',
        width: { type:'auto', minWidth: 300},
        cell: ({ rowData }: any) => {
          const docCount: number = rowData.requestTemplateDetails.length;
          return docCount;
        },
      },
    ],
    [setSelectedTemplateId, setTemplateSheetVisible],
  );

  const handleReqTemplateClose = (tenantId: number) => {
    refetchRequestTemplates();
    setTemplateSheetVisible(false);
    setCreateTemplateSheetVisible(false);
  };
  const { handleSubmit, reset } = useForm<{
    projectName: string;
    description: string;
    loanAmount: number;
    assignedStaff: undefined | Key;
    staffNote: string;
    purpose: string;
    primaryBorrower: undefined | Key;
    primaryBusiness: undefined | Key;
    primaryIndustry: undefined | Key;
    loanType: undefined | Key;
    startDate: string;
    desiredClosingDate: string;
    loanPhase: undefined | Key;
  }>({
    mode: undefined,
  });

  const deleteRequestTemplate = (template: RequestTemplate) => {
    toBeDeletedTemplate.current = template;
    setSendConfirmModal(true);
  };

  const handleNoClick = () => {
    setSendConfirmModal(false);
  };
  const handleYesClick = async () => {
    
    setSendConfirmModal(false);
    await TemplateService.deleteRequestTemplate(toBeDeletedTemplate.current?.requestTemplateId ?? 0, tenantId);
    toBeDeletedTemplate.current = null;
    refetchRequestTemplates();
  };
  return (
    <Sheet show={state} onDismiss={close}>
      <LoadingStateComponent loading={requestTemplatesLoading || isRefetchingRequestTemplates} />
      <TitleBar>
        <TitleBar.Title>Template management</TitleBar.Title>
        <TitleBar.Subtitle>View and manage tenant templates </TitleBar.Subtitle>
        <TitleBar.Action>
          <Flex flexDirection='row' gap={8}>
            <Button
              width='75px'
              onClick={() => {
                setState(false);
                close();
              }}
              variant='default'
            >
              <Button.Prefix>
                <XmarkIcon />
              </Button.Prefix>
            </Button>
          </Flex>
        </TitleBar.Action>
      </TitleBar>
      <Tabs defaultIndex={0}>
        <Tab title='Customer requests'>
          <form onSubmit={handleSubmit(() => void 0)} onReset={() => reset()} noValidate>
            <Flex flexDirection='column' margin={8} padding={0} gap={8}>
              <TitleBar>
                <TitleBar.Subtitle style={{ fontSize: 'medium' }}>Manage request templates </TitleBar.Subtitle>
              </TitleBar>

              <Flex flexDirection='row' justifyContent='space-between' alignItems='end'>
                <FilterBar onFilterChange={() => {}}>
                  <FilterBar.Item name={'search'} label={''}>
                    <TextInput placeholder={'Search'} onChange={setSearchQuery} value={searchQuery} />
                  </FilterBar.Item>
                  <FilterBar.Item name={'selectedType'} label={''}>
                    <Button></Button>
                  </FilterBar.Item>
                </FilterBar>

                <Button
                  onClick={() => {
                    //setSelectedEndpoint(null);
                    setCreateTemplateSheetVisible(true);
                  }}
                  variant='accent'
                  color='primary'
                  style={{ margin: '0px 0px 0px auto' }}
                >
                  <Button.Prefix>
                    <PlusIcon />
                  </Button.Prefix>
                  Request template
                </Button>
              </Flex>

              <Flex flexDirection={'column'}>
                <DataTableV2
                  columns={columns as any}
                  data={filteredData ?? []}
                  sortable
                  resizable
                  interactiveRows
                  fullWidth
                  sortBy={[{ id: 'requestTemplateName', desc: false }]}
                  variant={{ rowDensity: 'comfortable', rowSeparation:'none', verticalDividers: false, contained:false }}
                  onActiveRowChange={(row: any) => {
                    if (row !== null) {
                      setTemplateSheetVisible(true);
                      setSelectedTemplateId(filteredData?.[+row].requestTemplateId);
                    }
                  }}
                  >
                    <DataTableV2.RowActions>
                      {(row: any) => {
                        return (
                          <TableRowActions.Group>
                            <TableRowActions.Item
                              onClick={() => {
                                const template: RequestTemplate = row;
                                deleteRequestTemplate(template);
                              }}
                              prefixIcon={<DeleteIcon />}
                            />
                          </TableRowActions.Group>
                        );
                      }}
                    </DataTableV2.RowActions>
                </DataTableV2>
              </Flex>
            </Flex>
          </form>
        </Tab>
        {/* <Tab title='Lending documents' disabled>
          <Flex></Flex>
        </Tab>{' '} */}
      </Tabs>
      {sendConfirmModal && (
          <Modal title='Delete template' onDismiss={handleNoClick} size={'small'} show={true}>
            <Flex flexDirection='column' columnGap={4}>
              <Text>Please confirm deletion of this template: '{toBeDeletedTemplate.current?.requestTemplateName}'</Text>
              <Flex flexDirection='row' justifyContent='flex-end' rowGap={4}>
                <Button width='80px' variant='default' onClick={handleNoClick}>
                  Cancel
                </Button>
                <Button width='80px' variant='accent' color='primary' onClick={handleYesClick}>
                  Confirm
                </Button>
              </Flex>
            </Flex>
          </Modal>
        )}
      {createTemplateSheetVisible && (
        <RequestTemplateSheet
          close={() => setCreateTemplateSheetVisible(false)}
          show={createTemplateSheetVisible}
          onTemplateClose={handleReqTemplateClose}
        />
      )}
      {templateSheetVisible && (
        <RequestTemplateSheet
          close={() => setTemplateSheetVisible(false)}
          show={templateSheetVisible}
          selectedTemplateId={selectedTemplateId}
          requestTemplate={filteredData.filter((template: any) => template.requestTemplateId === selectedTemplateId)[0]}
          onTemplateClose={handleReqTemplateClose}
        />
      )}
    </Sheet>
  );
};
