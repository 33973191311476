import { v4 as uuidv4 } from 'uuid';
import { DataTableV2, Modal, SelectV2 } from '@dynatrace/strato-components-preview';
import { Button } from '@dynatrace/strato-components/buttons';
import { Flex } from '@dynatrace/strato-components/layouts';
import { useMemo, useRef, useState } from 'react';
import { Business } from '../../types/Business';
import { PlusIcon } from '@dynatrace/strato-icons';
import { NewBusinessModal } from '../business/NewBusinessModal';
import { ShowErrorNotification, ShowSuccessNotification, ShowValidationBanner } from '../../utils/Notifications';
import { useAppInfo } from '../../contexts/AppContext';
import { useBusinessesByTenantId } from '../../hooks/use-minerva-data';
import { ModalParentType } from '../../types/Types';
import { PersonaService } from '../../services/PersonaService';

interface NewPeopleBusinessRelationModalProps {
  parentType: ModalParentType;
  onDismiss: (refresh?: boolean) => void;
  personId: number;
  currentPeopleBusinessList: Business[];
}

export const NewPeopleBusinessRelationModal = (props: NewPeopleBusinessRelationModalProps) => {
  const { onDismiss, personId } = props;
  const [showModal, setShowModal] = useState(true);
  const [disableConfirm, setDisableConfirm] = useState(true);
  const [showNewBusinessModal, setShowNewBusinessModal] = useState<boolean>(false);
  const [showErrorBanner, setShowErrorBanner] = useState<boolean>(false);

  const { tenantId, peoplePersonas, tenantBusinessCategories } = useAppInfo();

  const selectedBusinesses = useRef<any>([]);

  const { isLoading, error, data: tenantBusinesses, refetch } = useBusinessesByTenantId(tenantId);
  if (error) {
    ShowErrorNotification('Error loading business list', error);
  }

  const businessCategoryMap = useMemo(() => {
    const map: Record<number, string> = {};
    tenantBusinessCategories?.forEach((category) => {
      map[category.businessCategoryAutoId] = category.businessCategory;
    });
    return map;
  }, [tenantBusinessCategories]);

  const peopleBusinessColumns = useMemo(
    () => [
      {
        id: 'businessName',
        header: 'Business name',
        accessor: 'businessName',
        width: 300,
      },
      {
        id: 'businessCategory',
        header: 'Business category',
        accessor: (row: Business) =>
          businessCategoryMap[row.businessCategoryId] || '',
        minWidth: 200,
        autoWidth: true,
      },
      {
        id: 'personRole',
        header: 'Role',
        accessor: 'personRole',
        width: 300,
        cell: ({ rowData }: any) => (
          <DataTableV2.DefaultCell>
            <Flex
              onKeyDown={(event: { key: string; preventDefault: () => void }) => {
                if (event.key === 'Enter') {
                  event.preventDefault(); // Prevent form submission
                }
              }}
            >
              <Button style={{ padding: 0, margin: 0 }}>
                <SelectV2 disabled={selectedBusinesses.current?.filter((item: any) => item.businessId === rowData.businessId).length === 0} onChange={(value) => onSelectPersona(rowData, value as number)}>
                  <SelectV2.Trigger placeholder={'Select business role'} />
                  <SelectV2.Content style={{ maxWidth: '300px' }} width='150px'>
                    {peoplePersonas &&
                      peoplePersonas.map((persona, index) => (
                        <SelectV2.Option key={index} value={persona.personaId}>
                          {persona.personaName}
                        </SelectV2.Option>
                      ))}
                  </SelectV2.Content>
                </SelectV2>
              </Button>
            </Flex>
          </DataTableV2.DefaultCell>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onSelectPersona = (row: any, personaAutoId: number) => {
    selectedBusinesses.current?.forEach((business: any) => {
      if (business.businessId === row.businessId) {
        business.personaId = personaAutoId;
      }
    });
    setShowErrorBanner(false);
    if (selectedBusinesses.current?.length !== 0) {
      if (isValidSelection()) {
        setShowErrorBanner(false);
        setDisableConfirm(false);
      } else {
        setShowErrorBanner(true);
        setDisableConfirm(true);
      }
    }
  };

  function isValidSelection() {
    let isValid = true;
    selectedBusinesses.current?.forEach((business: any) => {
      if (business.isBusinessSelected && business.personaId === undefined) {
        isValid = false;
      }
    });
    return isValid;
  }

  const handleCancel = () => {
    setShowModal(false);
    onDismiss();
  };

  const handleConfirm = () => {
    savePersonBusinessRelation();
    setShowModal(false);
    onDismiss();
  };

  // save new business - project relations and close the modal
  // with refresh flag to project
  const savePersonBusinessRelation = async () => {
    try {
      const requestData = selectedBusinesses.current.map((business: any) => ({
        clientBusinessId: 0,
        clientId: personId,
        businessId: business.businessId,
        personaId: business.personaId,
      }));
      await PersonaService.createClintRelation(requestData, tenantId);
      ShowSuccessNotification('People business relation created successfully');
      onDismiss(true);
    } catch (error) {
      ShowErrorNotification('Error creating people business relation', error);
      onDismiss();
    }
  };

  const onRowSelected = (selectedRowsData: any) => {
    const selectedIndices = Object.keys(selectedRowsData)
      .filter((key) => selectedRowsData[key])
      .map((key) => parseInt(key, 10));

    const data = tenantBusinesses
      ? tenantBusinesses.filter(
        (business) => !props.currentPeopleBusinessList.some((pbr) => pbr.businessId === business.businessId),
      )
      : []

    const selectedRows = selectedIndices.map((index) => data[index]);

    setDisableConfirm(selectedRows.length === 0);
    selectedBusinesses.current = [];
    selectedRows.forEach((row: any) => {
      row.isBusinessSelected = true;
      selectedBusinesses.current.push(row);
    });
    setShowErrorBanner(false);
    if (selectedRows.length !== 0) {
      if (isValidSelection()) {
        setShowErrorBanner(false);
        setDisableConfirm(false);
      } else {
        setShowErrorBanner(true);
        setDisableConfirm(true);
      }
    }
  };
  return (
    <Modal
      show={showModal}
      title={'Add business to this person'}
      size={'medium'}
      dismissible={false}
      footer={
        <Flex justifyContent='space-between' width='100%'>
          <Button
            onClick={() => {
              setShowNewBusinessModal(true);
            }}
            variant='emphasized'
            color='neutral'
          >
            <Button.Prefix>
              <PlusIcon />
            </Button.Prefix>
            New business
          </Button>
          <Flex justifyContent='flex-end' gap={8}>
            <Button onClick={handleCancel} variant='default'>
              Discard
            </Button>
            <Button onClick={handleConfirm} type='submit' variant='accent' color='primary' disabled={disableConfirm}>
              Confirm
            </Button>
          </Flex>
        </Flex>
      }
    >
      {showErrorBanner && ShowValidationBanner('All selected rows should have a valid', 'Business role')}
      <DataTableV2
        loading={isLoading}
        sortable
        columns={peopleBusinessColumns}
        data={
          tenantBusinesses
            ? tenantBusinesses.filter(
              (business) => !props.currentPeopleBusinessList.some((pbr) => pbr.businessId === business.businessId),
            )
            : []
        }
        defaultSortBy={[{ id: 'businessName', desc: false }]}
        selectableRows
        onRowSelectionChange={onRowSelected}
        variant={{ rowDensity: 'comfortable', rowSeparation:'none', verticalDividers: false, contained:false }}
        resizable
        fullWidth
      >
        <DataTableV2.EmptyState>No businesses available.</DataTableV2.EmptyState>
      </DataTableV2>
      {showNewBusinessModal && (
        <NewBusinessModal
          key={uuidv4()}
          onDismiss={(refresh) => {
            setShowNewBusinessModal(false);
            if (refresh) {
              refetch();
            }
          }}
        />
      )}
    </Modal>
  );
};
