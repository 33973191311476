import { v4 as uuidv4 } from 'uuid';
import {
  DataTableV2,
  DataTableV2ColumnSort,
  DatePicker,
  FormField,
  Label,
  SelectV2,
  Sheet,
  Tab,
  TableRowActions,
  Tabs,
  TextInput,
  TitleBar
} from '@dynatrace/strato-components-preview';
import { Button } from '@dynatrace/strato-components/buttons';
import { Flex } from '@dynatrace/strato-components/layouts';
import { Key, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { isArray } from 'lodash';
import { ItemInfo } from '../../types/ListItemInfo';
import { LoadingStateComponent } from '../../components/LoadingStateComponent';
import { ShowErrorNotification, ShowSuccessNotification } from '../../utils/Notifications';
import { Person } from '../../types/Person';
import { PeopleService } from '../../services/PeopleService';
import { Business, BusinessPersona } from '../../types/Business';
import { useAppInfo } from '../../contexts/AppContext';
import {
  BusinessAnalyticsSignetIcon,
  DeleteIcon,
  DescriptionIcon,
  PlusIcon,
  UnfoldMoreIcon,
} from '@dynatrace/strato-icons';
import { groupHeaderFontStyle } from '../GeneralStyles.css';
import { PARENT_TYPE_PERSON } from '../../types/Types';
import { NewPeopleBusinessRelationModal } from './NewPeopleBusinessRelationModal';
export interface PersonSheetProps {
  closeDetails: (itemInfo?: ItemInfo) => void;
  itemInfo: ItemInfo | undefined;
  show: boolean;
}

export const PersonSheet = (props: PersonSheetProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingBusinessRelations, setLoadingBusinessRelations] = useState<boolean>(false);
  const workingOnCall = useRef<boolean>(false);

  const [showExistingBusinessModal, setShowExistingBusinessModal] = useState<boolean>(false);

  const { closeDetails, show, itemInfo } = props;

  const [itemVisible, setItemVisible] = useState(show);
  const personDetails = useRef<BusinessPersona>();
  const [businessList, setBusinessList] = useState<Business[] | undefined>([]);
  const person = useRef<Person>();
  const { tenantId, userId, tenantStateList, tenantBusinessCategories } = useAppInfo();
  const [sortBy, setSortBy] = useState([{ id: 'businessName', desc: false }]);
  const handleSortingChange = (sort: DataTableV2ColumnSort[]) => {
    setSortBy(sort);
  };

  const businessCategoryMap = useMemo(() => {
    const map: Record<number, string> = {};
    tenantBusinessCategories?.forEach((category) => {
      map[category.businessCategoryAutoId] = category.businessCategory;
    });
    return map;
  }, [tenantBusinessCategories]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const peopleBusinessColumns = useMemo(
    () => [
      {
        id: 'businessName',
        header: 'Business name',
        accessor: 'businessName',
        width: { type:'auto', minWidth: 300},
      },
      {
        id: 'businessCategory',
        header: 'Business category',
        accessor: (row: Business) =>
          businessCategoryMap[row.businessCategoryId] || '',
        width: { type:'auto', minWidth: 200}
      },
      {
        id: 'personaName',
        header: 'Business role',
        accessor: (row: any) => row.personas.personaName,
        width: { type:'auto', minWidth: 200}
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tenantBusinessCategories],
  );

  const {
    handleSubmit,
    control,
    reset,
    trigger,
    formState: { isSubmitSuccessful },
  } = useForm<{
    firstName: string;
    lastName: string;
    mobile: string;
    email: string;
    streetAddress1: string;
    streetAddress2: string;
    city: string;
    state: undefined | Key;
    zip: string;
    dob: string;
    ssn: string;
    gender: string;
    maritalStatus: number;
  }>({
    mode: 'all',
  });

  // discarded or closed the sheet from escape key
  const dismissDetails = () => {
    closeDetails();
  };

  // get all the other required data for the edit
  // and creating new for loading dropdown data
  const getRequiredData = async () => {
    setLoading(true);
    try {
      // if not its a new project
      if (itemInfo && itemInfo.id) {
        personDetails.current = await PeopleService.getBusinessDetailsById<BusinessPersona>(itemInfo.id, tenantId);
        setBusinessList(personDetails.current.businesses);
        person.current = personDetails.current.client;
      }
    } catch (error) {
      ShowErrorNotification('Error fetching business person data', error);
      setLoading(false);
    } finally {
      if (person.current) {
        reset({
          firstName: person.current.firstName,
          lastName: person.current.lastName,
          email: person.current.email,
          mobile: person.current.phoneNumber,
          streetAddress1: person.current.clientAddress,
          streetAddress2: person.current.clientAddress1,
          city: person.current.city,
          state: person.current.stateid,
          gender: person.current.gender,
          zip: person.current.postalnumber,
          dob: person.current.dob ? person.current.dob.split("T")[0] : undefined,
          ssn: person.current.socialsecuritynumber,
          maritalStatus: person.current.marriedStatus,
        });
      } else {
        reset({});
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    // load other data from API
    if (workingOnCall.current === false) {
      workingOnCall.current = true;
      getRequiredData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemInfo?.id, show]);

  useEffect(() => {
    if (itemInfo?.id) {
      trigger();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  // updates project business relations post creating a new one or deleting
  const updatePeopleBusinessRelations = async () => {
    try {
      const data: any = await PeopleService.getBusinessDetailsById<BusinessPersona>(itemInfo?.id ?? 0, tenantId);
      setBusinessList(data.businesses);
    } catch (error) {
      ShowErrorNotification('Error updating people business relation', error);
    }
    setLoadingBusinessRelations(false);
  };

  // save the information
  const saveDetails = async (values: any) => {
    //console.log('Saving project details', values);
    const updateParams = {
      clientId: itemInfo?.id,
      //userId: ,
      tenantId: tenantId,
      //clientName: '',
      firstName: values.firstName,
      lastName: values.lastName,
      dob: values.dob,
      socialsecuritynumber: values.ssn,
      postalnumber: values.zip,
      stateid: values.state === 0 ? undefined : values.state,
      clientAddress: values.streetAddress1,
      phoneNumber: values.mobile,
      email: values.email,
      preferredContact: values.preferredContact,
      creditScore: values.creditScore,
      lendabilityScore: values.lendabilityScore,
      marriedStatus: values.maritalStatus,
      spouseClientId: values.spouseClientId,
      rootFolder: values.rootFolder,
      createdTime: itemInfo?.id ? undefined : new Date().toISOString(),
      modifiedTime: new Date().toISOString(),
      createdBy: itemInfo?.id ? undefined : userId,
      modifiedBy: userId,
      clientAddress1: values.streetAddress2,
      city: values.city,
      gender: values.gender,
    };
    if (itemInfo?.id) {
      try {
        await PeopleService.updatePeople(updateParams, tenantId);
        ShowSuccessNotification('Business updated successfully');
      } catch (error) {
        ShowErrorNotification('Error updating user information', error);
        closeDetails();
        return;
      }
    } else {
      try {
        await PeopleService.createPepople(updateParams, tenantId);
        ShowSuccessNotification('Business created successfully');
      } catch (error) {
        ShowErrorNotification('Error creating project user', error);
        closeDetails();
        return;
      }
    }

    // save the information and then close
    closeDetails({ refreshParent: true });
  };

  const title = itemInfo?.name ? itemInfo.name : 'New Person';
  const subTitle = itemInfo?.name ? 'Editing personal information' : 'Creating new personal information';
  return (
    <Sheet show={itemVisible} onDismiss={dismissDetails}>
      <form onSubmit={handleSubmit(saveDetails)} onReset={() => reset()} noValidate>
        <Flex flexDirection='column' margin={8} gap={8}>
          <TitleBar>
            <TitleBar.Title> {title} </TitleBar.Title>
            <TitleBar.Subtitle> {subTitle}</TitleBar.Subtitle>
            <TitleBar.Action style={{ display: 'flex', alignItems: 'center' }}>
              <Flex flexDirection='row' gap={8}>
                <Button
                  width='75px'
                  onClick={() => {
                    setItemVisible(false);
                    dismissDetails();
                  }}
                  variant='default'
                >
                  Discard
                </Button>
                <Button width='60px' type='submit' variant='accent' color='primary' disabled={isSubmitSuccessful}>
                  Save
                </Button>
              </Flex>
            </TitleBar.Action>
          </TitleBar>
          <LoadingStateComponent loading={loading} />

          {!loading && personDetails && (
            <Tabs defaultIndex={0}>
              <Tab title={'Profile'} prefixIcon={<DescriptionIcon />}>
                <Flex flexDirection='column' width={512} minWidth={512} gap={16}>
                  <Controller
                    name='firstName'
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'First name is required.',
                      },
                      maxLength: {
                        value: 200,
                        message: 'Please enter a valid first name.',
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <FormField required>
                        <Label>First name</Label>
                        <TextInput
                          placeholder='Enter first name'
                          controlState={{
                            state: error ? 'error' : 'valid',
                            hint: error?.message,
                          }}
                          {...field}
                        />
                      </FormField>
                    )}
                  />
                  <Controller
                    name='lastName'
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Last name is required.',
                      },
                      maxLength: {
                        value: 200,
                        message: 'Please enter a valid last name.',
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <FormField required>
                        <Label>Last name</Label>
                        <TextInput
                          placeholder='Enter last name'
                          controlState={{
                            state: error ? 'error' : 'valid',
                            hint: error?.message,
                          }}
                          {...field}
                        />
                      </FormField>
                    )}
                  />
                  <Controller
                    name='email'
                    control={control}
                    rules={{
                      validate: (value) => /\S+@\S+\.\S+/.test(value) || 'Please enter a valid email address.',
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <FormField required>
                        <Label>Email</Label>
                        <TextInput
                          placeholder='Enter email address'
                          controlState={{
                            state: error ? 'error' : 'valid',
                            hint: error?.message,
                          }}
                          {...field}
                        />
                      </FormField>
                    )}
                  />
                  <Controller
                    name='mobile'
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Mobile number is required.',
                      },
                      maxLength: {
                        value: 200,
                        message: 'Please enter a valid mobile number.',
                      },
                      validate: (value) =>
                        /\d{0,3}-?\d{0,2}-?\d{0,4}/.test(value) || 'Please enter a valid mobile number.',
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <FormField required>
                        <Label>Mobile number</Label>
                        <TextInput
                          placeholder='Enter mobile number'
                          controlState={{
                            state: error ? 'error' : 'valid',
                            hint: error?.message,
                          }}
                          {...field}
                        />
                      </FormField>
                    )}
                  />
                  <Controller
                    name='streetAddress1'
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <FormField>
                        <Label>Street address 1</Label>
                        <TextInput
                          placeholder='Enter street address 1'
                          controlState={{
                            state: error ? 'error' : 'valid',
                            hint: error?.message,
                          }}
                          {...field}
                        />
                      </FormField>
                    )}
                  />
                  <Controller
                    name='streetAddress2'
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <FormField>
                        <Label>Street address 2</Label>
                        <TextInput
                          placeholder='Enter street address 2'
                          controlState={{
                            state: error ? 'error' : 'valid',
                            hint: error?.message,
                          }}
                          {...field}
                        />
                      </FormField>
                    )}
                  />
                  <Controller
                    name='city'
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <FormField>
                        <Label>City</Label>
                        <TextInput
                          placeholder='Enter city'
                          controlState={{
                            state: error ? 'error' : 'valid',
                            hint: error?.message,
                          }}
                          {...field}
                        />
                      </FormField>
                    )}
                  />
                  <Controller
                    name='state'
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <FormField>
                        <Label>State</Label>
                        <Flex
                          onKeyDown={(event: { key: string; preventDefault: () => void }) => {
                            if (event.key === 'Enter') {
                              event.preventDefault(); // Prevent form submission
                            }
                          }}
                        >
                          <SelectV2
                            controlState={{
                              state: error ? 'error' : 'valid',
                              hint: isArray(error) ? error.join('\n') : error?.message,
                            }}
                            {...field}
                          >
                            <SelectV2.Trigger placeholder={'Select state'} />

                            <SelectV2.Content style={{ width: 'max-content', minWidth: '200px' }}>
                              {tenantStateList &&
                                tenantStateList.map((state, index) => (
                                  <SelectV2.Option key={index} value={state.id}>
                                    {state.name}
                                  </SelectV2.Option>
                                ))}
                            </SelectV2.Content>
                          </SelectV2>
                        </Flex>
                      </FormField>
                    )}
                  />
                  <Controller
                    name='zip'
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <FormField>
                        <Label>Zip</Label>
                        <Flex width={150}>
                          <TextInput
                            maxLength={5}
                            onKeyDown={(e) => {
                              !/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete'
                                ? e.preventDefault()
                                : void 0;
                            }}
                            placeholder='Enter zip code'
                            controlState={{
                              state: error ? 'error' : 'valid',
                              hint: error?.message,
                            }}
                            {...field}
                          />
                        </Flex>
                      </FormField>
                    )}
                  />
                </Flex>
              </Tab>
              <Tab title={'Extended profile'} prefixIcon={<UnfoldMoreIcon />}>
                <Flex flexDirection='column' width={512} minWidth={512} gap={16}>
                  <Controller
                    name='dob'
                    control={control}
                    rules={{
                      validate(value) {
                        return value && new Date(value).getTime() < new Date().getTime();
                      },
                      required: {
                        value: false,
                        message: 'Please choose a desired closing date.',
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <FormField>
                        <Label>Date of birth</Label>
                        <Flex width={150}>
                          <DatePicker
                            controlState={{
                              state: error ? 'error' : 'valid',
                              hint: error?.message,
                            }}
                            {...field}
                            onChange={({ value }) => field.onChange(value)}
                          />
                        </Flex>
                      </FormField>
                    )}
                  />
                  <Controller
                    name='ssn'
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <FormField>
                        <Label>SSN</Label>
                        <Flex width={150}>
                          <TextInput
                            pattern='/^\d{0,3}-?\d{0,2}-?\d{0,4}$/'
                            maxLength={11}
                            onKeyDown={(e) => {
                              !/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete'
                                ? e.preventDefault()
                                : void 0;
                            }}
                            placeholder='Enter Social security number'
                            controlState={{
                              state: error ? 'error' : 'valid',
                              hint: error?.message,
                            }}
                            {...field}
                          />
                        </Flex>
                      </FormField>
                    )}
                  />

                  <Controller
                    name='gender'
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <FormField>
                        <Label>Gender</Label>
                        <Flex
                          onKeyDown={(event: { key: string; preventDefault: () => void }) => {
                            if (event.key === 'Enter') {
                              event.preventDefault();
                            }
                          }}
                        >
                          <SelectV2
                            controlState={{
                              state: error ? 'error' : 'valid',
                              hint: isArray(error) ? error.join('\n') : error?.message,
                            }}
                            {...field}
                          >
                            <SelectV2.Content>
                              <SelectV2.Option value="male">Male</SelectV2.Option>
                              <SelectV2.Option value="female">Female</SelectV2.Option>
                            </SelectV2.Content>
                          </SelectV2>
                        </Flex>
                      </FormField>
                    )}
                  />
                  <Controller
                    name="maritalStatus"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <FormField>
                        <Label>Marital status</Label>
                        <Flex
                          onKeyDown={(event: any) => {
                            if (event.key === 'Enter') {
                              event.preventDefault(); // Prevent form submission
                            }
                          }}
                        >
                          <SelectV2
                            controlState={{
                              state: error ? 'error' : 'valid',
                              hint: Array.isArray(error) ? error.join('\n') : error?.message,
                            }}
                            {...field} // field already contains value and onChange
                          >
                            <SelectV2.Content>
                              <SelectV2.Option value={0}>Single</SelectV2.Option>
                              <SelectV2.Option value={1}>Married</SelectV2.Option>
                            </SelectV2.Content>
                          </SelectV2>
                        </Flex>
                      </FormField>
                    )}
                  />

                </Flex>
              </Tab>
              <Tab
                title={'Associated businesses'}
                prefixIcon={<BusinessAnalyticsSignetIcon />}
                disabled={itemInfo?.id === undefined}
              >
                <Flex flexDirection='column' width={'50%'} minWidth={325} gap={8}>
                  <Flex flexDirection='row' justifyContent='space-between' alignItems='end' marginTop={8}>
                    <Label className={groupHeaderFontStyle}>Businesses associated with this project</Label>
                    <Flex>
                      <Button
                        onClick={() => {
                          setShowExistingBusinessModal(true);
                        }}
                        variant='accent'
                        color='primary'
                        style={{ margin: '0px 0px 0px auto' }}
                      >
                        <Button.Prefix>
                          <PlusIcon />
                        </Button.Prefix>
                        Add business
                      </Button>
                      {showExistingBusinessModal && (
                        <NewPeopleBusinessRelationModal
                          key={uuidv4()}
                          parentType={PARENT_TYPE_PERSON}
                          personId={itemInfo?.id ?? 0}
                          currentPeopleBusinessList={businessList ?? []}
                          onDismiss={(refresh) => {
                            setShowExistingBusinessModal(false);
                            if (refresh) {
                              setLoadingBusinessRelations(true);
                              updatePeopleBusinessRelations();
                            }
                          }}
                        />
                      )}
                    </Flex>
                  </Flex>
                  <DataTableV2
                    loading={loadingBusinessRelations}
                    columns={peopleBusinessColumns as any}
                    data={businessList ?? []}
                    sortable
                    resizable
                    fullWidth
                    variant={{ rowDensity: 'comfortable', rowSeparation: 'none', verticalDividers: false, contained: false }}
                    sortBy={sortBy}
                    onSortByChange={handleSortingChange}
                  >
                    <DataTableV2.EmptyState>No businesses are associated with this project.</DataTableV2.EmptyState>
                    <DataTableV2.RowActions>
                      {(row: any) => (
                        <TableRowActions.Group>
                          <TableRowActions.Item
                            onClick={async () => {
                              // deleting the buisness from the project
                              try {
                                await PeopleService.deleteBusinessDetailsById(row.clientBusinessId, tenantId);
                                setLoadingBusinessRelations(true);
                                ShowSuccessNotification('Business association with the project deleted successfully');
                                updatePeopleBusinessRelations();
                              } catch (error) {
                                ShowErrorNotification('Error deleting business association with the project', error);
                              }
                            }}
                            prefixIcon={<DeleteIcon />}
                          ></TableRowActions.Item>
                        </TableRowActions.Group>
                      )}
                    </DataTableV2.RowActions>
                  </DataTableV2>
                </Flex>
              </Tab>
            </Tabs>
          )}
        </Flex>
      </form>
    </Sheet>
  );
};
